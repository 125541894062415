import React, { useEffect, useState } from "react";
import {
  EditOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card } from "antd";
import Meta from "antd/es/card/Meta";
import ModalFormCreator from "../ModalFormCreator";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../util/functions";
const ToolCard = ({ data,setRefresh }) => {
  const api=useHttp();
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen,setIsOpen]=useState(false);
  
  useEffect(()=>{
    if(isOpen)
    {
    const apiTool= {...CONSTANTS.API.getToolNews};
    api.sendRequest(apiTool,(res)=>{
        const tool=res?.data?.map((i)=>{
           return {
            ...i,
            Label:i?.title,
            value:i?.id,
            id:i?.id
           }
        })
        CONSTANTS.FORM_FIELD.EDIT_HOME_TOOL.find(
          (el) => el?.id === "toolId"
        ).option = tool;
   
       
     })
    }
  },[isOpen])

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleOpenModel = () => {
    setIsOpen(true)
  };
  const handleEditTool=(val)=>{
    const tool = { ...CONSTANTS.API.editHomeTool };
    const apitool = apiGenerator(tool, { dataId:data?.id });
    const payload = {
    toolId:val?.toolId,
    index:data?.index
    };
    api.sendRequest(
      apitool,
      (res) => {
        setIsOpen((prv)=>!prv)
        setRefresh((prv)=>!prv)
      },
      payload,
      "Edit Tool Successfully!!!"
    );
  }


  return (
    <>
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Card id={data?.id}>
          <Meta
            avatar={<Avatar src={data?.image} shape="square" size={100} />}
            title={<p className="m-0 pt-3 line-clamp-1 text-xl">{data?.title}</p>}
            description={
              <div className="text-primary-p-blue-500 bg-primary-p-blue-50 rounded-full px-3 py-1 w-fit">
                {data?.price}
              </div>
            }
          />
        </Card>
        <div
          className={`bg-dark-mode-600/50 w-full h-full absolute top-0 rounded-lg transition-container ${
            isHovered ? `active` : ``
          }`}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -48%)",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                handleOpenModel(data?.id);
              }}
            >
              <p className=" ">
                <EditOutlined />
              </p>
            </Button>
          </div>
        </div>
      </div>
      {isOpen ? <ModalFormCreator
            open={isOpen}
            onCreate={handleEditTool}
            onCancel={() => {
              setIsOpen((prev) => !prev);
            }}
            name={'Edit Home Tools'}
            menu={'EDIT_HOME_TOOL'}
            loading={api.isLoading}
            formData={{ ...data }}
            // modalWidth={modalWidth}
          /> :null}
    </>
  );
};

export default ToolCard;
