import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Modal,
  Popconfirm,
  Row,
} from "antd";
import FormFields from "./FormFields";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const ModalEditImages = ({
  open,
  onCreate,
  onCancel,
  name = "",
  previewImages = [],
  formData = {},
  menu,
  disabled = false,
  edit,
  SubmitName = "Submit",
  onEdit = () => {},
  Delete,
  onDelete = () => {},
  formFields = [],
  loading = false,
  children,
}) => {
  const [openAltModal, setOpenAltModal] = useState(false);
  const formRef1 = useRef(null);
  const formRef = useRef(null);
  const [altData, setAltData] = useState();
  const showModal = (i) => {
    setOpenAltModal((prv) => !prv);
    setAltData(i);
  };
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const footer = {
    footer: [
      edit && (
        <Button
          key="edit"
          style={{
            borderRadius: "4px",
            height: "40px",
            width: "200px",
          }}
          ghost
          type="primary"
          onClick={onEdit}
        >
          Edit
        </Button>
      ),
      Delete && (
        <Popconfirm
          onConfirm={onDelete}
          key="deleteConfirm"
          title={`Delete ${name.toLowerCase()}`}
          description={`Are you sure to delete ${name.toLowerCase()}`}
        >
          <Button
            key="delete"
            style={{
              borderRadius: "4px",
              height: "40px",
              width: "200px",
            }}
            ghost
            type="primary"
            danger
            // onClick={onDelete}
          >
            Delete
          </Button>
        </Popconfirm>
      ),
      <Button
        key="submit"
        style={{
          // background: "#2E5BFF",
          borderRadius: "4px",
          height: "40px",
          width: "200px",
          color: "black",
        }}
        loading={loading}
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {SubmitName}
      </Button>,
    ],
  };
  const EditImagefooter = {
    footer: [
      <Button
        key="submit"
        style={{
          // background: "#2E5BFF",
          borderRadius: "4px",
          height: "40px",
          width: "200px",
          color: "black",
        }}
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onEdit(values);
              !loading && showModal();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        Submit
      </Button>,
    ],
  };

  // const handleOk = () => {
  //   setOpenAltModal(false);
  // };
  const handleCancel = () => {
    setOpenAltModal(false);
  };
  useEffect(() => {
    if (openAltModal) {
      const handleKeyPress = (e) => {
        if (e?.key === "Enter" && !e?.shiftKey) {
          form1
            .validateFields()
            .then((values) => {
              form1.resetFields();
              onEdit(values, altData?.id);
              !loading && showModal();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    } else {
      if (open && !openAltModal) {
        const handleKeyPress = (e) => {
          if (e?.key === "Enter" && !e?.shiftKey) {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }
        };

        document.addEventListener("keydown", handleKeyPress);

        return () => {
          document.removeEventListener("keydown", handleKeyPress);
        };
      }
    }
  }, [openAltModal, open]);
  // console.log(previewImages);
  return (
    <>
      <Modal
        open={open}
        title={<p className="text-2xl mb-10 text-slate-500">{name}</p>}
        okText="Submit"
        width={700}
        // {...footer}
        cancelButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Row gutter={16}>
          {previewImages && previewImages?.length > 0
            ? previewImages?.map((i, idx) => {
                return (
                  <Col span={8} style={{ marginBottom: "16px" }} key={idx}>
                    <div className="relative">
                      <Image
                        width={200}
                        height={120}
                        src={i?.image}
                        className="rounded-md"
                      />
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => onDelete(i?.id)}
                      >
                        <Button
                          type="primary"
                          className="absolute top-2 right-3"
                        >
                          <p className="">
                            <DeleteOutlined />
                          </p>
                        </Button>
                      </Popconfirm>

                      <Button
                        type="primary"
                        className="absolute top-2 right-16"
                        onClick={() => {
                          showModal(i);
                        }}
                      >
                        <p className="">
                          <EditOutlined />
                        </p>
                      </Button>

                      {/* {openAltModal && (
                        <Modal
                          open={openAltModal}
                          title="Title"
                          onCancel={handleCancel}
                          loading={loading}
                          onOk={() => {
                            form
                              .validateFields()
                              .then((values) => {
                                form.resetFields();
                                onEdit(values, i?.id);
                                !loading && showModal();
                              })
                              .catch((info) => {
                                console.log("Validate Failed:", info);
                              });
                          }}
                        >
                          <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                          >
                            <Form.Item
                              id="alt"
                              name="alt"
                              label="Alt"
                              initialValue={i?.alt}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input placeholder="Alt"/>
                            </Form.Item>
                          </Form>
                        </Modal>
                      )} */}
                    </div>
                  </Col>
                );
              })
            : null}
        </Row>
        <FormFields
          formData={formData}
          menu={menu}
          formFields={formFields}
          form={form}
          disabled={disabled}
          open={open}
          formRef={formRef}
          // isAutoFocus={isAutoFocus}
        />
        {children}
      </Modal>

      {openAltModal && (
        <Modal
          open={openAltModal}
          title={
            <p className="text-2xl mb-10 text-slate-500">{"Edit Image Alt"}</p>
          }
          okText="Submit"
          // title="Edit Image Alt"
          onCancel={handleCancel}
          // {...footer}
          onOk={() => {
            form1
              .validateFields()
              .then((values) => {
                form1.resetFields();
                onEdit(values, altData?.id);
                !loading && showModal();
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <FormFields
            formData={altData}
            menu={"EDIT_TOOL_IMAGE_ALT_MODAL"}
            form={form1}
            formRef={formRef1}
          />
        </Modal>
      )}
    </>
  );
};

export default ModalEditImages;
ModalEditImages.defaultProps = {
  open: false,
  onCreate: () => {},
  onCancel: () => {},
  name: "",
  formData: {},
  menu: "",
  edit: false,
  onEdit: () => {},
  formFields: [],
};
