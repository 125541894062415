import React from "react";

import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import PageComponent from "../../../../component/common/Page-Component";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ListiclesLike = ({ dataId }) => {
  let list = { ...CONSTANTS.API.getListiclesLikesList };
  const apiList = apiGenerator(list, { dataId });
  const navigate=useNavigate();
  const handleView = (id) => {
    navigate(`/app/users/${id}`);
  };
  return (
    <>
      <div className="mt-5 mx-3">
        <PageComponent
          tableHeaders="LISTICLESIKE"
          tableTitle="Likes"
          getAPI={apiList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data.user,
                no: `L${data?.id.toString().padStart(7, "0")}`,  
                view:{
                  id:data?.userId,
                  onClick:(id)=>{handleView(id)}
                },
                date:moment(data?.createdAt).format('lll'),            
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/blog?search="
          // viewData
          // viewFunction={handleViewBlog}
        />
      </div>
    </>
  );
};

export default ListiclesLike;
