// // // import React from 'react'

// // // const TextEditorSimple = () => {
// // //   return (
// // //     <div>

// // //     </div>
// // //   )
// // // }

// // // export default TextEditorSimple

// // // import { Form } from "antd";
// // // import { useForm } from "antd/es/form/Form";

// // // import React, { useEffect, useState } from "react";
// // // import RichTextEditor from "react-rte";

// // // const TextEditorSimple = ({ valueB, onChange, placeholder }) => {
// // //   console.log(valueB);
// // //   //   const [value, setValue] = useState(
// // //   //     RichTextEditor.createValueFromString(valueB)
// // //   //   );
// // //   const [value, setValue] = useState(RichTextEditor.createEmptyValue());
// // //   //   const editor = useRef(null);

// // //   //   const toolbarConfig = {
// // //   //     // Optionally specify the groups to display (displayed in the order listed).
// // //   //     display: [
// // //   //       "INLINE_STYLE_BUTTONS",
// // //   //       "BLOCK_TYPE_BUTTONS",
// // //   //       "LINK_BUTTONS",
// // //   //       "BLOCK_TYPE_DROPDOWN",
// // //   //       "HISTORY_BUTTONS",
// // //   //     ],
// // //   //     INLINE_STYLE_BUTTONS: [
// // //   //       { label: "Bold", style: "BOLD", className: "custom-css-class" },
// // //   //       { label: "Italic", style: "ITALIC" },
// // //   //       { label: "Underline", style: "UNDERLINE" },
// // //   //     ],
// // //   //     BLOCK_TYPE_DROPDOWN: [
// // //   //       { label: "Normal", style: "unstyled" },
// // //   //       { label: "Heading Large", style: "header-one" },
// // //   //       { label: "Heading Medium", style: "header-two" },
// // //   //       { label: "Heading Small", style: "header-three" },
// // //   //     ],
// // //   //     BLOCK_TYPE_BUTTONS: [
// // //   //       { label: "UL", style: "unordered-list-item" },
// // //   //       { label: "OL", style: "ordered-list-item" },
// // //   //     ],
// // //   //   };
// // //   //   const [ form] =  Form.useForm()
// // //   //   form.getFieldValue()
// // //   const handleOnChange = (newValue) => {
// // //     setValue(newValue);
// // //     console.log(newValue, "55 ABS");
// // //     if (onChange) {
// // //       onChange(newValue.toString("html"));
// // //     }
// // //   };
// // //   useEffect(() => {
// // //     // valueB && setValue(valueB.toString("html"));
// // //     if (valueB) {
// // //       //   setValue(
// // //       //     RichTextEditor.createValueFromString(valueB.toString())
// // //       //   );
// // //       console.log(RichTextEditor.prototype);
// // //     }
// // //     valueB && console.log(valueB, "Formated Val", valueB.toString("html"));
// // //     // setValue(RichTextEditor.createValueFromString(valueB));
// // //   }, [valueB]);
// // //   console.log(value);
// // //   return (
// // //     <RichTextEditor
// // //       //   ref={editor}
// // //       //   value={value}
// // //       value={value}
// // //       onChange={handleOnChange}
// // //       //   toolbarConfig={toolbarConfig}
// // //     />
// // //   );
// // // };

// // // export default TextEditorSimple;

// // import React from "react";
// // import { LexicalComposer } from "@lexical/react/LexicalComposer";
// // import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
// // import { ContentEditable } from "@lexical/react/LexicalContentEditable";
// // // import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
// // // import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
// // import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
// // // import TreeViewPlugin from "./plugins/TreeViewPlugin";
// // // import ToolbarPlugin from "./plugins/ToolbarPlugin";
// // import { HeadingNode } from "@lexical/rich-text";
// // // import { HeadingNode, QuoteNode } from "@lexical/rich-text";
// // // import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
// // import { ListItemNode, ListNode } from "@lexical/list";
// // import ToolbarPlugin from "./ToolBar-PlugIn";
// // // import { CodeHighlightNode, CodeNode } from "@lexical/code";
// // // import { AutoLinkNode, LinkNode } from "@lexical/link";
// // // import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
// // // import { ListPlugin } from "@lexical/react/LexicalListPlugin";
// // // import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
// // // import { TRANSFORMERS } from "@lexical/markdown";

// // // import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
// // // import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
// // // import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

// // function Placeholder() {
// //   return <div className="editor-placeholder">Enter some rich text...</div>;
// // }
// // const TextEditorSimple = () => {
// //   const editorConfig = {
// //     // The editor theme
// //     theme: exampleTheme,
// //     // Handling of errors during update
// //     onError(error) {
// //       throw error;
// //     },
// //     // Any custom nodes go here
// //     nodes: [
// //       HeadingNode,
// //       ListNode,
// //       ListItemNode,
// //       // QuoteNode,
// //       // CodeNode,
// //       // CodeHighlightNode,
// //       // TableNode,
// //       // TableCellNode,
// //       // TableRowNode,
// //       // AutoLinkNode,
// //       // LinkNode,
// //     ],
// //   };
// //   return (
// //     <div>
// //       <LexicalComposer initialConfig={editorConfig}>
// //         <div className="editor-container">
// //           <ToolbarPlugin />
// //           <div className="editor-inner">
// //             <RichTextPlugin
// //               contentEditable={<ContentEditable className="editor-input" />}
// //               placeholder={<Placeholder />}
// //               ErrorBoundary={LexicalErrorBoundary}
// //             />
// //             {/* <HistoryPlugin />
// //             <TreeViewPlugin />
// //             <AutoFocusPlugin />
// //             <CodeHighlightPlugin />
// //             <ListPlugin />
// //             <LinkPlugin />
// //             <AutoLinkPlugin />
// //             <ListMaxIndentLevelPlugin maxDepth={7} />
// //             <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
// //           </div>
// //         </div>
// //       </LexicalComposer>
// //     </div>
// //   );
// // };

// // export default TextEditorSimple;
// // export const exampleTheme = {
// //   ltr: "ltr",
// //   rtl: "rtl",
// //   placeholder: "editor-placeholder",
// //   paragraph: "editor-paragraph",
// //   quote: "editor-quote",
// //   heading: {
// //     h1: "editor-heading-h1",
// //     h2: "editor-heading-h2",
// //     h3: "editor-heading-h3",
// //     h4: "editor-heading-h4",
// //     h5: "editor-heading-h5",
// //   },
// //   list: {
// //     nested: {
// //       listitem: "editor-nested-listitem",
// //     },
// //     ol: "editor-list-ol",
// //     ul: "editor-list-ul",
// //     listitem: "editor-listitem",
// //   },
// //   image: "editor-image",
// //   link: "editor-link",
// //   text: {
// //     bold: "editor-text-bold",
// //     italic: "editor-text-italic",
// //     overflowed: "editor-text-overflowed",
// //     hashtag: "editor-text-hashtag",
// //     underline: "editor-text-underline",
// //     strikethrough: "editor-text-strikethrough",
// //     underlineStrikethrough: "editor-text-underlineStrikethrough",
// //     code: "editor-text-code",
// //   },
// //   code: "editor-code",
// //   codeHighlight: {
// //     atrule: "editor-tokenAttr",
// //     attr: "editor-tokenAttr",
// //     boolean: "editor-tokenProperty",
// //     builtin: "editor-tokenSelector",
// //     cdata: "editor-tokenComment",
// //     char: "editor-tokenSelector",
// //     class: "editor-tokenFunction",
// //     "class-name": "editor-tokenFunction",
// //     comment: "editor-tokenComment",
// //     constant: "editor-tokenProperty",
// //     deleted: "editor-tokenProperty",
// //     doctype: "editor-tokenComment",
// //     entity: "editor-tokenOperator",
// //     function: "editor-tokenFunction",
// //     important: "editor-tokenVariable",
// //     inserted: "editor-tokenSelector",
// //     keyword: "editor-tokenAttr",
// //     namespace: "editor-tokenVariable",
// //     number: "editor-tokenProperty",
// //     operator: "editor-tokenOperator",
// //     prolog: "editor-tokenComment",
// //     property: "editor-tokenProperty",
// //     punctuation: "editor-tokenPunctuation",
// //     regex: "editor-tokenVariable",
// //     selector: "editor-tokenSelector",
// //     string: "editor-tokenSelector",
// //     symbol: "editor-tokenProperty",
// //     tag: "editor-tokenProperty",
// //     url: "editor-tokenOperator",
// //     variable: "editor-tokenVariable",
// //   },
// // };

// import React, { useState } from "react";
// import "react-quill/dist/quill.snow.css";
// import ReactQuill, { Quill } from "react-quill";
// import ImageUploader from "quill-image-uploader";
// import Services from "../../util/API/service";
// import QuillToggleFullscreenButton from "quill-toggle-fullscreen-button/dist/quill.toggleFullscreenButton";
// // import QuillPasteSmart from 'quill-paste-smart'; // Import the quill-paste-smart module

// // Register the module
// // Quill.register('modules/pasteSmart', QuillPasteSmart);

// // #2 register module
// Quill.register("modules/toggleFullscreen", QuillToggleFullscreenButton);
// Quill.register("modules/imageUploader", ImageUploader);
// const colorsData=["#F8FAFC", "#0D0D11", "#5A4FCF","#F7CE46","#10B981","#EF4444","#022575","#E2E8F0", "#403F46","#6E63E5","#F8D359","#10B981"
// ,'#F87171','#074AAF','#CBD5E1','#787685','#8C84DD','#F9DD7E','#6EE7B7','#FCA5A5',
// '#0E81F4','#94A3B8','#A8A7B6','#9C95E2','#FBE7A3','#A7F3D0','#FECACA','#6DC2FB']
// const modules = {
//   // toolbar: [
//   //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
//   //   ["bold", "italic", "underline", "strike", "blockquote"],
//   //   [
//   //     { list: "ordered" },
//   //     { list: "bullet" },
//   //     // { indent: "-1" },
//   //     // { indent: "+1" },
//   //   ],
//   //   ["link"],
//   //   // ["clean"],
//   // ],
// //   clipboard: {
// //     allowed: {
// //         tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
// //         attributes: ['href', 'rel', 'target', 'class']
// //     },
// //     keepSelection: true,
// //     substituteBlockElements: true,
// //     magicPasteLinks: true,
// //     hooks: {
// //         uponSanitizeElement(node, data, config) {
// //             console.log(node);
// //         },
// //     },
// // },
// keyboard: {
//   bindings: {
//     // enter: {
//     //   key: Quill.import('modules/keyboard').keys.ENTER,
//     //   handler: function(range, context) {
//     //     const quill = this.quill; // Get the Quill instance
//     //     quill.format('list', false); // Use Quill.prototype.format
//     //   },
//     // },
//     'shift enter': {
//       key: 13, // 13 is the keycode for Enter key
//       shiftKey: true,
//       handler: function(range, context) {
//         const quill = this.quill; // Get the Quill instance
//         quill.insertText(range.index, '\n', 'user'); // Insert a newline character
//         quill.setSelection(range.index + 1, 'user');
//             quill.format('list', false);
//         console.log("Shift + Enter pressed");
//       },
//     },
//   },
// },
//   toolbar: [
//     ["bold", "italic", "underline", "strike"], // toggled buttons
//     ["blockquote"],
//     [{ header: 1 }, { header: 2 }], // custom button values
//     [{ list: "ordered" }, { list: "bullet" }],
//     [{ script: "sub" }, { script: "super" }], // superscript/subscript
//     [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
//     [{ direction: "rtl" }], // text direction
//     [{ size: ["small", false, "large", "huge"] }], // custom dropdown
//     [{ header: [1, 2, 3, 4, 5, 6, false] }],
//     [
//       { color: colorsData }, // ["#000", "#fff", "#00aae3"]
//       { background: colorsData }, // ["#000", "#fff", "#00aae3", "#00ff00"]
//     ], // dropdown with defaults from theme
//     // [{ font: [] }],
//     [{ align: [] }],
//     ["link", "code-block", "image"], // remove formatting button
//   ],
//   toggleFullscreen: true,
//   imageUploader: {
//     upload: (file) => {
//       return new Promise((resolve, reject) => {
//         const formData = new FormData();
//         formData.append("image", file);
//         Services.post("/admin/upload", formData)
//           // .then((response) => response.json())
//           .then((result) => {
//             console.log(result);
//             resolve(result.data?.data);
//           })
//           .catch((error) => {
//             reject("Upload failed");
//             console.error("Error:", error);
//           });

//         // fetch(
//         //   "https://api.imgbb.com/1/upload?key=334ecea9ec1213784db5cb9a14dac265",
//         //   {
//         //     method: "POST",
//         //     body: formData,
//         //   }
//         // )
//         //   .then((response) => response.json())
//         //   .then((result) => {
//         //     console.log(result);
//         //     resolve(result.data.url);
//         //   })
//         //   .catch((error) => {
//         //     reject("Upload failed");
//         //     console.error("Error:", error);
//         //   });
//       });
//     },
//   },
//   // pasteSmart: true,
// };

// const formats = [
//   // "header",
//   // "bold",
//   // "italic",
//   // "underline",
//   // "strike",
//   // "blockquote",
//   // "list",
//   // "bullet",
//   // "indent",
//   // "link",
//   // // "code",
//   // // "image",
//   // // "imageBlot",
//   "header",
//   "font",
//   "size",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "script",
//   "indent",
//   "direction",
//   "color",
//   "background",
//   "align",
//   "link",
//   "image",
//   "code-block",
// ];

// const TextEditor = ({ value, onChange, placeholder }) => {
//   // const [isFullScreen, setIsFullScreen] = useState(false);

//   // const toggleFullScreen = () => {
//   //   setIsFullScreen(!isFullScreen);
//   // };
//   return (
//     <>
//       {/* <div
//         className={
//           isFullScreen ? "full-screen h-[100vh] bg-white" : "h-[400px]"
//         }
//       > */}
//       <ReactQuill
//         // style={{ height: "100%", width: "100%" }}
//         theme="snow"
//         // scrollingContainer={false}
//         // className={value == undefined ? "ql-error" : null}
//         value={value || ""}
//         modules={modules}
//         formats={formats}
//         onChange={onChange}
//         placeholder={placeholder}
//       />
//       {/* </div> */}
//       {/* <button onClick={toggleFullScreen}>
//         {isFullScreen ? "Exit Full Screen" : "Full Screen"}
//       </button> */}
//     </>
//   );
// };

// export default TextEditor;
import React, { useEffect, useState } from "react";
import Editor from "./CkEditor";

const TextEditorSimple = ({ value, onChange, name}) => {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  useEffect(() => {
    setData(value);
  }, [value]);

  const handleEditorChange = (data) => {
    onChange(data);
    setData(data);
  };
  return (
    <>
      <Editor
        name={name}
        onChange={handleEditorChange}
        editorLoaded={editorLoaded}
        value={data ? data : value}
      />
    </>
  );
};

export default TextEditorSimple;
