import { Card, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReadOnlyRating from "../../../../../component/common/ReadOnlyRating";
import CustomTextSlider from "../../../../../component/common/CustomTextSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import { apiGenerator, capitalizeFirstLetter, changePremiumtoPaid } from "../../../../../util/functions";
import useHttp from "../../../../../hooks/use-http";
import { useParams } from "react-router-dom";
import ToolTab from "./tool-tab";
import "./toolView.css"
import OverView from "./overView";
const ToolView = () => {
  const { id } = useParams();
  const api = useHttp();
  const [toolData, setToolData] = useState();
  const [refreshTool,setRefreshTool]=useState(false);
  useEffect(() => {
    const OneTool = { ...CONSTANTS.API.getOneTool };
    const apiOneTool = apiGenerator(OneTool, { id });
    api.sendRequest(apiOneTool, (res) => {
      setToolData(res?.data);
    });
  }, [refreshTool]);

  return (
    <>
    
      <Card title="" bordered={false} className="">
        <div className="p-[30px]">
          <Row className="">
            <Col span={3}>
              <div className="flex justify-center">
                <div className="rounded-[20px] overflow-hidden">
                  <img
                    src={toolData?.image}
                    alt="tool"
                    width="100px"
                    height="100px"
                    className="object-cover select-none"
                  />
                </div>
              </div>
            </Col>
            <Col span={20}>
              <Row className="flex-col">
                <Col>
                  <div className="flex gap-x-2">
                    <span className="text-xl font-semibold">
                      {toolData?.title}
                    </span>
                    <span className="px-3 py-[2px] rounded-2xl bg-[#1212120d]">
                      { capitalizeFirstLetter(changePremiumtoPaid(toolData?.price))}
                    </span>
                  </div>
                </Col>
                <Col>
                  <span className="text-sm opacity-50">{toolData?.link}</span>
                </Col>
                <Col>
                  <div className="flex items-center gap-x-3">
                    <div className="flex gap-2 text-[#FAC407] items-center select-none">
                      <ReadOnlyRating rating={toolData?.ratingsAverage} />
                      <span className="">{toolData?.ratingsAverage}</span>
                    </div>
                    <span className="opacity-50">|</span>
                    <span className="opacity-50 text-sm">{toolData?.views} Users</span>
                    <span className="opacity-50">|</span>
                    <div className="w-1/4">
                      <CustomTextSlider
                        data={
                          toolData?.toolCategories?.length > 0 &&
                          toolData?.toolCategories?.map((i, idx) => {
                            return (
                              <span className="flex justify-center items-center px-5 py-1 text-sm rounded-full bg-[#1212120d]">
                                {i?.category?.name}
                              </span>
                            );
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-20">
            <Col span={24}>
              <Swiper
                className="select-none slider-tool"
                id="main"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation
                pagination
                spaceBetween={0}
                slidesPerView={1} 
              >
                {toolData && toolData?.toolImages?.length > 0
                  ? toolData?.toolImages.map((photo, index) => (
                      <SwiperSlide
                        className="flex justify-center slider-card"
                        key={index}
                      >
                        <div className="slider-slide-card overflow-hidden md:h-[300px] min-[425px]:h-[250px] min-[375px]:h-[200px] min-[320px]:h-[170px] object-cover object-center">
                          <img
                            src={photo?.image}
                            className="overflow-hidden object-cover object-center w-full h-auto"
                            layout="responsive"
                            width={400}
                            height={250}
                            alt="tool"
                          />
                        </div>
                      </SwiperSlide>
                    ))
                  : null}
              </Swiper>
            </Col>
          </Row>
          <OverView data={toolData}/>
          {/* <ToolTab data={toolData} setRefreshTool={setRefreshTool}/> */}
        </div>
      </Card>
    </>
  );
};

export default ToolView;
