import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";

const Category = () => {
  const api = useHttp();
  const navigate = useNavigate();
  useEffect(() => {
    const apiCategory = { ...CONSTANTS.API.getMainCategory };
    api.sendRequest(apiCategory, (res) => {
      const category = res?.data?.rows?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.CATEGORY_MODAL.find(
        (el) => el?.id === "mainCategoryId"
      ).option = category;
      CONSTANTS.FORM_FIELD.EDIT_CATEGORY_MODAL.find(
        (el) => el?.id === "mainCategoryId"
      ).option = category;
      CONSTANTS.FILTER.SUBCATEGORY.find(
        (el) => el?.id === "categories"
      ).option = category;
    });
  }, []);
  return (
    <>
      <PageComponent
      trackEventName="Sub Category"
        tableHeaders="CATEGORY"
        tableTitle="Sub Category List"
        getAPI={CONSTANTS.API.getCategory}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `SC${data?.id.toString().padStart(7, "0")}`,
              mainCategory:data?.mainCategory?.name ? data?.mainCategory?.name : "-",
              // description:data?.description != null ? data?.description : "",
              date: moment(data?.createdAt).format("lll"),
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton="Add New Sub Category"
        addModalTitle="Add New Sub Category"
        editModalTitle="Edit Sub Category"
        modalFields="CATEGORY_MODAL"
        editModalFields="EDIT_CATEGORY_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addCategory}
        editAPI={CONSTANTS.API.editCategory}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteCategory}
        searchfilter
        isSearch={true}
        searchAPI="/admin/category?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.SUBCATEGORY}
        extraFilterParam="mainCategoryId"
        modalWidth={1100}
      />
    </>
  );
};

export default Category;
