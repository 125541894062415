import React from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import { Card,Tabs } from "antd";
import BlogComment from "../blog/tabs/comment";
import ListiclesLike from "./tabs/like";
import ListicleUserView from "./tabs/view";
import ListComment from "./tabs/comments";


const ListiclesView = () => {
  const { id } = useParams();
  const menus = [
    { like: <ListiclesLike dataId={id} /> },
    { view: <ListicleUserView dataId={id}/> },
    { comment: <ListComment dataId={id} /> },
    // { blogLike: <UserBlogLike dataId={id} /> },
    // // { blogComment: <UserBlogComment dataId={id} /> },
    // { blogView: <UserBlogView dataId={id} /> },
  ];
  return (
    <>
   <div className="mt-10">
        {/* <Row gutter={[16, 16]}>
          <Col span={7} xs={24} sm={24} md={24} lg={7} xl={5}>
          <Card className="h-54">
      <Row>
        <Col span={8}>
          <Image
            width={80}
            height={80}
            className="rounded-full"
            preview={false}
            src={users?.profilePic}
            alt="Images is Not Availabel"
          />
        </Col>
        <Col span={16}>
          <p className="font-medium mt-3 text-2xl mb-0 truncate">
            {users?.username}
          </p>
          <p className="font-medium text-sm text-gray-400 truncate">
            {users?.email}
          </p>
        </Col>
      </Row>

      
    </Card>
          </Col>
        </Row> */}
        <Card className="mt-10">
          <Tabs
            className="p-0 m-0"
            defaultActiveKey="1"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.LISTICLES.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: menus.map((data) => {
                  if (Object.keys(data)[0] === ele.id) {
                    return data[ele.id];
                  }
                }),
              };
            })}
          />
        </Card>
      </div>
    </>
  );
};

export default ListiclesView;
