import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import useHttp from "../../../hooks/use-http";
import { useLocation, useNavigate } from "react-router-dom";
import { apiGenerator, capitalizeFirstLetter } from "../../../util/functions";

const SingleMainCategory = () => {
  const location = useLocation();
  useEffect(() => {
    const defaultValue = location.state?.categoryId
      ? {
          value: location.state?.categoryId,
          Label: location.state?.categoryName,
          id: location.state?.categoryId,
        }
      : null;
    CONSTANTS.FORM_FIELD.MAINCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "mainCategoryId"
    ).option = [defaultValue];

    CONSTANTS.FORM_FIELD.MAINCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "mainCategoryId"
    ).defaultValue = location.state?.categoryId;
    CONSTANTS.FORM_FIELD.EDIT_MAINCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "mainCategoryId"
    ).option = [defaultValue];

    CONSTANTS.FORM_FIELD.EDIT_MAINCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "mainCategoryId"
    ).defaultValue = location.state?.categoryId;
  }, []);
  const API_CALL = apiGenerator(CONSTANTS.API.getMainSingleCategory, {
    dataId: location.state?.categoryId,
  });
  return (
    <>
      <PageComponent
      trackEventName="Sub Category"
        tableHeaders="MAINCATEGORYVIEW"
        tableTitle={`Sub Category List [${capitalizeFirstLetter(
          location.state?.categoryName
        )}]`}
        getAPI={API_CALL}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `SC${data?.id.toString().padStart(7, "0")}`,
              //   mainCategory:data?.mainCategory?.name ? data?.mainCategory?.name : "-",
              // description:data?.description != null ? data?.description : "",
              date: moment(data?.createdAt).format("lll"),
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton={`Add New Sub Category`}
        addModalTitle={`Add New Sub Category [${capitalizeFirstLetter(
          location.state?.categoryName
        )}]`}
        editModalTitle={`Edit Sub Category [${capitalizeFirstLetter(
          location.state?.categoryName
        )}]`}
        modalFields="MAINCATEGORYVIEW_MODAL"
        editModalFields="EDIT_MAINCATEGORYVIEW_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addCategory}
        editAPI={CONSTANTS.API.editCategory}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteCategory}
        searchfilter
        isSearch={true}
        searchAPI="/admin/category?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.MAINCATEGORYVIEW}
        extraFilterParam="mainCategoryId"
        modalWidth={1100}
      />
    </>
  );
};

export default SingleMainCategory;
