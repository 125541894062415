import React from "react";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import PageComponent from "../../../../component/common/Page-Component";
import moment from "moment";
import { useNavigate } from "react-router-dom";


const ToolRating= ({ dataId }) => {
  const apiList = { ...CONSTANTS.API.getOneRating };
  apiList.endpoint = apiList.endpoint + dataId;
  const navigate=useNavigate();
  const handleView = (id) => {
    navigate(`/app/users/${id}`);
  };
  
  return (
    <>
      <div className="mt-5 mx-3">
        <PageComponent
        trackEventName="Tool Rating"
          tableHeaders="TOOLRATING"
          tableTitle="Ratings"
          getAPI={apiList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data,
                no: `R${data?.id.toString().padStart(7, "0")}`,  
                username:data?.user?.username,
                email:data?.user?.email,
                profilePic:data?.user?.profilePic,
                title:data?.title ? data?.title : "-",
                review:data?.review ? data?.review:"-",
                view:{
                  id:data?.userId,
                  onClick:(id)=>{ 
                    handleView(id)
                }
                },
                date:moment(data?.createdAt).format('lll'),            
              };
            });
          }}
          extraResData="rows"
          deleteData
          deleteAPI={CONSTANTS.API.deleteRateing}

          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/blog?search="
          // viewData
          // viewFunction={handleViewBlog}
        />
      </div>
    </>
  );
};

export default ToolRating;
