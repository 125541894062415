import React, { useEffect, useState } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import useHttp from '../../../hooks/use-http';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const ScheduleNews = () => {
    const api=useHttp();
    const navigate=useNavigate();
    useEffect(()=>{
      const apiCategory= {...CONSTANTS.API.getNewsCategory};
      api.sendRequest(apiCategory,(res)=>{
         const category=res?.data?.rows?.map((i)=>{
            return {
             ...i,
             Label:i?.name,
             value:i?.id,
             id:i?.id
            }
         })
         CONSTANTS.FORM_FIELD.NEWS_SCHEDULED_MODAL.find(
           (el) => el?.id === "newsCategoryId"
         ).option = category;
        //  CONSTANTS.FILTER.NEWS.find(
        //   (el) => el?.id === "categories"
        // ).option = category;
         CONSTANTS.FORM_FIELD.EDIT_NEWS_SCHEDULED_MODAL.find(
           (el) => el?.id === "newsCategoryId"
         ).option = category;
      })
      const apiTool= {...CONSTANTS.API.getToolNews};
      api.sendRequest(apiTool,(res)=>{
         const tool=res?.data?.map((i)=>{
            return {
             ...i,
             Label:i?.title,
             value:i?.id,
             id:i?.id
            }
         })
         CONSTANTS.FORM_FIELD.NEWS_SCHEDULED_MODAL.find(
           (el) => el?.id === "tools"
         ).option = tool;
         CONSTANTS.FORM_FIELD.EDIT_NEWS_SCHEDULED_MODAL.find(
           (el) => el?.id === "tools"
         ).option = tool;
      })
    },[])
    const handleViewNews=(id)=>{
      navigate(`/app/news/${id}`)
    }
  return (
    <>
      <PageComponent
      trackEventName='News'
        tableHeaders="SCHEDULENEWS"
        tableTitle="Scheduled News List"
        getAPI={CONSTANTS.API.getNewsSchedualData}
        getData={(res)=>{
            return res?.map((data,idx)=>{ 
                return {
                    ...data,
                    no: `N${data?.id.toString().padStart(7, "0")}`,
                    newsCategory:data?.newsCategory?.name ? data?.newsCategory?.name :"-",    
                    tools:data?.toolNews?.map(ele => ele?.toolId),               
                    date:moment(data?.createdAt).format('lll'),   
                    release:moment(data?.release).format('lll'),              
                    view:{
                      id:data?.id,
                      onClick:(id)=>{handleViewNews(id)}
                    },      
                }
            })
        }}
        extraResData="rows"
        formData
        modalButton="Add New News"
        addModalTitle="Add New News"
        editModalTitle="Edit News"
        modalFields="NEWS_SCHEDULED_MODAL"
        editModalFields="EDIT_NEWS_SCHEDULED_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addNews}
        editAPI={CONSTANTS.API.editNews}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteNews}
        searchfilter
        isSearch={true}
        searchAPI="/admin/news?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.NEWS}
        extraFilterParam='newsCategoryId'
        dateTime
        modalWidth={800}
      />
      
    </>
  )
}

export default ScheduleNews
