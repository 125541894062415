import React, { useEffect, useState } from "react";
import CustomDND from "../../../component/common/Custom-DND";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Col, Row, Spin } from "antd";
const Home = () => {
  const [toolData, setToolData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [listData, setListData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refreshBlog, setRefreshBlog] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [isLoadingTool, setIsLoadingTool] = useState(false);
  const [isLoadingBlog, setIsLoadingBlog] = useState(false);
  const [isLoadingListicle, setIsLoadingListicle] = useState(false);
  const api = useHttp();
  useEffect(() => {
    setIsLoadingTool(true);
    const apiTool = { ...CONSTANTS.API.getHomeTool };
    api.sendRequest(apiTool, (res) => {
      console.log(res, "tool home");
      const data = res?.data?.rows?.map((data) => {
        return {
          id: data?.id,
          toolId: data?.tool?.id,
          index: data?.index,
          title: data?.tool?.title,
          price: data?.tool?.price,
          image: data?.tool?.image,
        };
      });

      setToolData(data);
      setIsLoadingTool(false);
    });
  }, [refresh]);

  useEffect(() => {
    const apiBlog = { ...CONSTANTS.API.getHomeBlog };
    setIsLoadingBlog(true);
    api.sendRequest(apiBlog, (res) => {
      console.log(res, "blog home");
      const data = res?.data?.rows?.map((data) => {
        return {
          id: data?.id,
          blogId: data?.blog?.id,
          index: data?.index,
          alt: data?.blog?.alt,
          title: data?.blog?.title,
          image: data?.blog?.image,
        };
      });

      setBlogData(data);
      setIsLoadingBlog(false);
    });
  }, [refreshBlog]);

  useEffect(() => {
    const apiList = { ...CONSTANTS.API.getHomeListing };
    setIsLoadingListicle(true);
    api.sendRequest(apiList, (res) => {
      console.log(res, "list home");
      const data = res?.data?.rows?.map((data) => {
        return {
          id: data?.id,
          listingId: data?.listing?.id,
          index: data?.index,
          alt: data?.listing?.alt,
          title: data?.listing?.title,
          image: data?.listing?.image,
        };
      });

      setListData(data);
      setIsLoadingListicle(false);
    });
  }, [refreshList]);

  return (
    <>
      {/* {api.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content pt-28" />
        </Spin>
      ) : ( */}
      <Row className="py-5">
        <Col xs={24} className="pb-5">
          <h1 className="">Tools</h1>
          {isLoadingTool ? (
            <div className="flex justify-center">
              <Spin tip="Loading" size="large">
                <div className="content pt-28 " />
              </Spin>
            </div>
          ) : (
            <CustomDND
              data={toolData}
              card={"ToolCard"}
              setRefresh={setRefresh}
              editApi={CONSTANTS.API.editHomeTool}
              payloadIdKey={"toolId"}
              grid={4}
              height={150}
            />
          )}
        </Col>
       
          <Col xs={24} className="pb-5">
            <h1 className="">Blogs</h1>
            {isLoadingBlog ? (
            <div className="flex justify-center">
              <Spin tip="Loading" size="large">
                <div className="content pt-28 " />
              </Spin>
            </div>
          ) : (
            <CustomDND
              data={blogData}
              card={"BlogCard"}
              setRefresh={setRefreshBlog}
              editApi={CONSTANTS.API.editHomeBlog}
              payloadIdKey={"blogId"}
              grid={3}
              height={210}
            />)}
          </Col>
      
       
          <Col xs={24} className="">
            <h1 className="">Listicle</h1>
            
            {isLoadingListicle ? (
            <div className="flex justify-center">
              <Spin tip="Loading" size="large">
                <div className="content pt-28 " />
              </Spin>
            </div>
          ) : (
            <CustomDND
              data={listData}
              card={"ListicleCard"}
              setRefresh={setRefreshList}
              editApi={CONSTANTS.API.editHomeListing}
              payloadIdKey={"listingId"}
              grid={3}
              height={210}
            />)}
          </Col>
        
      </Row>
      {/* )} */}
    </>
  );
};

export default Home;
