import React from "react";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import PageComponent from "../../../../component/common/Page-Component";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const UserToolWishlist = ({ dataId }) => {
  let list = { ...CONSTANTS.API.getUserToolWishlist };
  const apitoolList = apiGenerator(list, { dataId });
  const navigate=useNavigate();
  const handleViewTool=(id)=>{
    navigate(`/app/tool/${id}`)
  }
  return (
    <div>
      <div className="mt-5 mx-3">
        <PageComponent
          tableHeaders="USERTOOLWISHLIST"
          tableTitle="Tool Wishlists"
          getAPI={apitoolList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data.tool,
                no: `TW${data?.id.toString().padStart(7, "0")}`, 
                view:{
                  id:data?.toolId,
                  onClick:(id)=>{handleViewTool(id)}
                },
                date:moment(data?.createdAt).format('lll'),              
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/tool?search="
          // viewData
          // viewFunction={handleViewTool}
        />
      </div>
    </div>
  );
};

export default UserToolWishlist;
