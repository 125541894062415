import React, { useEffect, useState } from 'react'
import BlogView from '../blogView'
import { Card, Col, Row } from 'antd';
import CustomTextSlider from '../../../../component/common/CustomTextSlider';
import useHttp from '../../../../hooks/use-http';
import { useParams } from 'react-router-dom';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { apiGenerator } from '../../../../util/functions';

const BlogOverview = () => {
    const [blogData, setblogData] = useState({});
    const { id } = useParams();
    const api = useHttp();
    useEffect(() => {
      const OneBlog = { ...CONSTANTS.API.getOneBlog };
      const apiOneBlog = apiGenerator(OneBlog, { id });
      api.sendRequest(apiOneBlog, (res) => {
        setblogData(res?.data);
      });
    }, []);
    const formatDate = (dateStr) => {
      const dateObj = new Date(dateStr);
      const day = dateObj.getDate();
      const monthAbbreviation = dateObj.toLocaleString("default", {
        month: "short",
      });
      return `${day} ${monthAbbreviation}`;
    };
  return (
    <>
      <Card title="" bordered={false} className="">
        <div className="p-[30px]">
          <Row className="items-center">
            <Col span={4}>
              <div className="flex items-center justify-between">
                <CustomTextSlider
                  data={blogData?.blogCategories?.map((i, idx) => {
                    return (
                      <span className="flex justify-center items-center px-5 py-1 rounded-full bg-[#1212120d]">
                        {i?.categoryOfBlog?.name}
                      </span>
                    );
                  })}
                />
              </div>
            </Col>
            <Col span={5} className="items-center">
              <span className="opacity-50 ms-2">{blogData?.readTime} Min Read</span>
            </Col>
            <Col span={15}>
              <span className="flex items-center justify-end opacity-50">
                {formatDate(blogData?.createdAt)}
              </span>
            </Col>
          </Row>
          <Row className="gap-y-10">
            <Col span={24}>
              <span className="text-[48px] font-medium">{blogData?.title}</span>
              <div>
                {blogData?.blogTags?.map((i, idx) => {
                  return (
                    <span key={idx} className="m-0 opacity-50 me-2">
                      #{i?.tag?.name}
                    </span>
                  );
                })}
              </div>
            </Col>
            <Col span={24}>
              <div className="flex justify-center items-center">
                <div className="rounded-[20px] border border-[#12121280] overflow-hidden">
                  <img
                    src={blogData?.image}
                    alt="blog"
                    width="640px"
                    height="384px"
                    className="object-cover"
                  />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="blog-content-html" dangerouslySetInnerHTML={{ __html: blogData?.overview }} />
            </Col>
          </Row>
        </div>
      </Card>
    </>
  )
}

export default BlogOverview
