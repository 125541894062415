import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, notification } from "antd";
import FormFields from "./FormFields";
import useHttp from "../../hooks/use-http";
import { apiGenerator } from "../../util/functions";
import { useLocation } from "react-router-dom";
import { PATHNAME } from "../../util/constant/CONSTANTS";
const ModalFAQ = ({
  open,
  onCreate,
  onCancel,
  name = "",
  formData = {},
  menu,
  disabled = false,
  edit,
  SubmitName = "Submit",
  onEdit = () => {},
  Delete,
  onDelete = () => {},
  formFields = [],
  loading = false,
  getAPI = null,
  dataId = null,
  children,
}) => {
  const api = useHttp();
  const [form] = Form.useForm();
  const [faqData, setFaqData] = useState({});
  const formRef = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    if (open) {
      const oneFAQ = { ...getAPI };
      const apiOneFAQ = apiGenerator(oneFAQ, { dataId });
      api.sendRequest(apiOneFAQ, (res) => {
        setFaqData(res?.data);
        form.setFieldsValue({
          faqs: res?.data?.faqs?.map((item, index) => ({
            ...item,
          })),
        });
      });
    }
  }, [open, formData.faqs]);
// console.log(faqData)
  useEffect(() => {
    if (pathname == PATHNAME.TOOL || pathname == PATHNAME.TOOLSHEDULE) return;
    if (open) {
      const handleKeyPress = (e) => {
        if (e?.key === "Enter" && !e?.shiftKey) {
          form
            .validateFields()
            .then((values) => {
              onCreate(values, () => {
                form?.resetFields();
              });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [open]);
  return (
    <Modal
      open={open}
      title={<p className="text-2xl mb-10 text-slate-500">{name}</p>}
      okText="Submit"
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
      className="form-modal-title"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // console.log(values, "*-*-*-value faq");
            const filteredData = values?.faqs?.filter(
              (item) => item.answer === "<p><br></p>"
            );

            // Log the filtered data
            // console.log(filteredData);

            // If there are matching objects, log an error
            if (filteredData?.length > 0) {
              notification.error({
                message: `Please fill the FAQ answer field!`,
                duration: "3",
              });
              // console.error(
              //   "Error: Found objects with '<p><br></p>' in the 'answer' property"
              // );
            } else {
              onCreate(values, () => {
                form.resetFields();
              });
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <FormFields
        formData={faqData || {}}
        menu={menu}
        formFields={formFields}
        form={form}
        disabled={disabled}
        formRef={formRef}
      />
      {children}
    </Modal>
  );
};

export default ModalFAQ;
ModalFAQ.defaultProps = {
  open: false,
  onCreate: () => {},
  onCancel: () => {},
  name: "",
  formData: {},
  menu: "",
  edit: false,
  onEdit: () => {},
  formFields: [],
};
