import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../util/functions";
import ModalFormCreator from "../ModalFormCreator";
import {
    EditOutlined,
  } from "@ant-design/icons";
const ListicleCard = ({ data, setRefresh }) => {
  const api = useHttp();
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const apiList = { ...CONSTANTS.API.getListicleNameList };
      api.sendRequest(apiList, (res) => {
        const list = res?.data?.map((i) => {
          return {
            ...i,
            Label: i?.title,
            value: i?.id,
            id: i?.id,
          };
        });
        CONSTANTS.FORM_FIELD.EDIT_HOME_LISTICLE.find(
          (el) => el?.id === "listingId"
        ).option = list;
      });
    }
  }, [isOpen]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleOpenModel = () => {
    setIsOpen(true);
  };
  const handleEditListicle = (val) => {
    const list = { ...CONSTANTS.API.editHomeListing };
    const apilist = apiGenerator(list, { dataId: data?.id });
    const payload = {
      listingId: val?.listingId,
      index: data?.index,
    };
    api.sendRequest(
        apilist,
      (res) => {
        setIsOpen((prv) => !prv);
        setRefresh((prv) => !prv);
      },
      payload,
      "Edit Listicle Successfully!!!"
    );
  };

  return (
    <>
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
         <Card>
          <div className="grid grid-flow-col gap-x-4 items-center">
          <img alt={data?.alt} src={data?.image} width={250}  className="object-contain rounded-lg"/>
          <p className="mb-0 line-clamp-3 text-xl font-semibold">{data?.title}</p>
          </div>
        </Card>
        <div
          className={`bg-dark-mode-600/50 w-full h-full absolute top-0 rounded-lg transition-container ${
            isHovered ? `active` : ``
          }`}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -48%)",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                handleOpenModel(data?.id);
              }}
            >
              <p className=" ">
                <EditOutlined />
              </p>
            </Button>
          </div>
        </div>
      </div>
      {isOpen ? <ModalFormCreator
            open={isOpen}
            onCreate={handleEditListicle}
            onCancel={() => {
              setIsOpen((prev) => !prev);
            }}
            name={'Edit Home Listicle'}
            menu={'EDIT_HOME_LISTICLE'}
            loading={api.isLoading}
            formData={{ ...data }}
            // modalWidth={modalWidth}
          /> :null}
    </>
  );
};

export default ListicleCard;
