import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { apiGenerator } from '../../../../util/functions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const UserToolLike = ({dataId}) => {
    let list = { ...CONSTANTS.API.getUserToolLike };
    const apiToolList = apiGenerator(list, { dataId });
    const navigate=useNavigate();
    const handleViewTool = (id) => {
      navigate(`/app/tool/${id}`);
    };
  return (
    <>
      <div className="mt-5 mx-3">
        <PageComponent
          tableHeaders="USERTOOLLIKE"
          tableTitle="Tool Likes"
          getAPI={apiToolList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data.tool,
                no: `TL${data?.id.toString().padStart(7, "0")}`, 
                view:{
                    id:data?.toolId,
                    onClick:(id)=>{handleViewTool(id)}
                  },
                date:moment(data?.createdAt).format('lll'),            
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/blog?search="
        //   viewData
        //   viewFunction={handleViewTool}
        />
      </div>
    </>
  )
}

export default UserToolLike
