import { Avatar, Card, Col, Input, Row, Select, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../hooks/use-http";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../util/functions";
import { useMixpanel } from "react-mixpanel-browser";
import TextEditorSimple from "../../../component/common/Text-Editor-Simple";
import { DownCircleOutlined } from "@ant-design/icons";
import axios from "axios";

const SingleSubmitTool = () => {
  const API = useHttp();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  // const Admin=JSON.parse(localStorage.getItem('admin'));
  // const Token=localStorage.getItem('token');
  // const mixpanel = useMixpanel();
  // const statusList = [
  //   {
  //     value: "Pending",
  //     Label: "Pending",
  //   },
  //   {
  //     value: "OnGoing",
  //     Label: "OnGoing",
  //   },
  //   {
  //     value: "Approved",
  //     Label: "Approved",
  //   },
  //   {
  //     value: "Denied",
  //     Label: "Denied",
  //   },
  // ];
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    const dataId = id;
    const apiList = { ...CONSTANTS.API.getOneSubmitTool };
    const apiData = apiGenerator(apiList, { dataId });
    API.sendRequest(apiData, (res) => {
      setData(res?.data);
      setLoading(false);
    });
  };
  // console.log(data);
  // const handleSelect = (val) => {
  //   const dataId = id;
  //   const apiList = { ...CONSTANTS.API.editSubmitTool };
  //   const apiData = apiGenerator(apiList, { dataId });
  //   API.sendRequest(
  //     apiData,
  //     (res) => {
  //       fetchData();
  //       setLoading(true);
  //       mixpanel.identify(Admin?.email);
  //       mixpanel.track('Edit Submit Tool Status', {
  //         Token: Token,
  //         Admin: {id:Admin?.id,name:Admin?.name},
  //         ExtraData:{Id:id,Status:val}
  //       });
  //     },
  //     { status: val }
  //   );
  // };
  // const downloadImage = async(imageUrl,title) => {
  //   console.log(imageUrl,'*--*img')
  //   const response = await fetch(imageUrl);
  //   //  const response = await fetch(`/proxy?url=${encodeURIComponent(imageUrl)}`);
  //   console.log(response,'res')
  //   const blob = await response.blob();
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement('a');
  //   const fileName = `${title}.${getImageExtension(imageUrl)}`;
  //   link.href = url;
  //   link.setAttribute('download', fileName); // You can set the file name here
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const toDataURL = async (url) => {
    /* Using Fetch */
    // const response = await fetch(url)
    // const blobData = await response.blob()
    // const imageDataUrl = URL.createObjectURL(blobData);

    /* Using Axios */
    // const response = await axios.get(url, { responseType: "blob" });
    // const imageDataUrl = URL.createObjectURL(response.data);
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const imageDataUrl = URL.createObjectURL(response.data);
      return imageDataUrl;
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error; // Rethrow the error to handle it where `toDataURL` is called.
    }
    // return imageDataUrl;
  };

  const downloadImage = async (IMAGE_URL) => {
    const a = document.createElement("a");
    a.href = await toDataURL(
      "https://toolplate-staging.s3.ap-south-1.amazonaws.com/6eb5c667-ca89-40db-bbbc-a341754c3f09.png"
    );
    a.download = "myImage.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  //   const downloadImage = async (imageUrl, title) => {
  //     try {
  //         // const response = await fetch(`/proxy?url=${encodeURIComponent(imageUrl)}`);
  //         const response = await fetch(imageUrl);
  //         const blob = await response.blob();

  //         // Create a temporary anchor element
  //         const link = document.createElement('a');
  //         const fileName = `${title}.${getImageExtension(imageUrl)}`;

  //         // Set anchor's href to the image URL
  //         link.href = URL.createObjectURL(blob);
  //         link.setAttribute('download', fileName);

  //         // Programmatically trigger a click on the anchor to initiate download
  //         link.click();
  //     } catch (error) {
  //         console.error('Failed to download image:', error);
  //     }
  // };

  // // Function to extract image extension from URL
  function getImageExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }
  return (
    <div className="py-5">
      {loading ? (
        <Spin className="py-5" tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <Row className="py-5 gap-x-3 select-text">
          <Col span={7} xs={24} sm={24} md={24}>
            <Col className="">
              <Row className="gap-7">
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={7}>
                  <Card className="md:h-52">
                    <h2 className="text-light-gray-500 font-semibold">
                      User Details:
                    </h2>

                    <div className="md:flex md:justify-between md:items-center">
                      <div>
                        <h1 className="mb-0 ">
                          {data?.firstName} {data?.lastName}
                        </h1>
                        <h4 className="">{data?.email}</h4>
                      </div>
                      <div className="md:text-end">
                        <h3>{data?.country}</h3>
                        <h4>{data?.phone}</h4>
                      </div>
                    </div>
                    {data?.status === "Approved" ? (
                      <Tag color="green" className="font-semibold mb-1">
                        {"Featured"}
                      </Tag>
                    ) : data?.status === "OnGoing" ? (
                      <Tag color="blue" className="font-semibold mb-1">
                        {data?.status}
                      </Tag>
                    ) : data?.status === "Denied" ? (
                      <Tag color="red" className="font-semibold mb-1">
                        {"Rejected"}
                      </Tag>
                    ) : (
                      <Tag color="orange" className="font-semibold mb-1">
                        {data?.status}
                      </Tag>
                    )}
                    {/* <div className="flex justify-between mt-3">
                      <Select
                        defaultValue={data?.status || "Pending"}
                        style={{
                          width: 120,
                        }}
                        onChange={(val) => {
                          handleSelect(val);
                        }}
                        disabled={
                          data?.status == "Approved" || data?.status == "Denied"
                            ? true
                            : false
                        }
                        options={statusList?.map((item) => ({
                          value: item.value,
                          label:
                            item?.Label == "Approved" ? (
                              <Tag color="green" className="font-semibold mb-1">
                                {item?.Label}
                              </Tag>
                            ) : item?.Label == "OnGoing" ? (
                              <Tag color="blue" className="font-semibold mb-1">
                                {item?.Label}
                              </Tag>
                            ) : item?.Label == "Denied" ? (
                              <Tag color="red" className="font-semibold mb-1">
                                {item?.Label}
                              </Tag>
                            ) : (
                              <Tag
                                color="orange"
                                className="font-semibold mb-1"
                              >
                                {item?.Label}
                              </Tag>
                            ),
                        }))}
                      />
                    </div> */}
                  </Card>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={7}>
                  <Card className="h-52">
                    <h2 className="text-light-gray-500 font-semibold">
                      Tool Details:
                    </h2>

                    <h2 className="mb-0 line-clamp-2">{data?.title}</h2>
                    <h4 className="">
                      <a
                        className="text-blue-700 hover:text-blue-500"
                        target="_blank"
                        href={data?.link}
                      >
                        {data?.link}
                      </a>
                    </h4>

                    {data?.submitToolCategories?.map((i) => (
                      <Tag color="#5A4FCF" className="font-semibold mt-2">
                        {i?.category?.name}
                      </Tag>
                    ))}
                  </Card>
                </Col> */}
              </Row>
              <Card className="lg:my-3 my-7">
                <h2 className="text-light-gray-500 font-semibold">
                  Tool Details:
                </h2>
                <Row className="gap-y-4 items-center">
                  <Col xs={2}>
                    <h3>Name:</h3>
                  </Col>
                  <Col xs={22}>
                    <h4 className="capitalize">{data?.title}</h4>
                  </Col>
                  <Col xs={2}>
                    <h3>Link:</h3>
                  </Col>
                  <Col xs={22}>
                    <h4 className="">
                      <a
                        className="text-blue-700 hover:text-blue-500"
                        target="_blank"
                        href={data?.link}
                        title="Link"
                        rel="noreferrer"
                      >
                        {data?.link}
                      </a>
                    </h4>
                  </Col>
                  <Col xs={2}>
                    <h3>Category:</h3>
                  </Col>
                  <Col xs={22}>
                    {data?.submitToolCategories?.length
                      ? data?.submitToolCategories?.map((i) => (
                          <Tag color="#5A4FCF" className="font-semibold">
                            {i?.category?.name}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                  <Col xs={2}>
                    <h3>Video Link:</h3>
                  </Col>
                  <Col xs={22}>
                    <h4 className="select-text">{data?.video ? <a href={data?.video} target="_blank" rel="noreferrer">{data?.video}</a> :"-"}</h4>
                  </Col>
                  <Col xs={2}>
                    <h3>Logo:</h3>
                  </Col>
                  <Col xs={22}>
                    {/* <Avatar shape="square" size={80} src={data?.logo} /> */}
                    {/* <a href={data?.logo} download><button>Download</button></a> */}
                    <a href={data?.logo} download target="_blank" rel="noreferrer">
                      <div className="relative w-fit">
                        <Avatar shape="square" size={80} src={data?.logo} />
                        <span className="absolute top-1 right-1 bg-primary-p-blue-100 p-1 rounded-md hover:scale-[1.1] cursor-pointer">
                          <DownCircleOutlined />
                        </span>
                      </div>
                    </a>
                    {/* onClick={()=>{downloadImage(data?.logo,`${data?.title} Logo`)}} */}
                  </Col>
                  <Col xs={2}>
                    <h3>Preview Images:</h3>
                  </Col>
                  <Col xs={22}>
                    {/* <div className="flex gap-x-4">
                      {data?.previews?.map((i, index) => {
                        return <Avatar shape="square" size={120} src={i} />;
                      })}
                    </div> */}
                    {/* onClick={()=>{downloadImage(i,`${data.title}_${index}`)}} */}
                    <div className="flex gap-x-4">
                      {data?.previews?.map((i, index) => {
                        return (
                          <a href={i} download target="_blank" rel="noreferrer">
                            <div key={index} className="relative">
                              <Avatar shape="square" size={120} src={i} />
                              <span className="absolute top-1 right-1 bg-primary-p-blue-100 p-1 rounded-md hover:scale-[1.1] cursor-pointer">
                                <DownCircleOutlined />
                              </span>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </Col>
                  <Col xs={2}>
                    <h3>Overview:</h3>
                  </Col>
                  <Col xs={22}>
                    <TextEditorSimple
                      value={data?.overview || ""}
                      name={"overview"}
                      onChange={() => {}}
                    ></TextEditorSimple>
                  </Col>
                </Row>

                {/* <Input
                name={data?.name}
                id={data?.id}
                className="hidden"
              /> */}
              </Card>
              <Card className="lg:my-3 my-7">
                <h2 className="text-light-gray-500 font-semibold">Message:</h2>
                <p className="text-base">{data?.message}</p>
              </Card>
            </Col>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SingleSubmitTool;
