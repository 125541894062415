
import React, { useState } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';

const ListiclesCategory = () => { 

  return (
    <>
       <PageComponent
       trackEventName='Listicles Category'
        tableHeaders="LISTICLESCATEGORY"
        tableTitle="Listicles Category List"
        getAPI={CONSTANTS.API.getListiclesCategory}
        getData={(res)=>{
            return res?.map((data,idx)=>{
                return {
                    ...data,
                    no: `LC${data?.id.toString().padStart(7, "0")}`,
                    date:moment(data?.createdAt).format('lll'),
                    // description:data?.description != null ? data?.description : "",                      
                }
            })
        }}
        extraResData="rows"
        addData
        modalButton="Add New Listicle Category"
        addModalTitle="Add New Listicle Category"
        editModalTitle="Edit Listicles Category"
        modalFields="LISTICLES_CATEGORY_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addListiclesCategory}
        editAPI={CONSTANTS.API.editListiclesCategory}
        editData
        deleteData
        deleteAPI={CONSTANTS.API.deleteListiclesCategory}
        searchfilter
        isSearch={true}
        searchAPI="/admin/category-listicles?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.LISTICLESCATEGORY}
        extraFilterParam=""
        modalWidth={1100}
      />
    </>
  )
}

export default ListiclesCategory
