import React, { useState } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Main_Category = () => { 
    const navigate=useNavigate();
  const handleView=(id,name)=>{
    navigate(`/app/category/main-category/${id}`,{ state: { categoryId: id ,categoryName:name} });
  }
  return (
    <>
       <PageComponent
       trackEventName='Main Category'
        tableHeaders="MAINCATEGORY"
        tableTitle="Main Category List"
        getAPI={CONSTANTS.API.getMainCategory}
        getData={(res)=>{
            return res?.map((data,idx)=>{
                return {
                    ...data,
                    no: `MC${data?.id.toString().padStart(7, "0")}`,
                    date:moment(data?.createdAt).format('lll'),
                    description:data?.description != null ? data?.description : "",
                    view: {
                        id: data?.id,
                        name:data?.name,
                        onClick: (id,name) => {
                          handleView(id,name);
                        },
                      },            
                }
            })
        }}
        extraResData="rows"
        formData
        modalButton="Add New Main Category"
        addModalTitle="Add New Main Category"
        editModalTitle="Edit Main Category"
        modalFields="MAIN_CATEGORY_MODAL"
        editModalFields="EDIT_MAIN_CATEGORY_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addMainCategory}
        editAPI={CONSTANTS.API.editMainCategory}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteMainCategory}
        searchfilter
        isSearch={true}
        searchAPI="/admin/main-category?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.CATEGORY}
        extraFilterParam=""
        modalWidth={1100}
      />
    </>
  )
}

export default Main_Category
