import { Row } from "antd";
import React, { memo, useEffect, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import ToolCard from "./Card/ToolCard";
import CONSTANTS from "../../util/constant/CONSTANTS";
import { apiGenerator } from "../../util/functions";
import useHttp from "../../hooks/use-http";
import BlogCard from "./Card/BlogCard";
import ListicleCard from "./Card/ListicleCard";

const CustomDND = ({ data, card, setRefresh ,editApi,payloadIdKey,grid ,height}) => {
  const [images, SetImages] = useState([]);
  const api = useHttp();
  console.log(data);
  useEffect(() => {
    SetImages(data);
  }, [data]);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(images, sourceIndex, targetIndex);
    // const length = nextState.length;
    SetImages(nextState?.map((el, i) => ({ ...el, index: i + 1 })));
    // console.log(
    //   nextState,
    //   nextState?.map((el, i) => ({ id: el?.id, index: i + 1 }))
    // );

    const apiData = { ...editApi };
    const promises = nextState?.map((item, index) => {
      return new Promise((resolve, reject) => {
        const apiCall = apiGenerator(apiData, { dataId: item?.id });
        const payload = {
          [payloadIdKey]: item?.[payloadIdKey],
          index: index + 1,
        };
        api.sendRequest(
          apiCall,
          (res) => {
            resolve(res);
          },
          payload,
          // {},
          // (error) => {
          //   reject(error);
          // }
        );
      });
    });
    
    Promise.all(promises)
      .then((responses) => {
        // Handle responses from all API calls
      })
      .catch((error) => {
        console.log(error,"error custom DND")
        // Handle error if any of the API calls fail
      });
    
  }
  console.log(images, "*-*-set");
  return (
    <div>
      <Row>
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={grid}
            rowHeight={250}
            style={{
              height: `${Math?.ceil(images?.length / 4) * height}px`,
              width: "100%",
            }}
          >
            {images?.map((item) => (
              <GridItem
                key={item?.id}
                className="griditemUI px-1 lg:px-1 xl:px-2 2xl:px-3 "
                // style={{ height: "400px" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* {Card({data:item,setRefresh:{setRefresh}})} */}

                  {card === "ToolCard" ? (
                    <ToolCard data={item} setRefresh={setRefresh} />
                  ) : card === "BlogCard" ? <BlogCard data={item} setRefresh={setRefresh} />: card === "ListicleCard" ? <ListicleCard data={item} setRefresh={setRefresh} /> : null}
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      </Row>
    </div>
  );
};

export default memo(CustomDND);
