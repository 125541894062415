import { setCookie, getCookie, eraseCookie } from "../Cookies";

export const getAuthToken = () => {
  return getCookie("SAID");
};

export const setAuthDetails = (accessToken,admin) => {
  setCookie("SAID", accessToken, 365);
  setCookie("ADMIN", admin, 365);
  localStorage.setItem("token", accessToken);
  localStorage.setItem("admin", JSON.stringify(admin));
};

export const deleteAuthDetails = () => {
  eraseCookie("SAID");
};
