import {
  BoxAdd,
  Category,
  Chart,
  Educare,
  Home2,
  Link2,
  Magicpen,
  Minus,
  Notification,
  NotificationStatus,
  Receipt2,
  Receipt21,
  ReceiptItem,
  Sms,
  User,
} from "iconsax-react";
const data = [
  {
    id: "dashboard",
    icon: <Chart size={20} />,
    label: "Dashboard",
  },
  {
    id: "users",
    icon: <User size={20} />,
    label: "User",
  },
  {
    id: "blog",
    icon: <Receipt21 size={20} />,
    label: "Blog",
  },
  {
    id: "tool",
    icon: <Magicpen size={20} />,
    label: "Tool",
  },
  {
    id: "listicles",
    icon: <ReceiptItem size={20} />,
    label: "Listicles",
  },
  {
    id: "news",
    icon: <NotificationStatus size={20} />,
    label: "News",
  },
  {
    id: "category",
    icon: <Category size={20} />,
    label: "Categories",
    subMenu: [
      { id: "main-category", icon: <Minus size={20} />, label: "Main Category" },
      { id: "sub-category", icon: <Minus size={20} />, label: "Sub Category" },
      { id: "blog-category", icon: <Minus size={20} />, label: "Blog Category" },
      { id: "listicles-category", icon: <Minus size={20} />, label: "Listicles Category" },
      {id: "news-category",icon: <Minus size={20} />,label: "News Category",},
    ],
  },
  {
    id: "submit-tool",
    icon: <BoxAdd  size={22}/>,
    label: "Submit Tool",
  },
  {
    id: "home",
    icon: <Home2  size={20}/>,
    label: "Home",
  },
  {
    id: "redirect-url",
    icon: <Link2  size={20}/>,
    label: "Redirect Url",
  },
  {
    id: "pitch",
    icon:<Sms size={21} />,
    label: "Pitch",
  },
  {
    id: "notification",
    icon: <Notification size={20} />,
    label: "notification",
  },

];
export default data;
