
import React, { useState } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const BlogCategory = () => { 
    const navigate=useNavigate();
  const handleView=(id,name)=>{
    navigate(`/app/category/sub-category`,{ state: { categoryId: id ,categoryName:name} });
  }
  return (
    <>
       <PageComponent
       trackEventName='Blog Category'
        tableHeaders="BLOGCATEGORY"
        tableTitle="Blog Category List"
        getAPI={CONSTANTS.API.getBlogCategory}
        getData={(res)=>{
            return res?.map((data,idx)=>{
                return {
                    ...data,
                    no: `BC${data?.id.toString().padStart(7, "0")}`,
                    date:moment(data?.createdAt).format('lll'),
                    // description:data?.description != null ? data?.description : "",
                    view: {
                        id: data?.id,
                        name:data?.name,
                        onClick: (id,name) => {
                          handleView(id,name);
                        },
                      },            
                }
            })
        }}
        extraResData="rows"
        addData
        modalButton="Add New Blog Category"
        addModalTitle="Add New Blog Category"
        editModalTitle="Edit Blog Category"
        modalFields="BLOG_CATEGORY_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addBlogCategory}
        editAPI={CONSTANTS.API.editBlogCategory}
        editData
        deleteData
        deleteAPI={CONSTANTS.API.deleteBlogCategory}
        searchfilter
        isSearch={true}
        searchAPI="/admin/category-blog?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.BLOGCATEGORY}
        extraFilterParam=""
        modalWidth={1100}
      />
    </>
  )
}

export default BlogCategory
