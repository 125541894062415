export const apiGenerator = (apiObject, exchangePair = {}, join = null) => {
  const apiObj = { ...apiObject };
  if (Object.keys(exchangePair).length) {
    Object.keys(exchangePair).forEach((el) => {
      apiObj.endpoint = apiObj.endpoint.replace(`:${el}`, exchangePair[el]);
    });
  }

  if (join) {
    apiObj.endpoint = `${apiObj.endpoint}${join}`;
  }
  return apiObj;
};
export function changePremiumtoPaid(inputString) {
  // Convert both the input string and the target string to lowercase
  const lowercasedInput = inputString?.toLowerCase();
  const targetString = "premium";

  // Check if the lowercase input string is equal to the lowercase target string
  if (lowercasedInput === targetString) {
    return "paid";
  }

  // If the input string doesn't match any specific condition, handle it here
  return inputString;
}
export function capitalizeFirstLetter(inputString) {
  return inputString?.charAt(0)?.toUpperCase() + inputString?.slice(1);
}
export function removeEmptyStringValues(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      // Check for empty string, empty array, or null
      return value !== '';
      // return value !== '' && (Array.isArray(value) ? value.length !== 0 : value !== null);
    })
  );
}
export function removeEmptyArrayValues(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      // Check for empty string, empty array, or null
      // return value !== '';
      return (Array.isArray(value) ? value.length !== 0 : value !== null);
    })
  );
}
export function formatStringToSlug(str) {
  return str
  .toLowerCase() // Convert to lowercase
  .replace(/[^a-z\s]/g, '') // Remove non-alphabetic characters
  .replace(/\s+/g, '-') // Replace spaces with hyphens
  .replace(/-+$/, '') // Remove trailing hyphens
  .trim(); // Trim leading and trailing spaces
}
export const ChangeValueSortBY = {
  date:'createdAt',
  no:'id',
  sender:'$admins.name$'
};
export const buildQueryString = (params) => {
  const queryParts = [];

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];

      if (key.startsWith("autogenerate-mul-array-") && Array.isArray(value)) {  
        // Handle autogenerate-mul-array- with array values
        // console.log(key, "key");
        const arrayKey = key.slice("autogenerate-mul-array-".length);
        value.forEach((item) => {
          queryParts.push(
            `${encodeURIComponent(arrayKey)}=${encodeURIComponent(item)}`
          );
        });
      } else {
        // Handle other cases
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        );
      }
    }
  }

  return queryParts.length > 0 ? `?${queryParts.join("&")}` : "";
};