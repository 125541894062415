import { Button } from "antd";
import React, { memo, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import useHttp from "../../hooks/use-http";
import CONSTANTS from "../../util/constant/CONSTANTS";
import moment from "moment";

const Custom_AllData_CSVExport = ({ getAPI, extraResData, tableHeaders , getData}) => {
  const [loadCSV, setLoadCSV] = useState(false);
  const [csvData, setCSVData] = useState(null);
  const csvInstance = useRef();
  const api = useHttp();
 
  const handleDownload = async () => {
    try {
      setLoadCSV(true);
      let API_CALL = { ...getAPI };

      // Perform your API call to fetch data
      api.sendRequest(API_CALL, (res) => {
        let API_RESPONSE_DATA = res?.data;

        if (extraResData) {
          API_RESPONSE_DATA = API_RESPONSE_DATA[extraResData];
        }
     
        const modifiedData = getData(API_RESPONSE_DATA);
        // Convert modifiedData to table format
        const filteredTitles = CONSTANTS?.TABLE[tableHeaders]
          ?.map((el) => el?.title)
          .filter((title) => title !== "Actions" && title !== "" && title !== "View" && title !== "Block" && title !== "Change Status");
        const tableData = [
          filteredTitles,
          ...modifiedData?.map((item) =>
            CONSTANTS.TABLE[tableHeaders]
              ?.map((el) =>
                (item[el?.dataIndex] === undefined || typeof item[el?.dataIndex] === 'object') ? "" : item[el?.dataIndex]
              )
              .filter((value) => value !== "")
          ),
        ];

        // console.log(tableData, "table");
        // Set CSV data in state
        setCSVData(tableData);
        // onCreateCSVData();
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadCSV(false);
    }
  };
  useEffect(() => {
    setLoadCSV(false);
    if (csvData?.length) {
      onCreateCSVData();
      setCSVData([]);
    }
  }, [csvData?.length]);

  const onCreateCSVData = () => {
    // Check if CSV data is available
    // console.log(csvData, "outside");
    if (csvData) {
    //   console.log("inside");
      // Set interception to "off" to allow direct download without confirmation
      csvInstance.current.link.dataset.interception = "off";
      // Trigger the click on CSVLink
      csvInstance.current.link.click();
    }
  };
  return (
    <>
      <Button
      className="mt-5 ms-3"
   
        type="primary"
        // ghost
        loading={loadCSV}
        onClick={() => {
          handleDownload();
        }}
        // {...props.ButtonDefault}
      >
        All Data Export CSV
      </Button>

      {csvData && (
        <CSVLink
          data={csvData}
          ref={csvInstance}
         filename={`${tableHeaders}.csv`}
          // onClick={(event, done) => {
          //   // Reset CSV data after download
          //   setCSVData(null);
          //   done();
          // }}
        />
      )}
    </>
  );
};

export default memo(Custom_AllData_CSVExport);
