import React, { useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import useHttp from "../../../hooks/use-http";
import SubmitEditModal from "../../../component/submit/SubmitEditModal";

const SubmitTool = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const[editRenderData,setEditRenderData]=useState([]);
  const [reLoading,setReloading]=useState(false);
  const handleView = (id) => {
    navigate(`/app/submit-tool/${id}`);
  };
  const API=useHttp();
  const selectDetail=(value)=>{
    const dataId=value?.id;
    const apiList = { ...CONSTANTS.API.editSubmitTool };
    const apiData = apiGenerator(apiList, { dataId });
    API.sendRequest(apiData, (res) => {
        setReloading((prv)=>!prv);
      },{status:value?.val});
  //  console.log(value)
  }
  const handleEdit = (id) => {
    setOpen(true);
  };
  return (
    <>
      <PageComponent
      trackEventName="Submitted Tool Status"
        tableHeaders="SUBMIT_TOOL"
        tableTitle="Submitted Tool List"
        getAPI={CONSTANTS.API.getSubmitTool}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `ST${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("lll"),
              // mainCategory:data?.mainCategory?.name,
              edit: {
                id: data?.id,
                onClick: (id) => {
                  handleEdit(id);
                  setEditRenderData(data)
                },
                status:data?.status,
              },
              view: {
                id: data?.id,          
                onClick: (id) => {
                  handleView(id);
                },
              },
            };
          });
        }}
        extraResData="rows"
        selectBoxData
        selectBoxFunction={selectDetail}
        reload={reLoading}
        // formData
        // modalButton="Add New News Category"
        // addModalTitle="Add New News Category"
        // editModalTitle="Edit News Category"
        // modalFields="NEWS_CATEGORY_MODAL"
        // editModalFields="EDIT_NEWS_CATEGORY_MODAL"
        datefilter
        // addAPI={CONSTANTS.API.addNewsCategory}
        // editformData
        // editAPI={CONSTANTS.API.editNewsCategory}
        deleteData
        deleteAPI={CONSTANTS.API.deleteSubmitTool}
        searchfilter
        isSearch={true}
        searchAPI="/admin/submit-tool?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.SUBMIT_TOOL}
        extraFilterParam="status"
      />
       {open ? (
        <SubmitEditModal
          open={open}
          onCancel={() => {
            setOpen((prev) => !prev);
          }}
          name={"Edit Submit Tool"}
          formData={{ ...editRenderData }}
          setReloading={setReloading}
          setOpen={setOpen}
        />
      ) : null}
    </>
  );
};

export default SubmitTool;
