import React, { useEffect, useRef, useState } from "react";
import useHttp from "../../hooks/use-http";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Spin,
  notification,
} from "antd";
import CustomTable from "./Custom-Table";
import ModalFormCreator from "./ModalFormCreator";
import {
  ChangeValueSortBY,
  apiGenerator,
  buildQueryString,
  removeEmptyStringValues,
} from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";
import moment from "moment";
import dayjs from "dayjs";
import Heading from "./Heading";
import CustomSearchBar from "./Custom-search";
import ModalEditImages from "./ModalEditImages";
import ModalFAQ from "./ModalFAQ";
import { useNavigate } from "react-router-dom";
import Custom_AllData_CSVExport from "./Custom-AllData-CSVExport";
import Custom_Filter_Select from "./CustomFilterSelect";
import { useMixpanel } from "react-mixpanel-browser";
import ModalMetaData from "./ModalMetaData";
import * as cheerio from "cheerio";
const PageComponent = ({
  tableTitle = "Data List",
  tableHeaders = "USERS",
  addModalTitle = "Add Modal Title",
  editModalTitle = "Edit Modal Title",
  editImagesModalFields = null,
  editImageModalTitle = "Edit Title Images",
  addFAQModalTitle = "Add Title FAQ",
  addMetadataModalTitle = "Add Title Metadata",
  modalFields = "USERS_MODAL",
  faqModalField = "",
  metadataModalField = "",
  editModalFields = null,
  modalButton = "Add Button Name",
  getImagesAPI = null,
  getFAQAPI = null,
  getMetadataAPI = null,
  getAPI = null,
  getData = (res) => res,
  formData = false,
  addData = false,
  viewData = false,
  viewFunction = (res) => res,
  selectBoxFunction = (res) => res,
  addAPI = null,
  addImagesAPI = null,
  addFAQAPI = null,
  addMetadataAPI = null,
  deleteData = false,
  editImages = false,
  addFAQ = false,
  addMetadata = false,
  deleteAPI = null,
  deleteImagesAPI = null,
  blockData = false,
  editAPI = null,
  editImagesAPI = null,
  editData = false,
  editformData = false,
  acceptRejectData = false,
  acceptRejectAPI = null,
  checkboxData = false,
  selectBoxData = false,
  dateTime = false,
  extraResData = "",
  DUMMY_DATA = null,
  filterparmas = false,
  filterList = [],
  defaultFilterOption = null,
  searchfilter = false,
  isSearch = false,
  searchAPI = null,
  datefilter = false,
  extraParams = "",
  payloadExtra = {},
  extraEndPointData = "",
  getNotify = null,
  schedulePage = null,
  scheduleTitle = "Schedule Title",
  extraScheduleState = {},
  chartData = null,
  setReloadChart = () => {},
  chartComponent = <></>,
  reload = null,
  extraFilter = false,
  extraFilterField = [],
  extraFilterParam = "",
  modalWidth = 700,
  trackEventName = "Event",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false);
  const [isMetadataModalOpen, setIsMetadataModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [renderData, setRenderData] = useState([]);
  const [exportAllDataApi, setExportAllDataApi] = useState(getAPI);
  const [Allfilter, setAllFilter] = useState(null);
  // const [filterData, setFilterData] = useState([])
  const [editRenderData, setEditRenderData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const mixpanel = useMixpanel();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [selectedOption, setSelectedOption] = useState(filterList[0]?.value);
  const [editImagesData, setEditImagesData] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [id, setId] = useState(null);
  // const [generalNotification, setGeneralNotification] = useState(false);
  const api = useHttp();
  const navigate = useNavigate();
  const Admin = JSON.parse(localStorage.getItem("admin"));
  const Token = localStorage.getItem("token");
  // console.log(totalRecords);
  const { RangePicker } = DatePicker;
  const [extraFilterData, setExtraFilterData] = useState({
    category: [],
    price: "",
    sort: "",
    sortBy: "",
  });

  // ADD Data API
  const addTableData = (val, formReset) => {
    console.log({ ...val });
    const value = removeEmptyStringValues(val);
    // console.log({ ...value });
    let isFormValid = true;
    let rawPayload = {};
    const formPayload = new FormData();
    CONSTANTS.FORM_FIELD[modalFields]
      .filter((ele) => ele.type == "SimpleRichTextArea")
      .forEach((ele) => {
        // console.log(ele,"*----ele")
        // const $ = cheerio.load(value[ele.id],null,false);
        // //array for table of content
        // console.log(value[ele.id],'orignal')
        // $("h2").replaceWith((_, e) => `<h1>${$(e).html()}</h1>`);
        // $("h3").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // $("h4").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // const modifiedHtmlString = $.html();
        // value[ele.id]=modifiedHtmlString;
        // console.log(value[ele.id],'*-*-updated')
        if (
          ele.id in value &&
          ele?.required &&
          (value[ele.id] === "<p><br></p>" || value[ele.id]?.trim() === "")
        ) {
          isFormValid = false;
          notification.error({
            message: `${ele.id} is required`,
            duration: "3",
          });
          return;
        }
      });
    const uniqueMultifieldData = [];
    CONSTANTS.FORM_FIELD[modalFields]
      .filter((ele) => ele.type === "multifield")
      .forEach((ele) => {
        const multifieldData = value[ele.id];

        if (multifieldData) {
          const uniqueData = [];

          multifieldData.forEach((item, index) => {
            // Check if the current object has unique key-value pairs
            const isUnique = !uniqueData.some((otherItem) =>
              Object.entries(item).some(([key, value]) =>
                Object.entries(otherItem).some(
                  ([otherKey, otherValue]) =>
                    key === otherKey && value === otherValue
                )
              )
            );

            if (isUnique) {
              uniqueData.push(item);
            } else {
              // Log or handle duplicates if needed
              // console.log(`Duplicate item found at index ${index + 1}:`, item);
              isFormValid = false;
              notification.error({
                message: `Please Enter Unique ${ele.Label}.`,
                duration: "3",
              });
              return;
            }
          });

          value[ele.id] = uniqueData;
          uniqueMultifieldData.push({ [ele.id]: uniqueData });
        }
      });

    if (isFormValid) {
      if (formData) {
        CONSTANTS.FORM_FIELD[modalFields].forEach((ele) => {
          if (
            ele.type !== "file" &&
            ele.type !== "fileWithPreview" &&
            ele.type !== "date" &&
            ele.type !== "multifield" &&
            ele.type !== "select" &&
            ele.type !== "extraMultiSingle"
          ) {
            // console.log(value[ele.id]);
            value[ele.id] && formPayload.append(ele.id, [value[ele.id]]);
          }
          if (
            (ele.type === "file" || ele.type === "fileWithPreview") &&
            value[ele.id]
          ) {
            if (Array.isArray(value[ele.id])) {
              for (let i = 0; i < value[ele.id]?.length; i++) {
                formPayload.append(ele.id, value[ele.id][i].originFileObj);
              }
            } else {
              value[ele.id] &&
                formPayload.append(ele.id, value[ele.id][0].originFileObj);
            }
          }
          if (ele.type === "multifield" || ele.type === "extraMultiSingle") {
            if (ele?.handler) {
              value[ele.id] &&
                formPayload.append(ele.id, ele?.handler(value[ele.id]));
            } else {
              value[ele.id] &&
                formPayload.append(ele.id, JSON.stringify(value[ele.id]));
            }
          }
          if (ele.type === "select") {
            if (Array.isArray(value[ele.id]) && value[ele.id]?.length) {
              value[ele.id] &&
                formPayload.append(ele.id, value[ele.id]?.join(","));
            } else if (!Array.isArray(value[ele.id]) && value[ele.id]) {
              value[ele.id] && formPayload.append(ele.id, value[ele.id]);
            } else {
              value[ele.id] && formPayload.append(ele.id, "[]");
              // formPayload.delete(ele.id);
            }
          }
          if (ele.type === "date") {
            if (dateTime && value[ele.id]) {
              const dateTimeValue = `${moment(value[ele.id].$d).format(
                "YYYY-MM-DD"
              )} ${moment(value[ele.id].$d, "HH:mm:ss")
                .utc()
                .format("HH:mm:ss")}`;
              // console.log(dateTimeValue);
              value[ele.id] && formPayload.append(ele.id, dateTimeValue);
            } else {
              value[ele.id] &&
                formPayload.append(
                  ele.id,
                  moment(value[ele.id].$d, "YYYY-MM-DD")
                    .utc()
                    .format("YYYY-MM-DD")
                );
            }
          }
        });
        Object.entries(payloadExtra).forEach((ele) => {
          formPayload.append(ele[0], ele[1]);
        });
      } else {
        CONSTANTS.FORM_FIELD[modalFields].forEach((ele) => {
          if (ele.type === "option") {
            const OpetionsArr = [];
            for (let i = 1; i <= 10000000; i++) {
              if (ele?.id + i in value) {
                OpetionsArr.push(value[ele?.id + i]);
                delete value[ele?.id + i];
              } else {
                break;
              }
            }
            rawPayload = { ...rawPayload, options: OpetionsArr.toString() };
          }
        });
        rawPayload = { ...value, ...payloadExtra, ...rawPayload };
      }
      const payload = formData ? formPayload : rawPayload;

      if (addAPI) {
        const ADD_API_CALL = { ...addAPI };
        // if(getNotify)
        // {
        //   api.sendRequest(
        //     ADD_API_CALL,
        //     () => {
        //       if(getNotify) setGeneralNotification((prev)=>!prev);
        //       setRefresh((prev) => !prev);
        //       formReset();
        //     },
        //     payload,
        //     "Send Notification Successfully!!!"
        //   );
        // }
        // else
        // {
        api.sendRequest(
          ADD_API_CALL,
          () => {
            setIsModalOpen((prev) => !prev);
            setRefresh((prev) => !prev);
            formReset();
            mixpanel.identify(Admin?.email);
            mixpanel.track(`Add ${trackEventName}`, {
              Token: Token,
              Admin: { id: Admin?.id, name: Admin?.name },
              ExtraData: { value },
            });
          },
          payload,
          "Add Data Successfully!!!"
        );
        // }
      }
    } else {
      return;
    }
  };
  // const handleNotify = () => {
  //   setGeneralNotification((prev) => !prev);
  // };
  // Delete Data API
  const deleteTableData = (dataId) => {
    // console.log(dataId);
    if (deleteAPI) {
      const DELETE_API_CALL = apiGenerator(deleteAPI, {
        dataId,
      });
      // console.log(DELETE_API_CALL);
      api.sendRequest(
        DELETE_API_CALL,
        () => {
          setRefresh((prev) => !prev);
          mixpanel.identify(Admin?.email);
          mixpanel.track(`Delete ${trackEventName}`, {
            Token: Token,
            Admin: { id: Admin?.id, name: Admin?.name },
            ExtraData: { Id: dataId },
          });
        },
        {},
        "Delete Data Successfully!!!"
      );
    }
  };

  // Edit Data API
  const editTableData = (value, formReset) => {
    console.log(value, "-*-*-*-*edit value");

    let isFormValid = true;
    let rawPayload = {};
    // console.log(value);
    const formPayload = new FormData();
    CONSTANTS.FORM_FIELD[editModalFields ? editModalFields : modalFields]
      .filter((ele) => ele.type == "SimpleRichTextArea")
      .forEach((ele) => {
        // const $ = cheerio.load(value[ele.id],null,false);
        // //array for table of content
        // $("h2").replaceWith((_, e) => `<h1>${$(e).html()}</h1>`);
        // $("h3").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // $("h4").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // const modifiedHtmlString = $.html();
        // value[ele.id]=modifiedHtmlString;
        if (
          ele.id in value &&
          ele?.required &&
          (value[ele.id] === "<p><br></p>" || value[ele.id].trim() === "")
        ) {
          isFormValid = false;
          notification.error({
            message: `${ele.id} is required`,
            duration: "3",
          });
          return;
        }
      });
    const uniqueMultifieldData = [];
    CONSTANTS.FORM_FIELD[modalFields]
      .filter((ele) => ele.type === "multifield")
      .forEach((ele) => {
        const multifieldData = value[ele.id];

        if (multifieldData) {
          const uniqueData = [];

          multifieldData.forEach((item, index) => {
            // Check if the current object has unique key-value pairs
            const isUnique = !uniqueData.some((otherItem) =>
              Object.entries(item).some(([key, value]) =>
                Object.entries(otherItem).some(
                  ([otherKey, otherValue]) =>
                    key === otherKey && value === otherValue
                )
              )
            );

            if (isUnique) {
              uniqueData.push(item);
            } else {
              // Log or handle duplicates if needed
              // console.log(`Duplicate item found at index ${index +  1}:`, item);
              isFormValid = false;
              notification.error({
                message: `Please Enter Unique ${ele.Label}.`,
                duration: "3",
              });
              return;
            }
          });

          value[ele.id] = uniqueData;
          uniqueMultifieldData.push({ [ele.id]: uniqueData });
        }
      });
    if (isFormValid) {
      if (editformData) {
        CONSTANTS.FORM_FIELD[
          editModalFields ? editModalFields : modalFields
        ].forEach((ele) => {
          if (
            ele.type !== "file" &&
            ele.type !== "fileWithPreview" &&
            ele.type !== "date" &&
            ele.type !== "number" &&
            ele.type !== "multifield" &&
            ele.type !== "select" &&
            ele.type !== "extraMultiSingle"
          ) {
            // console.log(value[ele.id]);
            value[ele.id] && formPayload.append(ele.id, value[ele.id]);
          }

          if (ele.type === "number") {
            // console.log(value[ele.id]);
            value[ele.id] && formPayload.append(ele.id, +value[ele.id]);
          }

          if (
            (ele.type === "file" || ele.type === "fileWithPreview") &&
            value[ele?.id]
          ) {
            if (Array.isArray(value[ele.id])) {
              for (let i = 0; i < value[ele.id]?.length; i++) {
                formPayload.append(ele.id, value[ele.id][i].originFileObj);
              }
            } else {
              value[ele.id] &&
                formPayload.append(
                  ele.id,
                  value[ele.id][0].originFileObj || value[ele.id]
                );
            }
          }
          if (ele.type === "select") {
            if (Array.isArray(value[ele.id]) && value[ele.id]?.length) {
              value[ele.id] &&
                formPayload.append(ele.id, value[ele.id]?.join(","));
            } else if (!Array.isArray(value[ele.id]) && value[ele.id]) {
              value[ele.id] && formPayload.append(ele.id, value[ele.id]);
            } else {
              value[ele.id] && formPayload.append(ele.id, "[]");
              // formPayload.delete(ele.id);
            }
          }
          if (ele.type === "multifield" || ele.type === "extraMultiSingle") {
            if (ele?.handler) {
              value[ele.id] &&
                formPayload.append(ele.id, ele?.handler(value[ele.id]));
            } else {
              value[ele.id] &&
                formPayload.append(ele.id, JSON.stringify(value[ele.id]));
            }
          }
          if (ele.type === "date") {
            if (dateTime) {
              const dateTimeValue = `${moment(value[ele.id].$d).format(
                "YYYY-MM-DD"
              )} ${moment(value[ele.id].$d, "HH:mm:ss")
                .utc()
                .format("HH:mm:ss")}`;
              // console.log(dateTimeValue);
              value[ele.id] && formPayload.append(ele.id, dateTimeValue);
            } else {
              value[ele.id] &&
                formPayload.append(
                  ele.id,
                  moment(value[ele.id].$d).format("YYYY-MM-DD")
                );
            }
          }
        });
      } else {
        CONSTANTS.FORM_FIELD[modalFields].forEach((ele) => {
          // console.log(ele.id);

          if (ele.type === "option") {
            const OpetionsArr = [];
            for (let i = 1; i <= 10000000; i++) {
              if (ele?.id + i in value) {
                OpetionsArr.push(value[ele?.id + i]);
                delete value[ele?.id + i];
              } else {
                break;
              }
            }

            rawPayload = { ...rawPayload, options: OpetionsArr.toString() };
          }
        });
        rawPayload = { ...value, ...payloadExtra, ...rawPayload };
      }

      //  console.log(formPayload)
      const payload = editformData ? formPayload : rawPayload;
      // if (blockData) {
      //   payload = { ...payload, isBlocked: editRenderData?.isBlocked };
      // }
      const dataId = editRenderData?.id;
      // console.log(payload);
      if (editAPI) {
        const EDIT_API_CALL = apiGenerator(editAPI, {
          dataId,
        });
        // console.log(EDIT_API_CALL);
        api.sendRequest(
          EDIT_API_CALL,
          () => {
            setRefresh((prev) => !prev);
            formReset();
            setEditRenderData(null);
            mixpanel.identify(Admin?.email);
            mixpanel.track(`Edit ${trackEventName}`, {
              Token: Token,
              Admin: { id: Admin?.id, name: Admin?.name },
              ExtraData: { value },
            });
          },
          payload,
          "Edit Data Successfully!!!"
        );
      }
    } else {
      return;
    }
  };

  // Block Data API
  const blockTableData = (dataId = null, checked) => {
    const payload = {
      isBlocked: !checked,
    };

    if (editAPI) {
      const BLOCK_API_CALL = apiGenerator(editAPI, {
        dataId,
      });
      // console.log(BLOCK_API_CALL);
      api.sendRequest(
        BLOCK_API_CALL,
        () => {
          setRefresh((prev) => !prev);
          mixpanel.identify(Admin?.email);
          mixpanel.track(`Block-Unblock ${trackEventName}`, {
            Token: Token,
            Admin: { id: Admin?.id, name: Admin?.name },
            ExtraData: { isBlocked: !checked, Id: dataId },
          });
        },
        payload
      );
    }
  };

  // Accept Request
  const acceptRequest = (dataId) => {
    const payload = {
      approve: true,
    };
    // console.log(payload);
    if (acceptRejectAPI) {
      const ACCEPT_API_CALL = apiGenerator(acceptRejectAPI, {
        dataId,
      });
      api.sendRequest(
        ACCEPT_API_CALL,
        () => {
          setRefresh((prev) => !prev);
          mixpanel.identify(Admin?.email);
          mixpanel.track(`Accept Request ${trackEventName}`, {
            Token: Token,
            Admin: { id: Admin?.id, name: Admin?.name },
            ExtraData: { Id: dataId },
          });
        },
        payload,
        "Request Accepted Successfully!!!"
      );
    }
  };
  // Reject Request
  const rejectRequest = (dataId) => {
    const payload = {
      approve: false,
    };
    // console.log(payload);
    if (acceptRejectAPI) {
      const REJECT_API_CALL = apiGenerator(acceptRejectAPI, {
        dataId,
      });
      api.sendRequest(
        REJECT_API_CALL,
        () => {
          setRefresh((prev) => !prev);
          mixpanel.identify(Admin?.email);
          mixpanel.track(`Rejected Request ${trackEventName}`, {
            Token: Token,
            Admin: { id: Admin?.id, name: Admin?.name },
            ExtraData: { Id: dataId },
          });
        },
        payload,
        "Request Rejected Successfully!!!"
      );
    }
  };
  // Edit Images
  const AddImages = (value, dataId) => {
    const formPayload = new FormData();
    formPayload.append("toolId", dataId);
    if (formData) {
      CONSTANTS.FORM_FIELD[editImagesModalFields].forEach((ele) => {
        // console.log(ele.id);
        if (ele.type === "file" && value[ele.id]) {
          if (Array.isArray(value[ele.id])) {
            for (let i = 0; i < value[ele.id]?.length; i++) {
              formPayload.append(ele.id, value[ele.id][i].originFileObj);
            }
          } else {
            value[ele.id] &&
              formPayload.append(ele.id, value[ele.id][0].originFileObj);
          }
        }
      });
    }
    if (addImagesAPI) {
      const ADD_IMAGE_API_CALL = { ...addImagesAPI };
      api.sendRequest(
        ADD_IMAGE_API_CALL,
        () => {
          setIsImageModalOpen((prev) => !prev);
          setRefresh((prev) => !prev);
          mixpanel.identify(Admin?.email);
          mixpanel.track(`Add Image ${trackEventName}`, {
            Token: Token,
            Admin: { id: Admin?.id, name: Admin?.name },
            ExtraData: { Id: dataId },
          });
        },
        formPayload,
        "Add Data Successfully!!!"
      );
    }
  };
  const EditImages = (value, dataId) => {
    const editAlt = { ...editImagesAPI };
    const apiAltImage = apiGenerator(editAlt, { dataId });
    api.sendRequest(
      apiAltImage,
      (res) => {
        const updatedItems = previewImages?.map((item) => {
          if (item?.id === dataId) {
            return { ...item, alt: value.alt };
          }
          return item;
        });
        setPreviewImages(updatedItems);
        mixpanel.identify(Admin?.email);
        mixpanel.track(`Edit Image ${trackEventName}`, {
          Token: Token,
          Admin: { id: Admin?.id, name: Admin?.name },
          ExtraData: { Id: dataId },
        });
      },
      { alt: value?.alt },
      "Edit alt Successfully!!!"
    );
  };
  //deleteImages
  const deleteImages = (dataId) => {
    const deleteImage = { ...deleteImagesAPI };
    const apiDeleteImage = apiGenerator(deleteImage, { dataId });
    api.sendRequest(apiDeleteImage, (res) => {
      const updatedItems = previewImages.filter((item) => item.id !== dataId);
      setPreviewImages(updatedItems);
      mixpanel.identify(Admin?.email);
      mixpanel.track(`Delete Image ${trackEventName}`, {
        Token: Token,
        Admin: { id: Admin?.id, name: Admin?.name },
        ExtraData: { Id: dataId },
      });
    });
  };
  //get Images
  const getImages = (dataId) => {
    const OneToolImage = { ...getImagesAPI };
    const apiOneTool = apiGenerator(OneToolImage, { dataId });
    api.sendRequest(apiOneTool, (res) => {
      setPreviewImages(res?.data?.rows);
    });
  };
  //faq add
  const AddFAQ = (value, formReset) => {
    // console.log(value, "*-*-*-");
    let isFormValid = true;
    const addFAQ = { ...addFAQAPI };
    const apiFAQ = apiGenerator(addFAQ, { dataId: id });
    if (addFAQAPI) {
      const uniqueFaqs = value?.faqs?.reduce((accumulator, currentFAQ) => {
        const isDuplicate = accumulator?.some(
          (faq) => faq?.question === currentFAQ?.question
        );

        if (isDuplicate) {
          isFormValid = false;
          notification.error({
            message: `Please Enter Unique Question.`,
            duration: 3,
          });
          return accumulator; // Early return if duplicate is found
        }

        // If not a duplicate, add the currentFAQ to the accumulator
        accumulator.push(currentFAQ);
        return accumulator;
      }, []);

      // Update the 'faqs' array with unique FAQs
      value.faqs = uniqueFaqs;
      if (isFormValid) {
        api.sendRequest(
          apiFAQ,
          () => {
            setIsFAQModalOpen((prev) => !prev);
            mixpanel.identify(Admin?.email);
            mixpanel.track(`Add ${trackEventName} FAQ`, {
              Token: Token,
              Admin: { id: Admin?.id, name: Admin?.name },
              ExtraData: { FAQ: value?.faqs },
            });
            // setRefresh((prev) => !prev);
          },
          value,
          "Add Data Successfully!!!"
        );
      }
    }
  };
  //meta data add
  const AddMetadata = (value, formReset) => {
    // console.log(value, "*-*-*-");
    let isFormValid = true;
    const addMeta = { ...addMetadataAPI };
    const apiMeta = apiGenerator(addMeta, { dataId: id });
    if (addMetadataAPI) {
      if (isFormValid) {
        api.sendRequest(
          apiMeta,
          () => {
            setIsMetadataModalOpen((prev) => !prev);
            mixpanel.identify(Admin?.email);
            mixpanel.track(`Add ${trackEventName} Metadata`, {
              Token: Token,
              Admin: { id: Admin?.id, name: Admin?.name },
              ExtraData: {
                MetaTitle: value?.metaTitle,
                MetaDescription: value?.metaDescription,
              },
            });
            // setRefresh((prev) => !prev);
          },
          value,
          "Add Metadata Successfully!!!"
        );
      }
    }
  };
  //Date Filter
  const dateFilterFunction = (e) => {
    console.log(e);
    // console.log(dayjs(e[0]).format("YYYY-MM-DD"));
    // console.log(dayjs(e[1]).format("YYYY-MM-DD"));
    if (e) {
      setDates({
        startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
        endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
  };
  // Add Requried Buttons
  const tableData = (res) => {
    const answer = res?.map((data) => {
      let tableData = {
        ...data,
        viewData,
      };

      // View Button
      if (viewData) {
        tableData = {
          ...tableData,
          view: {
            id: data?.id,
            onClick: viewFunction,
          },
        };
      }

      //  Block Button required
      if (blockData) {
        tableData = {
          ...tableData,
          toggle: {
            id: data?.id,
            checked: data?.isBlocked,
            onClick: blockTableData,
          },
        };
      }
      //  Checkbox Button required
      if (checkboxData) {
        tableData = {
          ...tableData,
          checkbox: {
            id: data?.id,
            checked: !data?.privateAcc,
            onClick: () => {},
          },
        };
      }
      if (selectBoxData) {
        tableData = {
          ...tableData,
          selectBox: {
            id: data?.id,
            selected: data?.status,
            onClick: selectBoxFunction,
          },
        };
      }
      if (editImages) {
        tableData = {
          ...tableData,
          editImages: {
            id: data?.id,
            onClick: () => {
              // alert(data?.id)
              console.log(tableData);
              setIsImageModalOpen((prev) => !prev);
              getImages(data?.id);
              setEditImagesData(tableData);
            },
          },
        };
      }
      if (addFAQ) {
        tableData = {
          ...tableData,
          addFAQ: {
            id: data?.id,
            onClick: () => {
              // alert(data?.id)
              setIsFAQModalOpen((prev) => !prev);
              // getFAQ(data?.id)
              setId(data?.id);
            },
          },
        };
      }
      if (addMetadata) {
        tableData = {
          ...tableData,
          addMetadata: {
            id: data?.id,
            onClick: () => {
              // alert(data?.id)
              setIsMetadataModalOpen((prev) => !prev);
              // getFAQ(data?.id)
              setId(data?.id);
            },
          },
        };
      }
      //  Edit Button required
      if (editData || editformData) {
        tableData = {
          ...tableData,
          edit: {
            id: data?.id,
            onClick: () => {
              // console.log(tableData, "edit data");
              setEditRenderData(tableData);
            },
          },
        };
      }

      //  Delete Button required
      if (deleteData) {
        tableData = {
          ...tableData,
          delete: {
            id: data?.id,
            onClick: deleteTableData,
          },
        };
      }

      //  Accept Reject Button required
      if (acceptRejectData) {
        tableData = {
          ...tableData,
          action: {
            id: data?.id,
            onAccept: acceptRequest,
            onReject: rejectRequest,
          },
        };
      }

      return tableData;
    });

    return answer;
  };
  const handleShedule = () => {
    navigate(schedulePage, extraScheduleState);
  };
  // Render Data API
  useEffect(() => {
    if (getAPI) {
      let API_CALL = { ...getAPI };
      let AllDATA_API_CALL = { ...getAPI };
      API_CALL.endpoint = API_CALL.endpoint + extraEndPointData;
      let params = {};

      // Start and End Date
      if (dates.startDate !== null && dates.endDate !== null) {
          params.startDate = dates.startDate;
          params.endDate = dates.endDate;
      }
      
      // Price Filter
      if (extraFilterData.price !== "") {
          params.price = extraFilterData.price;
      }
      
      // Sorting
      const sort = extraFilterData.sort || Allfilter?.sort?.sort;
      const sortBy = extraFilterData.sortBy || Allfilter?.sort?.sortBy;
      if (sort && sortBy) {
          params.sort = sort;
          params.sortBy = sortBy;
      }
      
      // Category Filter
      if (extraFilterData.category?.length > 0) {
          params[extraFilterParam] = extraFilterData.category.join(",");
      }
      
      // Status Filter
      if (extraFilterData.status?.length > 0) {
          params[extraFilterParam] = extraFilterData.status;
      }
      
      // Search Keyword
      if (searchKeyword.trim() !== "") {
          params.search = searchKeyword;
      }
      
      // console.log(API_CALL);
      // if (searchKeyword === "") {
      API_CALL.endpoint = `${API_CALL.endpoint}${buildQueryString(params)}`;
      setExportAllDataApi(AllDATA_API_CALL);
      // console.log(API_CALL);
      if (API_CALL.endpoint.includes("?")) {
        API_CALL.endpoint = `${API_CALL.endpoint}&page=${pageNumber}&limit=${pageSize}`;
      } else {
        API_CALL.endpoint = `${API_CALL.endpoint}?page=${pageNumber}&limit=${pageSize}`;
      }
      // For Selected Filter
      if (selectedOption !== "" && selectedOption !== undefined) {
        API_CALL.endpoint = `${API_CALL.endpoint}&${selectedOption}`;
      }
      API_CALL.endpoint = `${API_CALL.endpoint}${extraParams}`;
      api.sendRequest(API_CALL, (res) => {
        setTotalRecords(res?.data?.count);
        let API_RESPONSE_DATA = res?.data;
        if (extraResData) {
          API_RESPONSE_DATA = API_RESPONSE_DATA[extraResData];
        }

        const RESPONSE = tableData(getData(API_RESPONSE_DATA));
        // console.log(RESPONSE);
        setRenderData(RESPONSE);
        setReloadChart((prv) => !prv);
      });
      // } else {
      //   api.sendRequest(
      //     { type: "GET", endpoint: `${searchAPI}${searchKeyword}` },
      //     (res) => {
      //       setRenderData(tableData(getData(res?.data[extraResData])));
      //     },
      //     { keyword: searchKeyword }
      //   );
      // }
    }
    setRenderData([]);
  }, [
    refresh,
    reload,
    searchKeyword,
    extraFilterData?.category,
    extraFilterData?.price,
    extraFilterData?.sortBy,
    Allfilter?.sort,
    dates,
    pageNumber,
    pageSize,
    selectedOption,
  ]);

  return (
    <>
      {/* Date Filter */}

      {(datefilter || searchfilter || extraFilter) && (
        <div className="my-5">
          <Card>
            <Row gutter={10}>
              <Col span={16}>
                <Heading>Filter</Heading>
              </Col>
              {datefilter && (
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <RangePicker
                    className="w-full"
                    onChange={dateFilterFunction}
                  />
                </Col>
              )}
              {searchfilter && (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={`${datefilter ? "" : ""}`}
                >
                  <Row>
                    <Col span={24}>
                      <div className="">
                        <CustomSearchBar
                          endpointAPI={searchAPI}
                          setKeyword={setSearchKeyword}
                          isSearch={isSearch}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {extraFilter && (
              <Custom_Filter_Select
                data={extraFilterField}
                getSelectedValues={(val) => {
                  setExtraFilterData({
                    category: val?.category,
                    price: val?.price,
                    sort: val?.sort,
                    sortBy: val?.sortBy,
                    status: val?.status,
                  });
                }}
              />
            )}
          </Card>
        </div>
      )}

      {/* {chartData && chartComponent} */}

      {/* Add Modal */}
      {(addData || formData) && (
        <>
          <Button
            onClick={() => {
              setIsModalOpen((prev) => !prev);
            }}
            className="mt-5"
            type="primary"
          >
            <p className="">{modalButton}</p>
          </Button>

          <ModalFormCreator
            open={isModalOpen}
            onCreate={addTableData}
            onCancel={() => {
              setIsModalOpen((prev) => !prev);
            }}
            name={addModalTitle}
            menu={modalFields}
            loading={api.isLoading}
            modalWidth={modalWidth}
          />
        </>
      )}
      {schedulePage && (
        <Button
          onClick={() => {
            handleShedule();
          }}
          className="mt-5 ms-3"
          type="primary"
        >
          <p className="">{scheduleTitle}</p>
        </Button>
      )}
      <Custom_AllData_CSVExport
        getAPI={exportAllDataApi}
        extraResData={extraResData}
        tableHeaders={tableHeaders}
        getData={getData}
      />

      {/* Edit Modal */}
      {(editData || editformData) && (
        <ModalFormCreator
          open={editRenderData !== null}
          onCreate={editTableData}
          onCancel={() => {
            setEditRenderData(null);
          }}
          name={editModalTitle}
          menu={editModalFields || modalFields}
          formData={{ ...editRenderData }}
          loading={api.isLoading}
          getAPI={getImagesAPI}
          dataId={id}
          modalWidth={modalWidth}
          trackEventName={trackEventName}
        />
      )}
      {editImages && (
        <ModalEditImages
          open={isImageModalOpen}
          onCreate={(value) => {
            AddImages(value, editImagesData?.id);
          }}
          onCancel={() => {
            setEditImagesData(null);
            setIsImageModalOpen((prev) => !prev);
          }}
          onEdit={(value, dataId) => {
            EditImages(value, dataId);
          }}
          onDelete={(id) => {
            deleteImages(id);
          }}
          name={editImageModalTitle}
          menu={editImagesModalFields}
          previewImages={previewImages}
          formData={{ ...editImagesData }}
          loading={api.isLoading}
        />
      )}
      {addFAQ && (
        <ModalFAQ
          open={isFAQModalOpen}
          onCreate={(value) => {
            AddFAQ(value);
          }}
          onCancel={() => {
            setIsFAQModalOpen((prev) => !prev);
          }}
          name={addFAQModalTitle}
          menu={faqModalField}
          formData={{}}
          loading={api.isLoading}
          getAPI={getFAQAPI}
          dataId={id}
        />
      )}
      {addMetadata && (
        <ModalMetaData
          open={isMetadataModalOpen}
          onCreate={(value) => {
            AddMetadata(value);
          }}
          onCancel={() => {
            setIsMetadataModalOpen((prev) => !prev);
          }}
          name={addMetadataModalTitle}
          menu={metadataModalField}
          formData={{}}
          loading={api.isLoading}
          getAPI={getMetadataAPI}
          dataId={id}
        />
      )}
      {/* {generalNotification && (
        <ModalFormCreator
          open={generalNotification}
          onCreate={addTableData}
          onCancel={() => {
            setGeneralNotification((prev) => !prev);
          }}
          name={"Notification"}
          menu={'NOTIFICATION_MODAL'}
          loading={api.isLoading}
        />
      )} */}
      {/* {api.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : ( */}
      <CustomTable
        // dataSource={data.slice(inventoryDisplay, inventoryDisplay + 10)}
        // dataSource={[]}
        // dataSource={DUMMY_DATA}
        filterparmas={filterparmas}
        filterList={filterList}
        defaultFilterOption={defaultFilterOption}
        title={tableTitle}
        dataSource={DUMMY_DATA ? DUMMY_DATA : renderData}
        name={tableHeaders}
        totalRecords={totalRecords}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setChanges={(v) => {
          setAllFilter(v);
          setPageNumber(1);
        }}
        isLoading={api.isLoading}
      />
      {/* )} */}
    </>
  );
};

export default PageComponent;
