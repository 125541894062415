import React from "react";

import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import PageComponent from "../../../../component/common/Page-Component";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ToolNews = ({ dataId }) => {
  let list = { ...CONSTANTS.API.getToolNewsData };
  const apiList = apiGenerator(list, { dataId });
  const navigate=useNavigate();
  const handleView = (id) => {
    navigate(`/app/tool/${id}`);
  };
  return (
    <>
      <div className="mt-5 mx-3">
        <PageComponent
          tableHeaders="TOOLNEWS"
          tableTitle="Tools"
          getAPI={apiList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data.tool,
                no: `T${data?.id.toString().padStart(7, "0")}`,  
                view:{
                  id:data?.toolId,
                  onClick:(id)=>{handleView(id)}
                },
                date:moment(data?.createdAt).format('lll'),            
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/blog?search="
          // viewData
          // viewFunction={handleViewBlog}
        />
      </div>
    </>
  );
};

export default ToolNews;
