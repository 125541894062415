import { Card, Col, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import dayjs from "dayjs";
import useHttp from "../../../hooks/use-http";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
const UserListChart = () => {
  const { RangePicker } = DatePicker;
  const { id } = useParams();
  const api = useHttp();
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const options = {
    labels: [
      "Tool Views",
      "Tool Likes",
      "Tool Wishlists",
      "Ratings",
      "Blog Views",
      "Blog Likes",
      "Blog Wishlists",
      "Blog Comments",
      "Blog Comment Replies",
      "News Wishlist",
      "News Views",
      "Listicle Views",
      "Listicle Likes",
      "Listicle Comments",
      "Listicle Comment Replies",
    ],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "11px",
      },
    },
    colors: [
      "#67729D",
      "#F39F5A",
      "#008FFB",
      "#662549",
      "#FFC400",
      "#2B3499",
      "#9D76C1",
      "#005B41",
      "#FFA1F5",
      '#711DB0',
      '#1B4242',
      '#662549',
      '#F39F5A',
      '#85586F',
      '#776B5D'
    ],
  };

  const [chartData, setChartData] = useState([]);
  const dateFilterFunction = (e) => {
    // console.log(e);
    // console.log(dayjs(e[0]).format("YYYY-MM-DD"));
    // console.log(dayjs(e[1]).format("YYYY-MM-DD"));
    if (e) {
      setDates({
        startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
        endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
  };
  useEffect(() => {
    let datefilter = "";
    if (dates.startDate !== null && dates.endDate !== null) {
      datefilter = `?startDate=${dates.startDate}&endDate=${dates.endDate}`;
    }

    const list = { ...CONSTANTS.API.getAllDashbordListByUser };
    list.endpoint = list.endpoint + datefilter;
    const apiList = apiGenerator(list, { dataId: id });
    api.sendRequest(apiList, (res) => {
      const data = res?.data;
      const series = [
        data?.toolViews,
        data?.toolLikes,
        data?.toolWishlists,
        data?.ratings,
        data?.blogViews,
        data?.blogLikes,
        data?.blogWishlists,
        data?.comments,
        data?.replies, 
        data?.newsWishlists,
        data?.newsViews, 
        data?.listingViews,
        data?.listingLikes,
        data?.listingComments,
        data?.listingCommentReplies
      ];
      console.log(series)
      setChartData(series);
    });
  }, [dates]);
  return (
    <>
     <Col span={7} xs={24} sm={24} md={24} lg={10} xl={10} xxl={6}>
      {/* <Col span={6} xs={24} sm={12} md={12} lg={8} xl={6}> */}
        <Card size="small"  className="h-60">
          <RangePicker className="w-full" onChange={dateFilterFunction} />
          <ReactApexChart
            options={options}
            series={chartData}
            type="pie"
            // width={400}
            height={180}
          />
        </Card>
      </Col>
    </>
  );
};

export default UserListChart;
