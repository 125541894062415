import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import Registration from "../page/user/Registration";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { appRoot } from "./constant/CONSTANTS";
import ForgetPassword from "../page/user/Registration/forgetPass";
import Users from "../page/app/users";
import UserDetail from "../page/app/users/user-detail";
import Category from "../page/app/sub_category";
import Tool from "../page/app/tool";
import Blog from "../page/app/blog";
import BlogView from "../page/app/blog/blogView";
import ToolView from "../page/app/tool/tabs/Overview/toolView";
import Notification from "../page/app/notification";
import ToolDetail from "../page/app/tool/tabs/tool-detail";
import ScheduleTool from "../page/app/tool/scheduleTool";
import ScheduleBlog from "../page/app/blog/scheduleBlog";
import NewsCategory from "../page/app/news-category";
import News from "../page/app/news";
import NewsDetail from "../page/app/news/tabs/news-detail";
import ScheduleNews from "../page/app/news/scheduleNews";
import Main_Category from "../page/app/main_category";
import BlogCategory from "../page/app/blog_category";
import SingleMainCategory from "../page/app/main_category/SingleMainCategory";
import SingleNewsCat_NewsView from "../page/app/news-category/SingleNewsCat_NewsView";
import SingleNewsCat_NewsSchedualed from "../page/app/news-category/SingleNewsCat_NewsSchedualed";
import SubmitTool from "../page/app/submit-tool";
import SingleSubmitTool from "../page/app/submit-tool/SingleSubmitTool";
import ListiclesCategory from "../page/app/listicles_category";
import Listicles from "../page/app/listicles";
import ListiclesView from "../page/app/listicles/ListiclesView";
import Home from "../page/app/home";
import Pitch from "../page/app/pitch";
import ScheduleNotification from "../page/app/notification/scheduleNotification";
import ReDirectUrl from "../page/app/redirect-url";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  USERS_DETAIL: "/users/:id",
  CATEGORY: "/category/sub-category",
  MAINCATEGORY: "/category/main-category",
  TOOL:"/tool",
  TAG:"/tag",
  BLOG:"/blog",
  BLOGVIEW:"/blog/:id",
  TOOLVIEW:"/tool/:id",
  LISTICLESVIEW:"/listicles/:id",
  NOTIFICATION:"/notification",
  TOOLSHEDULE: "/schedule/tool",
  BLOGSHEDULE: "/schedule/blog",
  NEWSCATEGORY: "/category/news-category",
  LISTICLESCATEGORY: "/category/listicles-category",
  NEWS: "/news",
  NEWSSHEDULE: "/schedule/news",
  NEWSVIEW:"/news/:id",
  BLOGCATEGORY: "/category/blog-category",
  MAINCATEGORYVIEW: "/category/main-category/:id",
  NEWSCATEGORYVIEW: "/category/news-category/news/:id",
  NEWSSHEDULECATEGORY: "/schedule/news-category/news/:id",
  NOTIFICATIONSHEDULE: "/schedule/notification",
  SUBMIT_TOOL: "/submit-tool",
  SUBMIT_TOOL_VIEW: "/submit-tool/:id",
  LISTICLES:"/listicles",
  HOME:"/home",
  PITCH:"/pitch",
  REDIRECT_URL:"/redirect-url",
};

const LOGIN_ROUTES = [
  // {
  //   path: "/",
  //   element: <SidePage />,
  //   errorElement: <Error />,
  //   children: [
  //     {
  //       index: true,
  //       element: <LogIn />,
  //     },
  //     {
  //       path: "registration",
  //       element: <Registration />,
  //     },
  //   ],
  // },
  {
    index: true,
    element: <LogIn />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <Error />,
  },
];

const ALL_ROUTES = [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={`${appRoot}${ROUTES.DASHBOARD}`} />,
      },
      {
        path: `${appRoot}${ROUTES.DASHBOARD}`,
        element: <Dashboard />,
      },
      {
        path: `${appRoot}${ROUTES.USERS}`,
        element: <Users />,
      },
      {
        path: `${appRoot}${ROUTES.CATEGORY}`,
        element: <Category />,
      },
      {
        path: `${appRoot}${ROUTES.TOOL}`,
        element: <Tool />,
      },
      {
        path: `${appRoot}${ROUTES.BLOG}`,
        element: <Blog />,
      },
      {
        path: `${appRoot}${ROUTES.BLOGVIEW}`,
        element: <BlogView />,
      },
      {
        path: `${appRoot}${ROUTES.TOOLVIEW}`,
        element: <ToolDetail />,
      },
      {
        path: `${appRoot}${ROUTES.USERS_DETAIL}`,
        element: <UserDetail />,
      },
      {
        path: `${appRoot}${ROUTES.NOTIFICATION}`,
        element: <Notification />,
      },
      {
        path: `${appRoot}${ROUTES.TOOLSHEDULE}`,
        element: <ScheduleTool />,
      },
      {
        path: `${appRoot}${ROUTES.BLOGSHEDULE}`,
        element: <ScheduleBlog />,
      },
      {
        path: `${appRoot}${ROUTES.NEWSCATEGORY}`,
        element: <NewsCategory/>,
      },
      {
        path: `${appRoot}${ROUTES.NEWS}`,
        element: <News/>,
      },
      {
        path: `${appRoot}${ROUTES.NEWSVIEW}`,
        element: <NewsDetail/>,
      },
      {
        path: `${appRoot}${ROUTES.NEWSSHEDULE}`,
        element: <ScheduleNews />,
      },
      {
        path: `${appRoot}${ROUTES.MAINCATEGORY}`,
        element: <Main_Category />,
      },
      {
        path: `${appRoot}${ROUTES.BLOGCATEGORY}`,
        element: <BlogCategory/>,
      },
      {
        path: `${appRoot}${ROUTES.MAINCATEGORYVIEW}`,
        element: <SingleMainCategory/>,
      },
      {
        path: `${appRoot}${ROUTES.NEWSCATEGORYVIEW}`,
        element: <SingleNewsCat_NewsView/>,
      },
      {
        path: `${appRoot}${ROUTES.NEWSSHEDULECATEGORY}`,
        element: <SingleNewsCat_NewsSchedualed/>,
      },
      {
        path: `${appRoot}${ROUTES.SUBMIT_TOOL}`,
        element: <SubmitTool/>,
      },
      {
        path: `${appRoot}${ROUTES.SUBMIT_TOOL_VIEW}`,
        element: <SingleSubmitTool/>,
      },
      {
        path: `${appRoot}${ROUTES.LISTICLESCATEGORY}`,
        element: <ListiclesCategory/>,
      },
      {
        path: `${appRoot}${ROUTES.LISTICLES}`,
        element: <Listicles/>,
      },
      {
        path: `${appRoot}${ROUTES.LISTICLESVIEW}`,
        element: <ListiclesView/>,
      },
      {
        path: `${appRoot}${ROUTES.HOME}`,
        element: <Home/>,
      },
      {
        path: `${appRoot}${ROUTES.PITCH}`,
        element: <Pitch/>,
      },
      {
        path: `${appRoot}${ROUTES.NOTIFICATIONSHEDULE}`,
        element: <ScheduleNotification/>,
      },
      {
        path: `${appRoot}${ROUTES.REDIRECT_URL}`,
        element: <ReDirectUrl/>,
      },
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  // {
  //   path: "*",
  //   element: <Navigate to="/error" />,
  // },
];

export default ALL_ROUTES;
