import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Listicles = () => {
  const api = useHttp();
  const navigate = useNavigate();
  useEffect(() => {
    const apiCategory = { ...CONSTANTS.API.getListiclesCategory };
    api.sendRequest(apiCategory, (res) => {
      const category = res?.data?.rows?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.LISTICLES_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FORM_FIELD.EDIT_LISTICLES_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FILTER.LISTICLES.find((el) => el?.id === "categories").option =
        category;
    });
    const apiTool= {...CONSTANTS.API.getToolNews};
    api.sendRequest(apiTool,(res)=>{
        const tool=res?.data?.map((i)=>{
           return {
            ...i,
            Label:i?.title,
            value:i?.id,
            id:i?.id
           }
        })
        CONSTANTS.FORM_FIELD.LISTICLES_TOOL.find(
          (el) => el?.id === "toolId"
        ).option = tool;
   
       
     })
  }, []);

  const handleViewBlog = (id) => {
    navigate(`/app/listicles/${id}`);
  };

  return (
    <>
      <PageComponent
        trackEventName="Listicles"
        tableHeaders="LISTICLES"
        tableTitle="Listicles List"
        getAPI={CONSTANTS.API.getListicles}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `L${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("lll"),
              categories: data?.listingCategories?.map(
                (ele) => ele?.categoryOfListingId
              ),
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton="Add New Listicles"
        addModalTitle="Add New Listicles"
        editModalTitle="Edit Listicles"
        modalFields="LISTICLES_MODAL"
        editModalFields="EDIT_LISTICLES_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addListicles}
        editAPI={CONSTANTS.API.editListicles}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteListicles}
        searchfilter
        isSearch={true}
        searchAPI="/admin/listicles?search="
        viewData
        viewFunction={handleViewBlog}
        // addFAQ
        // addFAQModalTitle="Add Listicle Meta"
        // faqModalField="METADATA_MODAL"
        // getFAQAPI={CONSTANTS.API.getOneListFAQ}
        // addFAQAPI={CONSTANTS.API.addListFAQ}
        // scheduleTitle="Scheduled Blog"
        // schedulePage="/app/schedule/blog"
        addMetadata
        addMetadataModalTitle="Add Listicle Meta"
        metadataModalField="METADATA_MODAL"
        getMetadataAPI={CONSTANTS.API.getOneListMeta}
        addMetadataAPI={CONSTANTS.API.addListMeta}
        dateTime
        extraFilter
        extraFilterField={CONSTANTS.FILTER.LISTICLES}
        extraFilterParam="categoryIds"
        modalWidth={1100}
      />
    </>
  );
};

export default Listicles;
