import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import ModalUserList from "../../../component/common/ModalUserList";
import {
  apiGenerator,
  capitalizeFirstLetter,
  changePremiumtoPaid,
} from "../../../util/functions";
import ModalRating from "./ModalRating";
import moment from "moment";
import Editor from "../../../component/common/Text-Editor-Simple";
import ModalFormCreator from "../../../component/common/ModalFormCreator";

const Tool = () => {
  const api = useHttp();
  const navigate = useNavigate();
  const [openAlt, setOpenAlt] = useState(false);
  const [editRenderData, setEditRenderData] = useState(null);
  const [id, setId] = useState(null);
  // const [openLikes, setOpenLikes] = useState(false);
  // const [openRatings, setOpenRatings] = useState(false);
  // const [openUserView, setOpenUserView] = useState(false);
  // const [toolId, setToolId] = useState("");
  // const [data, setData] = useState([]);
  useEffect(() => {
    const apiCategory = { ...CONSTANTS.API.getCategory };
    api.sendRequest(apiCategory, (res) => {
      const category = res?.data?.rows?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.TOOL_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FORM_FIELD.EDIT_TOOL_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FILTER.TOOL.find((el) => el?.id === "categories").option =
        category;
    });
  }, []);

  const getAltTool=(dataId)=>{
    const list = { ...CONSTANTS.API.getToolAlt };
    const apiList = apiGenerator(list, { dataId });
    api.sendRequest(
      apiList,
      (res) => {
        setEditRenderData(res?.data)
      },
    );
  }

  const handleViewTool = (id) => {
    navigate(`/app/tool/${id}`);
  };
  const addTableData = (values, formReset) => {
    console.log(values);
    const list = { ...CONSTANTS.API.addToolAlt };
    const apiList = apiGenerator(list, { dataId :id});
    api.sendRequest(
      apiList,
      () => {
        setOpenAlt((prev) => !prev);
        formReset();
        // mixpanel.identify(Admin?.email);
        // mixpanel.track(`Add ${trackEventName}`, {
        //   Token: Token,
        //   Admin: {id:Admin?.id,name:Admin?.name},
        //   ExtraData:{value}
        // });
      },
      values,
      "Add Data Successfully!!!"
    );
  };

  return (
    <>
      <PageComponent
        trackEventName="Tool"
        tableHeaders="TOOL"
        tableTitle="Tool List"
        getAPI={CONSTANTS.API.getTool}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `T${data?.id.toString().padStart(7, "0")}`,
              categories: data?.toolCategories?.map((ele) => ele?.categoryId),
              // tags:data?.toolTags?.map(ele => ele?.tagId),
              // price:capitalizeFirstLetter(changePremiumtoPaid(data?.price)),
              date: moment(data?.createdAt).format("lll"),
              // wishlists:{
              //   id:data?.id,
              //   count:data?.wishlists,
              //   onClick: (id) => {
              //     CallApi(id, "wishlist");
              //     setOpenWishlist((prev) => !prev);
              //   },
              // },
              // likes: {
              //   id: data?.id,
              //   count: data?.likes,
              //   onClick: (id) => {
              //     CallApi(id, "likes");
              //     setOpenLikes((prev) => !prev);
              //   },
              // },
              // ratingsAverage: {
              //   id: data?.id,
              //   count: data?.ratingsAverage,
              //   onClick: (id) => {
              //     setToolId(id)
              //     setOpenRatings((prev) => !prev);
              //   },
              // },
              addAlt: {
                id: data?.id,
                // count: data?.views,
                onClick: (id) => {
                  // CallApi(id, "views");
                  setId(data?.id)
                  getAltTool(data?.id)
                  setOpenAlt((prev) => !prev);
                },
              },
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton="Add New Tool"
        addModalTitle="Add New Tool"
        editModalTitle="Edit Tool"
        modalFields="TOOL_MODAL"
        editModalFields="EDIT_TOOL_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addTool}
        editAPI={CONSTANTS.API.editTool}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteTool}
        searchfilter
        isSearch={true}
        searchAPI="/admin/tool?search="
        viewData
        viewFunction={handleViewTool}
        getImagesAPI={CONSTANTS.API.getToolImages}
        deleteImagesAPI={CONSTANTS.API.deleteToolImages}
        addImagesAPI={CONSTANTS.API.addToolImages}
        editImagesAPI={CONSTANTS.API.editToolImages}
        editImages
        editImagesModalFields="EDIT_TOOL_IMAGES_MODAL"
        editImageModalTitle="Edit Tool Images"
        addFAQ
        addFAQModalTitle="Add Tool FAQ"
        faqModalField="FAQ_MODAL"
        getFAQAPI={CONSTANTS.API.getOneToolFAQ}
        addFAQAPI={CONSTANTS.API.addToolFAQ}
        schedulePage="/app/schedule/tool"
        scheduleTitle="Scheduled Tool"
        dateTime
        extraFilter
        extraFilterField={CONSTANTS.FILTER.TOOL}
        extraFilterParam="categoryIds"
        modalWidth={1100}
      />
      {openAlt ? (
        <ModalFormCreator
          open={openAlt}
          onCreate={addTableData}
          onCancel={() => {
            setOpenAlt((prev) => !prev);
          }}
          formData={{ ...editRenderData }}
          name={"Add Alternative Tool"}
          menu={"ALT_TOOL_MODAL"}
          loading={api.isLoading}
          modalWidth={1200}
        />
      ) : null}
    </>
  );
};

export default Tool;
