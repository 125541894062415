import axios from "axios";
import { getCookie } from "../Cookies";

const Services = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${ getCookie('SAID') || ''}`,
    'x-api-key':process.env.REACT_APP_API_KEY,
    'X-Frontend-Request': 'true'
  },
});
export default Services;
