import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Popconfirm } from "antd";
import FormFields from "./FormFields";
import useHttp from "../../hooks/use-http";
import CONSTANTS, { PATHNAME } from "../../util/constant/CONSTANTS";
import {
  apiGenerator,
  removeEmptyArrayValues,
  removeEmptyValues,
} from "../../util/functions";
import { useLocation } from "react-router-dom";
import * as cheerio from "cheerio";
const ModalFormCreator = ({
  open,
  onCreate,
  onCancel,
  name = "",
  formData = {},
  menu,
  disabled = false,
  edit,
  SubmitName = "Submit",
  onEdit = () => {},
  Delete,
  onDelete = () => {},
  formFields = [],
  loading = false,
  modalWidth = 700,
  trackEventName='',
  children,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { pathname } = useLocation();
  const footer = {
    footer: [
      edit && (
        <Button
          key="edit"
          style={{
            borderRadius: "4px",
            height: "40px",
            width: "200px",
          }}
          ghost
          type="primary"
          onClick={onEdit}
          loading={loading}
        >
          Edit
        </Button>
      ),
      Delete && (
        <Popconfirm
          onConfirm={onDelete}
          key="deleteConfirm"
          title={`Delete ${name.toLowerCase()}`}
          description={`Are you sure to delete ${name.toLowerCase()}`}
        >
          <Button
            key="delete"
            style={{
              borderRadius: "4px",
              height: "40px",
              width: "200px",
            }}
            ghost
            type="primary"
            danger
            loading={loading}
            // onClick={onDelete}
          >
            Delete
          </Button>
        </Popconfirm>
      ),
      <Button
        key="submit"
        style={{
          // background: "#2E5BFF",
          borderRadius: "4px",
          height: "40px",
          width: "200px",
          // color: "black",
        }}
        loading={loading}
        type="primary"
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              // const removeEmpty=removeEmptyArrayValues(values)
              onCreate(values, () => {
                form.resetFields();
              });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {SubmitName}
      </Button>,
    ],
  };
  const [editRenderData, setEditRenderData] = useState(null);
  const api = useHttp();
  useEffect(() => {
    if (name === "Edit Blog" && open) {
      const OneBlog = { ...CONSTANTS.API.getOneBlog };
      const id = formData?.id;
      const apiOneBlog = apiGenerator(OneBlog, { id });
      api.sendRequest(apiOneBlog, (res) => {
        // const $ = cheerio.load(res?.data?.overview,null,false);
        // //array for table of content
        // $("h3").replaceWith((_, e) => `<h4>${$(e).html()}</h4>`);
        // $("h2").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // $("h1").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // const modifiedHtmlString = $.html();
        setEditRenderData({
          ...formData,
          description: res?.data?.description,
          overview: res?.data?.overview,
          categories: res?.data?.blogCategories?.map((ele) => ele?.categoryOfBlogId),
        });
        // console.log(res?.data,"*-*-*-")
      });
    }
    if (name === "Edit Tool" && open) {
      const OneTool = { ...CONSTANTS.API.getOneTool };
      const id = formData?.id;
      const apiOneTool = apiGenerator(OneTool, { id });
      api.sendRequest(apiOneTool, (res) => {
        // const $ = cheerio.load(res?.data?.overview,null,false);
        // //array for table of content
        // $("h3").replaceWith((_, e) => `<h4>${$(e).html()}</h4>`);
        // $("h2").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // $("h1").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // const modifiedHtmlString = $.html();
        setEditRenderData({
          ...formData,
          description: res?.data?.description,
          overview: res?.data?.overview,
          link: res?.data?.link,
          categories: res?.data?.toolCategories?.map((ele) => ele?.categoryId),
          videos:res?.data?.videos
        });
        // console.log(res?.data,"*-*-*-")
      });
    }
    if (name === "Edit Listicles" && open) {
      const oneList = { ...CONSTANTS.API.getOneListicles };
      const id = formData?.id;
      const apiOneList = apiGenerator(oneList, { id });
      api.sendRequest(apiOneList, (res) => {
        // const $ = cheerio.load(res?.data?.overview,null,false);
        // //array for table of content
        // $("h3").replaceWith((_, e) => `<h4>${$(e).html()}</h4>`);
        // $("h2").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // $("h1").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // const modifiedHtmlString = $.html();
        setEditRenderData({
          ...formData,
          tools:res?.data?.listingTools?.sort((a, b) => a.index - b.index).map((i, indx) => {
            return { toolId: i?.toolId, description: i?.description ,index:i?.index};
          }),
          categories: res?.data?.listingCategories?.map((ele) => ele?.categoryOfListingId),
          overview: res?.data?.overview
        });
        // console.log(res?.data,"*-*-*-")
      });
    }
    if (name === "Edit Main Category" && open) {
      const One = { ...CONSTANTS.API.getOneMainCategory };
      const id = formData?.id;
      const apiOne = apiGenerator(One, { id });
      api.sendRequest(apiOne, (res) => {
        // const $ = cheerio.load(res?.data?.overview,null,false);
        // //array for table of content
        // $("h3").replaceWith((_, e) => `<h4>${$(e).html()}</h4>`);
        // $("h2").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // $("h1").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // const modifiedHtmlString = $.html();
        setEditRenderData({
          ...formData,
          description: res?.data?.description,
          overview: res?.data?.overview,
          bottomOverview: res?.data?.bottomOverview,
          faqs:res?.data?.faqs
        });
        // console.log(res?.data,"*-*-*-")
      });
    }
    if ((name === "Edit Sub Category" || trackEventName=== 'Sub Category') && open) {
      const One = { ...CONSTANTS.API.getOneCategory };
      const id = formData?.id;
      const apiOne = apiGenerator(One, { id });
      api.sendRequest(apiOne, (res) => {
        // const $ = cheerio.load(res?.data?.overview,null,false);
        // //array for table of content
        // $("h3").replaceWith((_, e) => `<h4>${$(e).html()}</h4>`);
        // $("h2").replaceWith((_, e) => `<h3>${$(e).html()}</h3>`);
        // $("h1").replaceWith((_, e) => `<h2>${$(e).html()}</h2>`);
        // const modifiedHtmlString = $.html();
        setEditRenderData({
          ...formData,
          description: res?.data?.description,
          overview: res?.data?.overview,
          bottomOverview: res?.data?.bottomOverview,
        });
        // console.log(res?.data,"*-*-*-")
      });
    }
    if (name === "Edit Redirect URL" && open) {
      const One = { ...CONSTANTS.API.getOneRedirectUrl};
      const id = formData?.id;
      const apiOne = apiGenerator(One, { id });
      api.sendRequest(apiOne, (res) => {
        setEditRenderData({
          ...formData,
          old: `https://toolplate.ai${res?.data?.old}`,
          new: `https://toolplate.ai${res?.data?.new}`,
        });
        // console.log(res?.data,"*-*-*-")
      });
    }
  }, [
    (trackEventName === 'Sub Category' || name == "Edit Blog" || name == "Edit Tool" || name == "Edit Listicles" || name == "Edit Main Category" || name == "Edit Sub Category" || name === "Edit Redirect URL") &&
      open &&
      formData?.id,
  ]);

  // useEffect(() => {
  // if (open) {
  //   const handleKeyPress = (e) => {
  //     if (e?.key === "Enter" && !e?.shiftKey) {
  //       form
  //         .validateFields()
  //         .then((values) => {
  //           onCreate(values, () => {
  //             form?.resetFields();
  //           });
  //         })
  //         .catch((info) => {
  //           console.log("Validate Failed:", info);
  //         });
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }
  // }, [open]);
  return (
    <Modal
      open={open}
      title={<p className="text-2xl mb-10 text-slate-500">{name}</p>}
      okText="Submit"
      width={modalWidth ? modalWidth : 700}
      {...footer}
      cancelButtonProps={{ style: { display: "none" } }}
      className="form-modal-title"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            console.log(values);
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <FormFields
        formData={editRenderData || formData}
        menu={menu}
        formFields={formFields}
        form={form}
        disabled={disabled}
        formRef={formRef}
      />
      {children}
    </Modal>
  );
};

export default ModalFormCreator;
ModalFormCreator.defaultProps = {
  open: false,
  onCreate: () => {},
  onCancel: () => {},
  name: "",
  formData: {},
  menu: "",
  edit: false,
  onEdit: () => {},
  formFields: [],
};
