import React, { useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NewsCategory = () => {
  const navigate = useNavigate();

  const handleView = (id,name) => {
    navigate(`/app/category/news-category/news/${id}`, { state: { categoryId: id ,categoryName:name} });
  };
  return (
    <>
      <PageComponent
      trackEventName="News Category"
        tableHeaders="NEWSCATEGORY"
        tableTitle="News Category List"
        getAPI={CONSTANTS.API.getNewsCategory}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `NC${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("lll"),
              imageSize: { width: 20, height: 20 },
              view: {
                id: data?.id,
                name:data?.name,
                onClick: (id,name) => {
                  handleView(id,name);
                },
              },
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton="Add New News Category"
        addModalTitle="Add New News Category"
        editModalTitle="Edit News Category"
        modalFields="NEWS_CATEGORY_MODAL"
        editModalFields="EDIT_NEWS_CATEGORY_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addNewsCategory}
        editformData
        editAPI={CONSTANTS.API.editNewsCategory}
        deleteData
        deleteAPI={CONSTANTS.API.deleteNewsCategory}
        searchfilter
        isSearch={true}
        searchAPI="/admin/newsCategory?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.NEWSCATEGORY}
        extraFilterParam=""
      />
    </>
  );
};

export default NewsCategory;
