import React from 'react';
import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs';

const ReadOnlyRating = ({ rating }) => {
  const starCount = 5;
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  return (
    <div className="rating flex gap-1">
      {[...Array(starCount)].map((_, idx) =>  hasHalfStar && idx === fullStars ? <BsStarHalf key={idx}/> :  idx < fullStars ? <BsStarFill key={idx}/> :<BsStar key={idx}/>)}
    </div>
  );
};

export default ReadOnlyRating;