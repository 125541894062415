import React, { memo } from "react";
import "react-awesome-slider/dist/styles.css";
import AwesomeSlider from "react-awesome-slider";

const CustomTextSlider = ({ data }) => {
  return (
    <>
      <style jsx global>{`
       .awssld  {
          --slider-height-percentage: 12%;
          --slider-width-percentage: 75%;
          --slider-transition-duration: 500ms;
          --organic-arrow-thickness: 2px;
          --organic-arrow-border-radius: 50%;
          --organic-arrow-height: 10px;
          --organic-arrow-color: gray;
          --control-button-width: 8%;
          --control-button-height: 30%;
          --control-button-background: transparent !important;
          --control-bullet-color: blue;
          --control-bullet-active-color: green;
          --loader-bar-color: transparent !important;
          --loader-bar-height: 4px;
        }
      `}</style>
      <AwesomeSlider bullets={false} name="custom-awesome-slider">
        {data}
      </AwesomeSlider>
    </>
  );
};

export default memo(CustomTextSlider);