import React from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import moment from 'moment'

const ScheduleNotification = () => {
  return (
    <>
        <PageComponent
        trackEventName='Notification'
        tableHeaders="NOTIFICATION_SCHEDULED"
        tableTitle="Scheduled Notification List"
        getAPI={CONSTANTS.API.getScheduledNotification}
        getData={(res)=>{
            return res?.map((data,idx)=>{
                return {
                    ...data,
                    no: `SN${data?.id.toString().padStart(7, "0")}`,
                    date:moment(data?.createdAt).format('lll'),
                    sender:data?.Admin?.name,
                    schedule:moment(data?.schedule).format('lll'),    
                }
            })
        }}
        extraResData="rows"
        addData
        // formData
        modalButton="Add Notification"
        addModalTitle="Add New Notification"
        editModalTitle="Edit Notification"
        modalFields="NOTIFICATION_MODAL"
        // editModalFields="EDIT_BLOG_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addNotifiactions}
        editAPI={CONSTANTS.API.editNotifiactions}
        // editformData
        editData
        deleteData
        deleteAPI={CONSTANTS.API.deleteNotifiactions}
        searchfilter
        isSearch={true}
        searchAPI="/admin/notification/scheduled?search="
        // scheduleTitle="Scheduled Notification"
        // schedulePage="/app/schedule/notification"
        modalWidth={850}
        // viewData
        // viewFunction={handleViewBlog}
      />
    </>
  )
}

export default ScheduleNotification
