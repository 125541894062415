import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { genAlertStyle } from "antd/es/alert/style";
import { Col, Image, Modal, Row } from "antd";
import ModalUserList from "../../../component/common/ModalUserList";
import ModalComments from "./ModalComments";
import { apiGenerator } from "../../../util/functions";
import moment from "moment";

const ScheduleBlog = () => {
  const api = useHttp();
  const navigate = useNavigate();
  // const [openWishlist, setOpenWishlist] = useState(false);
  // const [openLikes, setOpenLikes] = useState(false);
  // const [openComments, setOpenComments] = useState(false);
  // const [openUserView, setOpenUserView] = useState(false);
  // const [blogId, setBlogId] = useState("");
  // const [data, setData] = useState([]);
  useEffect(() => {
    const apiCategory = { ...CONSTANTS.API.getBlogCategory };
    api.sendRequest(apiCategory, (res) => {
      const category = res?.data?.rows?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.BLOG_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FORM_FIELD.EDIT_BLOG_SCHEDULED_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
    });
    const apiAdmin = { ...CONSTANTS.API.getAdmin };
    api.sendRequest(apiAdmin, (res) => {
      const admin = res?.data?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.BLOG_MODAL.find(
        (el) => el?.id === "AdminId"
      ).option = admin;
      CONSTANTS.FORM_FIELD.EDIT_BLOG_SCHEDULED_MODAL.find(
        (el) => el?.id === "AdminId"
      ).option = admin;
    });
    // const apiTag = { ...CONSTANTS.API.getTag };
    // api.sendRequest(apiTag, (res) => {
    //   const tag = res?.data?.rows?.map((i) => {
    //     return {
    //       ...i,
    //       Label: i?.name,
    //       value: i?.id,
    //       id: i?.id,
    //     };
    //   });
    //   CONSTANTS.FORM_FIELD.BLOG_MODAL.find((el) => el?.id === "tags").option =
    //     tag;

    //   CONSTANTS.FORM_FIELD.EDIT_BLOG_MODAL.find(
    //     (el) => el?.id === "tags"
    //   ).option = tag;
    // });
  }, []);

  const handleViewBlog = (id) => {
    navigate(`/app/blog/${id}`);
  };
  // const CallApi = (dataId, type) => {
  //   if (dataId && type == "views") {
  //     const list = { ...CONSTANTS.API.getBlogViewsList };
  //     const apiList = apiGenerator(list, { dataId });
  //     api.sendRequest(apiList, (res) => {
  //       setData(res?.data?.rows);
  //     });
  //   } 
  //   else if (dataId && type == "wishlist") {
  //     const list = { ...CONSTANTS.API.getBlogWishlistList };
  //     const apiList = apiGenerator(list, { dataId });
  //     api.sendRequest(apiList, (res) => {
  //       setData(res?.data?.rows);
  //     });
  //   } 
  //   else if (dataId && type == "likes") {
  //     const list = { ...CONSTANTS.API.getBlogLikesList };
  //     const apiList = apiGenerator(list, { dataId });
  //     api.sendRequest(apiList, (res) => {
  //       setData(res?.data?.rows);
  //     });
  //   } 
  //   else return;
  // };
  return (
    <>
      <PageComponent
        tableHeaders="SCHEDULEBLOG"
        tableTitle="Scheduled Blog List"
        getAPI={CONSTANTS.API.getScheduledBlog}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              date:moment(data?.createdAt).format('lll'),          
              admin:data?.Admin?.name ? data?.Admin?.name:'-',  
              // categories: data?.blogCategories?.map((ele) => ele?.categoryId),
            //   tags: data?.blogTags?.map((ele) => ele?.tagId),
              readTime: `${data?.readTime} Minutes`,    
              release:moment(data?.release).format('lll'),      
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton="Add New Blog"
        addModalTitle="Add New Blog"
        editModalTitle="Edit Blog"
        modalFields="BLOG_MODAL"
        editModalFields="EDIT_BLOG_SCHEDULED_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addBlog}
        editAPI={CONSTANTS.API.editBlog}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteBlog}
        searchfilter
        isSearch={true}
        searchAPI="/admin/blog?search="
        viewData
        viewFunction={handleViewBlog}
        addFAQ
        addFAQModalTitle="Add Blog FAQ"
        faqModalField='FAQ_MODAL'
        getFAQAPI={CONSTANTS.API.getOneBlogFAQ}
        addFAQAPI={CONSTANTS.API.addBlogFAQ}        
        dateTime
        modalWidth={1100}
      />
 
    </>
  );
};

export default ScheduleBlog;
