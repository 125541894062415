import Eye from "../../asset/image/eye.png";
import { Button, Image, Input, Popconfirm, Select, Switch, Tag } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  FileImageOutlined,
} from "@ant-design/icons";
import { FaQuestion, FaQuora } from "react-icons/fa6";
import logo from "../../asset/logos/icon.png";
import { Magicpen } from "iconsax-react";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

const RenderActionsButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
const RenderDeleteButton = (value) => {
  const { id, onClick } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? (
          value?.name
        ) : (
          <p className="">
            <DeleteOutlined />
          </p>
        )}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
const RenderEditButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <p className=" ">
        <EditOutlined />
      </p>
    </Button>
  );
};
const RenderAltButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <p className=" mt-0.5 ">
        <Magicpen size={20} />
      </p>
    </Button>
  );
};
const RenderPitchEditButton = (value) => {
  const { id, onClick, status } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
      disabled={
        status === "Rejected" ||
        status === "Featured" ||
        status === "Denied" ||
        status === "Approved"
          ? true
          : false
      }
    >
      <p className=" ">
        {status === "Rejected" ||
        status === "Featured" ||
        status === "Denied" ||
        status === "Approved" ? (
          status === "Rejected" || status === "Denied" ? (
            <p className="text-red-500 font-semibold mb-0">Rejected </p>
          ) : (
            <p className="text-green-700 font-semibold mb-0">Featured </p>
          )
        ) : (
          <EditOutlined />
        )}
      </p>
    </Button>
  );
};
const RenderFAQButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <p className=" mb-0">
        <FaQuestion className="mt-1 text-lg" />
      </p>
    </Button>
  );
};
const RenderMetaDataButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <p className=" mb-0">
        <FaQuestion className="mt-1 text-lg" />
      </p>
    </Button>
  );
};
const RenderTextClick = (value) => {
  const { id, onClick, count } = value;
  return (
    <p
      className="  mb-0 cursor-pointer"
      onClick={() => {
        onClick(id);
      }}
    >
      {count}
    </p>
  );
};
// View Image Button
const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={Eye}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
const RenderViewButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <p className=" ">
        <EyeOutlined />
      </p>
    </Button>
  );
};
const RenderViewExtraDataButton = (value) => {
  const { id, onClick, name } = value;
  return (
    <Button
      onClick={() => {
        onClick(id, name);
      }}
      type="primary"
    >
      <p className=" ">
        <EyeOutlined />
      </p>
    </Button>
  );
};
const RenderEditImageButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      type="primary"
    >
      <p className=" ">
        <FileImageOutlined />
      </p>
    </Button>
  );
};
// Render Images
const ImageRanders = (value, data) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={data?.imageSize?.width || 50}
        height={data?.imageSize?.height || 50}
        className={`rounded-full object-cover`}
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
const RenderToggleButton = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, checked);
        }}
        checked={checked}
      />
    </div>
  );
};

// Checkbox render
const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

const RenderSelectBox = (value) => {
  const { selected, id, onClick } = value;
  // console.log(selected, id, onClick)
  return (
    <div>
      <Select
        defaultValue={selected || "Pending"}
        style={{
          width: 120,
        }}
        onChange={(val) => {
          onClick({ val, id });
        }}
        disabled={selected == "Approved" || selected == "Denied" ? true : false}
        options={statusList?.map((item) => ({
          value: item.value,
          label:
            item?.Label == "Approved" ? (
              <Tag color="green" className="font-semibold mb-1">
                {item?.Label}
              </Tag>
            ) : item?.Label == "OnGoing" ? (
              <Tag color="blue" className="font-semibold mb-1">
                {item?.Label}
              </Tag>
            ) : item?.Label == "Denied" ? (
              <Tag color="red" className="font-semibold mb-1">
                {item?.Label}
              </Tag>
            ) : (
              <Tag color="orange" className="font-semibold mb-1">
                {item?.Label}
              </Tag>
            ),
        }))}
        // options={statusList}
      />
    </div>
  );
};
export const loginRoot = "/";
export const appRoot = "/app";
export const ROUTES = {
  //   FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  CATEGORY: "/category/sub-category",
  TOOL: "/tool",
  LISTICLES: "/listicles",
  // Tag: "/tag",
  Blog: "/blog",
  BLOGVIEW: "/blog/:id",
  TOOLVIEW: "/tool/:id",
  LISTICLESVIEW: "/listicles/:id",
  USERS: "/users",
  USERS_DETAIL: "/users/:id",
  NOTIFICATION: "/notification",
  TOOLSHEDULE: "/schedule/tool",
  BLOGSHEDULE: "/schedule/blog",
  NEWSSHEDULE: "/schedule/news",
  NEWSCATEGORY: "/category/news-category",
  LISTICLESCATEGORY: "/category/listicles-category",
  NEWS: "/news",
  NEWSVIEW: "/news/:id",
  MAINCATEGORY: "/category/main-category",
  MAINCATEGORYVIEW: "/category/main-category/:id",
  BLOGCATEGORY: "/category/blog-category",
  NEWSCATEGORYVIEW: "/category/news-category/news/:id",
  NEWSSHEDULECATEGORY: "/schedule/news-category/news/:id",
  SUBMIT_TOOL: "/submit-tool",
  SUBMIT_TOOL_VIEW: "/submit-tool/:id",
  HOME: "/home",
  REDIRECTURL: "/redirect-url",
  //   EMPLOYEE_DETAILS: "/employee/:eid/:id",
};
export const PATHNAME = {
  //   FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/app/dashboard",
  CATEGORY: "/app/category/sub-category",
  TOOL: "/app/tool",
  LISTICLES: "/app/listicles",
  // Tag: "/tag",
  Blog: "/app/blog",
  BLOGVIEW: "/app/blog/:id",
  TOOLVIEW: "/app/tool/:id",
  LISTICLESVIEW: "/app/listicles/:id",
  USERS: "/app/users",
  USERS_DETAIL: "/app/users/:id",
  NOTIFICATION: "/app/notification",
  TOOLSHEDULE: "/app/schedule/tool",
  BLOGSHEDULE: "/app/schedule/blog",
  NEWSCATEGORY: "/app/category/news-category",
  LISTICLESCATEGORY: "/app/category/listicles-category",
  NEWSSHEDULE: "/app/schedule/news",
  NEWS: "/app/news",
  NEWSVIEW: "/app/news/:id",
  NEWSCATEGORYVIEW: "/app/category/news-category/news/:id",
  MAINCATEGORY: "/app/category/main-category",
  REDIRECTURL: "/app/redirect-url",
  BLOGCATEGORY: "/app/category/blog-category",
  MAINCATEGORYVIEW: "/app/category/main-category/:id",
  NEWSSHEDULECATEGORY: "/app/schedule/news-category/news/:id",
  SUBMIT_TOOL: "/app/submit-tool",
  SUBMIT_TOOL_VIEW: "/app/submit-tool/:id",
  HOME: "/app/home",
  //   EMPLOYEE_DETAILS: "/employee/:eid/:id",
};
const statusList = [
  {
    value: "Pending",
    Label: "Pending",
  },
  {
    value: "OnGoing",
    Label: "OnGoing",
  },
  {
    value: "Approved",
    Label: "Featured",
  },
  {
    value: "Denied",
    Label: "Rejected",
  },
];
export const submitStatusList = [
  {
    value: "Pending",
    Label: "Pending",
  },
  {
    value: "OnGoing",
    Label: "OnGoing",
  },
  {
    value: "Approved",
    Label: "Featured",
  },
  {
    value: "Denied",
    Label: "Rejected",
  },
];
export const pitchStatusList = [
  {
    value: "Initial",
    Label: "Initial",
  },
  {
    value: "FollowUp1",
    Label: "FollowUp1",
  },
  {
    value: "FollowUp2",
    Label: "FollowUp2",
  },
  {
    value: "FollowUp3",
    Label: "FollowUp3",
  },
  {
    value: "Featured",
    Label: "Featured",
  },
  {
    value: "Rejected",
    Label: "Rejected",
  },
];
const priceList = [
  { id: 1, Label: "Free", value: "Free" },
  { id: 2, Label: "Paid", value: "Premium" },
  { id: 3, Label: "Freemium", value: "Freemium" },
];
const sortBy = [
  { id: 1, Label: "A to Z", value: "ASC" },
  { id: 2, Label: "Z to A", value: "DESC" },
];
const notifyList = [
  { id: 1, Label: "Home", value: "home" },
  { id: 2, Label: "Tool", value: "tool" },
  { id: 3, Label: "Listicle", value: "list" },
  { id: 4, Label: "Blog", value: "blog" },
  { id: 5, Label: "News", value: "news" },
  { id: 6, Label: "Category", value: "category" },
];
const socialList = [
  { value: "twitter", Label: "X" },
  { value: "linkedin", Label: "Linkedin" },
  { value: "instagram", Label: "Instagram" },
  { value: "facebook", Label: "Facebook" },
  { value: "youtube", Label: "Youtube" },
  { value: "discord", Label: "Discord" },
];

const CONSTANTS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  GETMe: null,
  TABLE: {
    USERS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "Profile",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      // {
      //   title: "option",
      //   dataIndex: "selectBox",
      //   render: RenderSelectBox,
      //   width: 200,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 200,
      },
      {
        title: "Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Block",
        dataIndex: "toggle",
        render: RenderToggleButton,
        width: 100,
      },

      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    SUBMIT_TOOL: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "Tool Name",
        dataIndex: "title",
        render: ImageRanders,
        width: 200,
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Tool Url",
        dataIndex: "link",
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        render: (text) => (
          <a
            className="text-blue-700 hover:text-blue-500"
            target="_blank"
            href={text}
          >
            {text}
          </a>
        ),
        width: 200,
      },
      // {
      //   title: "Tool Category",
      //   dataIndex: "mainCategory",
      //   // sorter: (a, b) => alphanumericSort(a, b, "mainCategory"),
      //   width: 200,
      // },
      {
        title: "Country",
        dataIndex: "country",
        sorter: (a, b) => alphanumericSort(a, b, "country"),
        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        // render: RenderSelectBox,
        render: (text) =>
          text === "Approved" ? (
            <Tag color="green" className="font-semibold mb-1">
              {"Featured"}
            </Tag>
          ) : text === "OnGoing" ? (
            <Tag color="blue" className="font-semibold mb-1">
              {text}
            </Tag>
          ) : text === "Denied" ? (
            <Tag color="red" className="font-semibold mb-1">
              {"Rejected"}
            </Tag>
          ) : (
            <Tag color="orange" className="font-semibold mb-1">
              {text}
            </Tag>
          ),
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      // {
      //   title: "Change Status",
      //   dataIndex: "selectBox",
      //   render: RenderSelectBox,
      //   width: 150,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "edit",
        render: RenderPitchEditButton,
        width: 85,
      },
      // {
      //   title: "",
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      //   width: 130,
      // },
    ],
    PITCH: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 110,
      },
      {
        title: "Name",
        dataIndex: "company",
        width: 170,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Tool Name",
        dataIndex: "toolName",
        width: 150,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Email",
        dataIndex: "email",
        width: 200,
        // render: (text) => <p className="font-semibold mb-0">{text}</p>,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        // render: RenderSelectBox,
        render: (text) =>
          text === "Featured" ? (
            <Tag color="green" className="font-semibold mb-1">
              {text}
            </Tag>
          ) : text === "FollowUp3" ||
            text === "FollowUp2" ||
            text === "FollowUp1" ? (
            <Tag color="blue" className="font-semibold mb-1">
              {text}
            </Tag>
          ) : text === "Rejected" ? (
            <Tag color="red" className="font-semibold mb-1">
              {text}
            </Tag>
          ) : (
            <Tag color="orange" className="font-semibold mb-1">
              {text}
            </Tag>
          ),
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 130,
      },

      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderPitchEditButton,
        width: 85,
      },
    ],
    NOTIFICATION: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Description",
        dataIndex: "body",
        sorter: (a, b) => alphanumericSort(a, b, "body"),
        width: 350,
      },
      {
        title: "Purpose",
        dataIndex: "click_action",
        sorter: (a, b) => alphanumericSort(a, b, "click_action"),
        width: 350,
      },
      {
        title: "Send By",
        dataIndex: "sender",
        // sorter: (a, b) => alphanumericSort(a, b, "sender"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
    ],
    NOTIFICATION_SCHEDULED: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Description",
        dataIndex: "body",
        sorter: (a, b) => alphanumericSort(a, b, "body"),
        width: 350,
      },
      {
        title: "Purpose",
        dataIndex: "click_action",
        sorter: (a, b) => alphanumericSort(a, b, "click_action"),
        width: 350,
      },
      {
        title: "Send By",
        dataIndex: "sender",
        // sorter: (a, b) => alphanumericSort(a, b, "sender"),
        width: 200,
      },
      {
        title: "Scheduled Date",
        dataIndex: "schedule",
        sorter: (a, b) => alphanumericSort(a, b, "schedule"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderPitchEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    REDIRECTURL: [
      {
        title: "NO.",
        dataIndex: "no",
        csvData: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Old URL",
        dataIndex: "old",
        csvData: "old",
        sorter: (a, b) => alphanumericSort(a, b, "old"),
        width: 200,
      },
      {
        title: "New URL",
        dataIndex: "new",
        csvData: "new",
        sorter: (a, b) => alphanumericSort(a, b, "new"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        csvData: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    MAINCATEGORY: [
      {
        title: "NO.",
        dataIndex: "no",
        csvData: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        csvData: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        csvData: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewExtraDataButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    BLOGCATEGORY: [
      {
        title: "NO.",
        dataIndex: "no",
        csvData: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        csvData: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        csvData: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    LISTICLESCATEGORY: [
      {
        title: "NO.",
        dataIndex: "no",
        csvData: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        csvData: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        csvData: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    CATEGORY: [
      {
        title: "NO.",
        dataIndex: "no",
        csvData: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        csvData: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 200,
      },
      {
        title: "Main Category",
        dataIndex: "mainCategory",
        csvData: "mainCategory",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        csvData: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      // { title: "Actions", dataIndex: "edit", render: RenderEditButton },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    MAINCATEGORYVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        csvData: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Icon",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        csvData: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 400,
      },

      {
        title: "Date",
        dataIndex: "date",
        csvData: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      // { title: "Actions", dataIndex: "edit", render: RenderEditButton },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    NEWSCATEGORY: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 100,
      },
      {
        title: "Icon",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewExtraDataButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      {
        title: "",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 130,
      },
    ],
    BLOG: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Author",
        dataIndex: "admin",
        sorter: (a, b) => alphanumericSort(a, b, "admin"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 150,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 380,
      },
      {
        title: "Read Time",
        dataIndex: "readTime",
        sorter: (a, b) => alphanumericSort(a, b, "readTime"),
        width: 170,
      },
      {
        title: "Wishlists",
        dataIndex: "wishlists",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "wishlists"),
        width: 120,
      },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "comments"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "addFAQ",
        render: RenderFAQButton,
        width: 80,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    TOOL: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Price",
        dataIndex: "price",
        sorter: (a, b) => alphanumericSort(a, b, "price"),
        render: (text) =>
          text == "Free" ? (
            <Tag color="green" className="font-semibold mb-0">
              {text}
            </Tag>
          ) : text == "Freemium" ? (
            <Tag color="blue" className="font-semibold mb-0">
              {text}
            </Tag>
          ) : (
            <Tag color="red" className="font-semibold mb-0">
              {text}
            </Tag>
          ),
        width: 120,
      },
      {
        title: "Wishlists",
        dataIndex: "wishlists",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "wishlists"),
        width: 120,
      },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Ratings",
        dataIndex: "ratingsAverage",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "ratingsAverage"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "editImages",
        render: RenderEditImageButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "addFAQ",
        render: RenderFAQButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "addAlt",
        render: RenderAltButton,
        width: 80,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    USERTOOLWISHLIST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERBLOGWISHLIST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERTOOLLIKE: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERBLOGLIKE: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERTOOLRATING: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
        width: 300,
      },
      {
        title: "Rate",
        dataIndex: "rating",
        sorter: (a, b) => alphanumericSort(a, b, "rating"),
        width: 100,
      },
      {
        title: "Rate Title",
        dataIndex: "title",
        width: 300,
        sorter: (a, b) => alphanumericSort(a, b, "title"),
      },
      {
        title: "Rate Review",
        dataIndex: "review",
        width: 300,
        sorter: (a, b) => alphanumericSort(a, b, "review"),
      },
      {
        title: "Date",
        dataIndex: "date",
        width: 150,
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 100,
      },
    ],
    USERBLOGCOMMENT: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERTOOLVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERBLOGVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    BLOGWISHLIST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    BLOGLIKE: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    BLOGVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    BLOGCOMMENT: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        sorter: (a, b) => alphanumericSort(a, b, "comment"),
        width: 250,
      },
      {
        title: "Like",
        dataIndex: "likes",
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 130,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 150,
      },
      {
        title: "Reply Comments",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 100,
      },
    ],
    TOOLWISHLIST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    TOOLLIKE: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    TOOLVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    TOOLRATING: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Rate",
        dataIndex: "rating",
        sorter: (a, b) => alphanumericSort(a, b, "rating"),
        width: 100,
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 250,
      },
      {
        title: "Review",
        dataIndex: "review",
        sorter: (a, b) => alphanumericSort(a, b, "review"),
        width: 250,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "delete",
        render: RenderDeleteButton,
        width: 100,
      },
    ],
    SCHEDULEBLOG: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Author",
        dataIndex: "admin",
        sorter: (a, b) => alphanumericSort(a, b, "admin"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 150,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 380,
      },
      {
        title: "Read Time",
        dataIndex: "readTime",
        sorter: (a, b) => alphanumericSort(a, b, "readTime"),
        width: 170,
      },
      {
        title: "Wishlists",
        dataIndex: "wishlists",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "wishlists"),
        width: 120,
      },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "comments"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Scheduled Date",
        dataIndex: "release",
        sorter: (a, b) => alphanumericSort(a, b, "release"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },

      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "addFAQ",
        render: RenderFAQButton,
        width: 80,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    SCHEDULETOOL: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Price",
        dataIndex: "price",
        sorter: (a, b) => alphanumericSort(a, b, "price"),
        render: (text) =>
          text == "Free" ? (
            <Tag color="green" className="font-semibold mb-0">
              {text}
            </Tag>
          ) : text == "Freemium" ? (
            <Tag color="blue" className="font-semibold mb-0">
              {text}
            </Tag>
          ) : (
            <Tag color="red" className="font-semibold mb-0">
              {text}
            </Tag>
          ),
        width: 120,
      },
      {
        title: "Wishlists",
        dataIndex: "wishlists",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "wishlists"),
        width: 120,
      },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Ratings",
        dataIndex: "ratingsAverage",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "ratingsAverage"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Scheduled Date",
        dataIndex: "release",
        sorter: (a, b) => alphanumericSort(a, b, "release"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "editImages",
        render: RenderEditImageButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "addFAQ",
        render: RenderFAQButton,
        width: 80,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    NEWS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        render: (text) => (
          <p className="font-semibold mb-0 line-clamp-2">{text}</p>
        ),
        width: 350,
      },
      {
        title: "Link",
        dataIndex: "link",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        render: (text) => (
          <a
            className="text-blue-700 hover:text-blue-500 line-clamp-1"
            target="_blank"
            href={text}
          >
            {text}
          </a>
        ),
        width: 200,
      },
      {
        title: "News Category",
        dataIndex: "newsCategory",
        // render:RenderTextClick,
        // sorter: (a, b) => alphanumericSort(a, b, "newsCategory"),
        width: 150,
      },
      // {
      //   title: "Tool",
      //   dataIndex: "tool",
      //   // render:RenderTextClick,
      //   sorter: (a, b) => alphanumericSort(a, b, "tool"),
      //   width: 120,
      // },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    NEWSCATEGORYVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        render: (text) => (
          <p className="font-semibold mb-0 line-clamp-2">{text}</p>
        ),
        width: 350,
      },
      {
        title: "Link",
        dataIndex: "link",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        render: (text) => (
          <a
            className="text-blue-700 hover:text-blue-500 line-clamp-1"
            target="_blank"
            href={text}
          >
            {text}
          </a>
        ),
        width: 200,
      },
      // {
      //   title: "Tool",
      //   dataIndex: "tool",
      //   // render:RenderTextClick,
      //   sorter: (a, b) => alphanumericSort(a, b, "tool"),
      //   width: 120,
      // },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    SCHEDULENEWS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        render: (text) => (
          <p className="font-semibold mb-0 line-clamp-2">{text}</p>
        ),
        width: 350,
      },
      {
        title: "Link",
        dataIndex: "link",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        render: (text) => (
          <a
            className="text-blue-700 hover:text-blue-500 line-clamp-1"
            target="_blank"
            href={text}
          >
            {text}
          </a>
        ),
        width: 200,
      },
      {
        title: "News Category",
        dataIndex: "newsCategory",
        // render:RenderTextClick,
        // sorter: (a, b) => alphanumericSort(a, b, "newsCategory"),
        width: 120,
      },
      {
        title: "Scheduled Date",
        dataIndex: "release",
        sorter: (a, b) => alphanumericSort(a, b, "release"),
        width: 200,
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
      },
      // {
      //   title: "Tool",
      //   dataIndex: "tool",
      //   // render:RenderTextClick,
      //   sorter: (a, b) => alphanumericSort(a, b, "tool"),
      //   width: 120,
      // },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    SCHEDULENEWSCATEGORYVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 80,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
        render: (text) => (
          <p className="font-semibold mb-0 line-clamp-2">{text}</p>
        ),
        width: 350,
      },
      {
        title: "Link",
        dataIndex: "link",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "link"),
        render: (text) => (
          <a
            className="text-blue-700 hover:text-blue-500 line-clamp-1"
            target="_blank"
            href={text}
          >
            {text}
          </a>
        ),
        width: 200,
      },
      {
        title: "Scheduled Date",
        dataIndex: "release",
        sorter: (a, b) => alphanumericSort(a, b, "release"),
        width: 200,
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
      },
      // {
      //   title: "Tool",
      //   dataIndex: "tool",
      //   // render:RenderTextClick,
      //   sorter: (a, b) => alphanumericSort(a, b, "tool"),
      //   width: 120,
      // },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Actions",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 85,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    NEWSWISHLIST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    NEWSVIEWS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERNEWSWISHLIST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERNEWSVIEWS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    TOOLNEWS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 120,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 350,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    LISTICLES: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => (
          <p className="font-semibold mb-0 line-clamp-2">{text}</p>
        ),
        width: 380,
      },
      // {
      //   title: "Description",
      //   dataIndex: "description",
      //   sorter: (a, b) => alphanumericSort(a, b, "description"),
      //   render: (text) => <p className="font-semibold mb-0 line-clamp-2">{text}</p>,
      //   width: 350,
      // },
      {
        title: "Likes",
        dataIndex: "likes",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "likes"),
        width: 120,
      },
      {
        title: "Comments",
        dataIndex: "comments",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "comments"),
        width: 120,
      },
      {
        title: "Views",
        dataIndex: "views",
        // render:RenderTextClick,
        sorter: (a, b) => alphanumericSort(a, b, "views"),
        width: 120,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "Actions",
        dataIndex: "view",
        render: RenderViewButton,
        width: 85,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "",
        dataIndex: "edit",
        render: RenderEditButton,
        width: 80,
      },
      {
        title: "",
        dataIndex: "addMetadata",
        render: RenderMetaDataButton,
        width: 80,
      },
      { title: "", dataIndex: "delete", render: RenderDeleteButton, width: 80 },
    ],
    LISTICLESIKE: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    LISTICLESVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
        width: 200,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERLISTICLELIKE: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERLISTICLEVIEW: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        width: 150,
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
        render: (text) => <p className="font-semibold mb-0">{text}</p>,
        width: 400,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
        width: 200,
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        width: 100,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    MAIN_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Main Category Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 5,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
      {
        no: 6,
        Label: "FAQ",
        name: "faqs",
        id: "faqs",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "FAQ",
        col: 24,
        mb: 0,
        // closeIconMargin: "19px",
      },
      // {
      //   no: 6,
      //   Label: "Meta Title",
      //   name: "metaTitle",
      //   id: "metaTitle",
      //   type: "text",
      //   placeholder: "Title",
      // },
      // {
      //   no: 7,
      //   Label: "Meta Description",
      //   name: "metaDescription",
      //   id: "metaDescription",
      //   type: "textarea",
      //   placeholder: "Enter Description",
      // },
    ],
    EDIT_MAIN_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Main Category Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 5,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        image: "image",
        // required: true,
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
      {
        no: 6,
        Label: "Meta Title",
        name: "metaTitle",
        id: "metaTitle",
        type: "text",
        placeholder: "Title",
      },
      {
        no: 7,
        Label: "Meta Description",
        name: "metaDescription",
        id: "metaDescription",
        type: "textarea",
        placeholder: "Enter Description",
      },
      {
        no: 8 ,
        Label: "FAQ",
        name: "faqs",
        id: "faqs",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "FAQ",
        col: 24,
        mb: 0,
        // closeIconMargin: "19px",
      },
    ],
    BLOG_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Blog Category Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Description",
        required: false,
      },
    ],
    LISTICLES_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Blog Category Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Description",
        required: false,
      },
    ],
    CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Subcategory Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 5,
        Label: "Main Category",
        name: "mainCategoryId",
        id: "mainCategoryId",
        type: "select",
        placeholder: "Select Main Category",
        required: true,
        option: [],
        // mode: "multiple",
      },

      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
      // {
      //   no: 7,
      //   Label: "Meta Title",
      //   name: "metaTitle",
      //   id: "metaTitle",
      //   type: "text",
      //   placeholder: "Title",
      // },
      // {
      //   no: 8,
      //   Label: "Meta Description",
      //   name: "metaDescription",
      //   id: "metaDescription",
      //   type: "textarea",
      //   placeholder: "Enter Description",
      // },
    ],
    EDIT_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Subcategory Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 5,
        Label: "Main Category",
        name: "mainCategoryId",
        id: "mainCategoryId",
        type: "select",
        placeholder: "Select Main Category",
        required: true,
        option: [],
        // mode: "multiple",
      },
      {
        no: 6,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        image: "image",
        placeholder: " ",
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
      // {
      //   no: 7,
      //   Label: "Meta Title",
      //   name: "metaTitle",
      //   id: "metaTitle",
      //   type: "text",
      //   placeholder: "Title",
      // },
      // {
      //   no: 8,
      //   Label: "Meta Description",
      //   name: "metaDescription",
      //   id: "metaDescription",
      //   type: "textarea",
      //   placeholder: "Enter Description",
      // },
    ],
    MAINCATEGORYVIEW_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Subcategory Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 5,
        Label: "Main Category",
        name: "mainCategoryId",
        id: "mainCategoryId",
        type: "select",
        placeholder: "Select Main Category",
        required: true,
        option: [],
        defaultValue: {},
        disabled: true,
        // mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
      // {
      //   no: 7,
      //   Label: "Meta Title",
      //   name: "metaTitle",
      //   id: "metaTitle",
      //   type: "text",
      //   placeholder: "Title",
      // },
      // {
      //   no: 8,
      //   Label: "Meta Description",
      //   name: "metaDescription",
      //   id: "metaDescription",
      //   type: "textarea",
      //   placeholder: "Enter Description",
      // },
    ],
    EDIT_MAINCATEGORYVIEW_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Subcategory Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        // type: "richTextarea",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 5,
        Label: "Main Category",
        name: "mainCategoryId",
        id: "mainCategoryId",
        type: "select",
        placeholder: "Select Main Category",
        required: true,
        option: [],
        defaultValue: {},
        disabled: true,
        // mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Meta Title",
      //   name: "metaTitle",
      //   id: "metaTitle",
      //   type: "text",
      //   placeholder: "Title",
      // },
      // {
      //   no: 8,
      //   Label: "Meta Description",
      //   name: "metaDescription",
      //   id: "metaDescription",
      //   type: "textarea",
      //   placeholder: "Enter Description",
      // },
    ],
   REDIRECTURL_MODAL: [
    {
      no: 1,
      Label: "Old URL",
      name: "old",
      id: "old",
      type: "url",
      placeholder: "Enter Old URL",
      required: true,
    },
    {
      no: 2,
      Label: "New URL",
      name: "new",
      id: "new",
      type: "url",
      placeholder: "Enter New URL",
      required: true,
    },
    ],
    NEWS_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter News Category Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 3,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Meta Title",
        // required: true,
      },
      {
        no: 4,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Meta Description",
        // required: true,
      },
      {
        no: 5,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
    ],
    BLOG_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
        handleChangeSlug1: true,
      },
      {
        no: 2,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        handleChangeSlug2: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Author",
        name: "AdminId",
        id: "AdminId",
        type: "select",
        placeholder: "Select Admin",
        required: true,
        option: [],
      },
      // {
      //   no: 6,
      //   Label: "Tags",
      //   name: "tags",
      //   id: "tags",
      //   type: "select",
      //   placeholder: "Select Tag",
      //   required: true,
      //   option: [],
      //   mode: "multiple",
      // },
      {
        no: 7,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 8,
        Label: "Alt",
        name: "alt",
        id: "alt",
        type: "text",
        placeholder: "Enter Alt",
        required: true,
      },
      {
        no: 9,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
    ],
    EDIT_BLOG_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        handleChangeSlug2: true,
      },

      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Author",
        name: "AdminId",
        id: "AdminId",
        type: "select",
        placeholder: "Select Admin",
        required: true,
        option: [],
      },
      // {
      //   no: 6,
      //   Label: "Tags",
      //   name: "tags",
      //   id: "tags",
      //   type: "select",
      //   placeholder: "Select Tag",
      //   required: true,
      //   option: [],
      //   mode: "multiple",
      // },
      {
        no: 7,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      {
        no: 8,
        Label: "Alt",
        name: "alt",
        id: "alt",
        type: "text",
        placeholder: "Enter Alt",
      },
      // {
      //   no: 9,
      //   Label: "Slug",
      //   name: "slug",
      //   id: "slug",
      //   type: "text",
      //   placeholder: "Enter slug",
      //   // required: true,
      // },
      // {
      //   no: 9,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime:'HH:mm:ss',
      //   format:"YYYY-MM-DD HH:mm:ss",
      //   required: true,
      //   disabled:true,
      // },
    ],
    EDIT_NEWS_CATEGORY_MODAL: [
      {
        no: 1,
        Label: "News Category Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter News Category Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: false,
      },
      {
        no: 3,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Meta Title",
        // required: true,
      },
      {
        no: 4,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Meta Description",
        // required: true,
      },
      {
        no: 5,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        // required: true,
        image: "image",
        acceptFormat: "image/*",
        fileSize: 100,
        fileSizeType: "KB",
      },
    ],
    USERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Your Name",
        required: true,
      },

      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number",
        required: true,
        // width: 24,
      },
      {
        no: 3,
        Label: "Username",
        name: "user",
        id: "user",
        type: "text",
        placeholder: "Enter User Name",
        required: true,
      },

      {
        no: 4,
        Label: "Date of Birth",
        name: "date",
        id: "date",
        type: "date",
        placeholder: "Enter Date of Birth",
        required: true,
      },
      {
        no: 5,
        Label: "Profile Picture",
        name: "profilePic",
        id: "profilePic",
        type: "file",
        placeholder: " ",
        required: true,
      },
    ],
    ALT_TOOL_MODAL:[
      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Top Overview",
        name: "topOverview",
        id: "topOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Bottom Overview",
        name: "bottomOverview",
        id: "bottomOverview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
    ],
    TOOL_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Price",
        name: "price",
        id: "price",
        type: "select",
        placeholder: "Select Price",
        required: true,
        option: priceList,
      },
      {
        no: 7,
        Label: "Social Link",
        name: "social",
        id: "social",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "SOCIAL_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 8,
        Label: "Pros",
        name: "pros",
        id: "pros",   
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "PROS_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 9,
        Label: "Cons",
        name: "cons",
        id: "cons",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "CONS_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 10,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 11,
        Label: "Images",
        name: "previews",
        id: "previews",
        type: "file",
        placeholder: " ",
        required: true,
        maxCount: 10,
        acceptFormat: "image/*",
        fileSize: 1,
        fileSizeType: "MB",
      },
      {
        no: 12,
        Label: "Videos Link",
        name: "videos",
        id: "videos",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "VIDEO_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 12,
        Label: "Is Chrome Extension ?",
        name: "isExtension",
        id: "isExtension",
        type: "radio",
        placeholder: "",
        // required: true,
        // defaultValue:true,
        option: [
          {
            id: 1,
            Label: "Yes",
            value: true,
          },
          {
            id: 2,
            Label: "No",
            value: false,
          },
        ],
      },
      {
        no: 13,
        Label: "Is API ?",
        name: "isApi",
        id: "isApi",
        type: "radio",
        placeholder: "",
        // required: true,
        // defaultValue:true,
        option: [
          {
            id: 1,
            Label: "Yes",
            value: true,
          },
          {
            id: 2,
            Label: "No",
            value: false,
          },
        ],
      },
      // {
      //   no: 12,
      //   Label: "Videos",
      //   name: "videos",
      //   id: "videos",
      //   type: "file",
      //   placeholder: " ",
      //   maxCount: 6,
      //   acceptFormat: "video/*",
      // },
      {
        no: 14,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // format:"YYYY-MM-DD",
        // required: true,
      },
    ],
    EDIT_TOOL_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Price",
        name: "price",
        id: "price",
        type: "select",
        placeholder: "Select Price",
        required: true,
        option: priceList,
      },
      // {
      //   no: 7,
      //   Label: "Slug",
      //   name: "slug",
      //   id: "slug",
      //   type: "text",
      //   placeholder: "Enter slug",
      //   // required: true,
      // },
      {
        no: 7,
        Label: "Social Link",
        name: "social",
        id: "social",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "SOCIAL_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 8,
        Label: "Pros",
        name: "pros",
        id: "pros",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "PROS_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 9,
        Label: "Cons",
        name: "cons",
        id: "cons",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "CONS_TOOL",
        closeIconMargin: "8px",
      },
      // {
      //   no: 7,
      //   Label: "Tags",
      //   name: "tags",
      //   id: "tags",
      //   type: "select",
      //   placeholder: "Select Tag",
      //   required: true,
      //   option: [],
      //   mode: "multiple",
      // },
      {
        no: 10,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
        fileSize: 1,
        fileSizeType: "MB",
      },
      // {
      //   no: 9,
      //   Label: "Images",
      //   name: "previews",
      //   id: "previews",
      //   type: "file",
      //   placeholder: " ",
      //   required: false,
      //   maxCount: 6,
      //   acceptFormat: "image/*",
      // },
      {
        no: 11,
        Label: "Videos Link",
        name: "videos",
        id: "videos",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "VIDEO_TOOL",
        closeIconMargin: "8px",
      },
      {
        no: 12,
        Label: "Is Chrome Extension ?",
        name: "isExtension",
        id: "isExtension",
        type: "radio",
        placeholder: "",
        // required: true,
        // defaultValue:true,
        option: [
          {
            id: 1,
            Label: "Yes",
            value: true,
          },
          {
            id: 2,
            Label: "No",
            value: false,
          },
        ],
      },
      {
        no: 13,
        Label: "Is API ?",
        name: "isApi",
        id: "isApi",
        type: "radio",
        placeholder: "",
        // required: true,
        // defaultValue:true,
        option: [
          {
            id: 1,
            Label: "Yes",
            value: true,
          },
          {
            id: 2,
            Label: "No",
            value: false,
          },
        ],
      },
      // {
      //   no: 11,
      //   Label: "Videos Link",
      //   name: "videos",
      //   id: "videos",
      //   // menuLabel:"FAQ",
      //   type: "multifield",
      //   // required: true,
      //   menu: "VIDEO_TOOL",
      //   closeIconMargin: "8px",
      // },
      // {
      //   no: 9,
      //   Label: "Videos",
      //   name: "videos",
      //   id: "videos",
      //   type: "file",
      //   placeholder: " ",
      //   required: false,
      //   maxCount: 6,
      //   acceptFormat: "video/*",
      // },
      // {
      //   no: 10,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime:'HH:mm:ss',
      //   format:"YYYY-MM-DD HH:mm:ss",
      //   disabled:true,
      //   required: true,
      // },
    ],
    EDIT_TOOL_IMAGES_MODAL: [
      {
        no: 1,
        Label: "Images",
        name: "previews",
        id: "previews",
        type: "file",
        placeholder: " ",
        required: false,
        maxCount: 10,
        acceptFormat: "image/*",
        fileSize: 1,
        fileSizeType: "MB",
      },
    ],
    EDIT_TOOL_IMAGE_ALT_MODAL: [
      {
        no: 1,
        Label: "Alt",
        name: "alt",
        id: "alt",
        type: "text",
        placeholder: "Enter Alt",
        required: true,
        autoFocus: true,
      },
    ],
    NOTIFICATION_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "body",
        id: "body",
        type: "text",
        placeholder: "Enter Description",
        required: true,
      },
      // {
      //   no: 3,
      //   Label: "Topic",
      //   name: "topic",
      //   id: "topic",
      //   type: "text",
      //   placeholder: "topic",
      //   required: true,
      // },
      {
        no: 3,
        Label: "Purpose",
        name: "click_action",
        id: "click_action",
        type: "select",
        placeholder: "Select purpose",
        required: true,
        option: notifyList,
      },
      {
        no: 4,
        Label: "Schedule Release",
        name: "schedule",
        id: "schedule",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm",
        format: "YYYY-MM-DD HH:mm",
        required: true,
      },
    ],
    EDIT_TOOL_SCHEDULED_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Price",
        name: "price",
        id: "price",
        type: "select",
        placeholder: "Select Price",
        required: true,
        option: priceList,
      },
      // {
      //   no: 7,
      //   Label: "Tags",
      //   name: "tags",
      //   id: "tags",
      //   type: "select",
      //   placeholder: "Select Tag",
      //   required: true,
      //   option: [],
      //   mode: "multiple",
      // },
      {
        no: 7,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        image: "image",
        placeholder: " ",
        required: false,
        acceptFormat: "image/*",
      },
      // {
      //   no: 9,
      //   Label: "Images",
      //   name: "previews",
      //   id: "previews",
      //   type: "file",
      //   placeholder: " ",
      //   required: false,
      //   maxCount: 6,
      //   acceptFormat: "image/*",
      // },
      {
        no: 8,
        Label: "Videos",
        name: "videos",
        id: "videos",
        type: "file",
        placeholder: " ",
        required: false,
        maxCount: 6,
        acceptFormat: "video/*",
      },
      {
        no: 9,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        required: true,
      },
    ],
    EDIT_BLOG_SCHEDULED_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        handleChangeSlug2: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 4,
        Label: "Read Time",
        name: "readTime",
        id: "readTime",
        type: "number",
        placeholder: "Enter Read Time",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Author",
        name: "AdminId",
        id: "AdminId",
        type: "select",
        placeholder: "Select Admin",
        required: true,
        option: [],
      },
      // {
      //   no: 6,
      //   Label: "Tags",
      //   name: "tags",
      //   id: "tags",
      //   type: "select",
      //   placeholder: "Select Tag",
      //   required: true,
      //   option: [],
      //   mode: "multiple",
      // },
      {
        no: 7,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        image: "image",
        placeholder: " ",
        required: false,
        acceptFormat: "image/*",
      },
      {
        no: 8,
        Label: "Alt",
        name: "alt",
        id: "alt",
        type: "text",
        placeholder: "Enter Alt",
      },
      {
        no: 9,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        required: true,
      },
    ],
    METADATA_MODAL: [
      {
        no: 1,
        Label: "Meta Title",
        name: "metaTitle",
        id: "metaTitle",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Meta Description",
        name: "metaDescription",
        id: "metaDescription",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
    ],
    FAQ_MODAL: [
      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "FAQ",
        name: "faqs",
        id: "faqs",
        // menuLabel:"FAQ",
        type: "multifield",
        required: true,
        menu: "FAQ",
        col: 24,
        mb: 0,
        // closeIconMargin: "19px",
      },
    ],
    BLOG_FAQ_MODAL: [
      {
        no: 1,
        Label: "Meta Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Meta Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "FAQ",
        name: "faqs",
        id: "faqs",
        // menuLabel:"FAQ",
        type: "multifield",
        // required: true,
        menu: "FAQ",
        col: 24,
        mb: 0,
        // closeIconMargin: "19px",
      },
    ],
    FAQ: [
      {
        no: 1,
        Label: "Question",
        name: "question",
        id: "question",
        type: "textarea",
        placeholder: "Enter Question",
        required: true,
      },
      {
        no: 2,
        Label: "Answer",
        name: "answer",
        id: "answer",
        type: "SimpleRichTextArea",
        // type: "textarea",
        placeholder: "Enter Answer",
        required: true,
      },
    ],
    PROS_TOOL:[ {
      no: 1,
      Label: "Pros",
      name: "pros",
      id: "pros",
      type: "text",
      placeholder: "Enter pros",
      required: true,
      // autoFocus: true,
    },],
    CONS_TOOL:[ {
      no: 1,
      Label: "Cons",
      name: "cons",
      id: "cons",
      type: "text",
      placeholder: "Enter cons",
      required: true,
      // autoFocus: true,
    },],
    SOCIAL_TOOL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "select",
        placeholder: "Select title",
        required: true,
        option: socialList,
        // option: [],
        // mode: "multiple",
      },
      {
        no: 2,
        Label: "Link",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter link",
        required: true,
      },
    ],
    VIDEO_TOOL: [
      {
        no:1,
        Label: "Video Link",
        name: "videoLink",
        id: "videoLink",
        type: "url",
        placeholder: "Enter video link",
        required: true,
      },
    ],
    NEWS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 7,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    EDIT_NEWS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime: "HH:mm:ss",
      //   format: "YYYY-MM-DD HH:mm:ss",
      //   // required: true,
      // },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    NEWSCATEGORYVIEW_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
        defaultValue: {},
        disabled: true,
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 7,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    EDIT_NEWSCATEGORYVIEW_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
        defaultValue: {},
        disabled: true,
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      // {
      //   no: 7,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime: "HH:mm:ss",
      //   format: "YYYY-MM-DD HH:mm:ss",
      //   // required: true,
      // },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    NEWS_SCHEDULED_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 7,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    EDIT_NEWS_SCHEDULED_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      {
        no: 7,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    NEWSCATEGORY_NEWS_SCHEDULED_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
        defaultValue: {},
        disabled: true,
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 7,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    EDIT_NEWSCATEGORY_NEWS_SCHEDULED_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 3,
        Label: "Website",
        name: "link",
        id: "link",
        type: "url",
        placeholder: "Enter Website URL",
        required: true,
      },
      {
        no: 4,
        Label: "News Category",
        name: "newsCategoryId",
        id: "newsCategoryId",
        type: "select",
        placeholder: "Select News Category",
        required: true,
        option: [],
        defaultValue: {},
        disabled: true,
      },
      {
        no: 5,
        Label: "Select Tool",
        name: "tools",
        id: "tools",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Icon",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      {
        no: 7,
        Label: "Schedule Release",
        name: "release",
        id: "release",
        type: "date",
        placeholder: "Select Date",
        showTime: "HH:mm:ss",
        format: "YYYY-MM-DD HH:mm:ss",
        // required: true,
      },
      // {
      //   no: 6,
      //   Label: "Tool",
      //   name: "tool",
      //   id: "tool",
      //   type: "select",
      //   placeholder: "Select Tool",
      //   option: [],
      // },
    ],
    LISTICLES_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
        handleChangeSlug1: true,
      },
      {
        no: 2,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        handleChangeSlug2: true,
      },

      {
        no: 3,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 4,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Tools",
        name: "tools",
        id: "tools",
        // menuLabel:"FAQ",
        type: "multifield",
        required: true,
        menu: "LISTICLES_TOOL",
        closeIconMargin: "8px",
        // initialValue:[{}]
        col: 7,
        // mb: 0,
      },
      {
        no: 7,
        Label: "Image",
        name: "image",
        id: "image",
        type: "file",
        placeholder: " ",
        required: true,
        acceptFormat: "image/*",
      },
      {
        no: 8,
        Label: "Alt",
        name: "alt",
        id: "alt",
        type: "text",
        placeholder: "Enter Alt",
        required: true,
      },
      // {
      //   no: 9,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime: "HH:mm:ss",
      //   format: "YYYY-MM-DD HH:mm:ss",
      //   // required: true,
      // },
    ],
    EDIT_LISTICLES_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Title",
        required: true,
        autoFocus: true,
      },
      // {
      //   no: 2,
      //   Label: "Slug",
      //   name: "slug",
      //   id: "slug",
      //   type: "drag-drop",
      //   placeholder: "Slug",
      //   required: true,
      //   handleChangeSlug2:true
      // },
      {
        no: 2,
        Label: "Slug",
        name: "slug",
        id: "slug",
        type: "text",
        placeholder: "Slug",
        required: true,
        handleChangeSlug2: true,
      },

      {
        no: 3,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
      },
      {
        no: 4,
        Label: "Overview",
        name: "overview",
        id: "overview",
        // type: "richTextarea",
        type: "SimpleRichTextArea",
        placeholder: "Enter Overview",
        required: true,
      },
      {
        no: 5,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Select Category",
        required: true,
        option: [],
        mode: "multiple",
      },
      {
        no: 6,
        Label: "Tools",
        name: "tools",
        id: "tools",
        // menuLabel:"FAQ",
        type: "multifield",
        required: true,
        menu: "LISTICLES_TOOL",
        closeIconMargin: "8px",
        // col: 24,
        // mb: 0,
      },
      {
        no: 7,
        Label: "Image",
        name: "image",
        id: "image",
        type: "fileWithPreview",
        placeholder: " ",
        required: false,
        image: "image",
        acceptFormat: "image/*",
      },
      {
        no: 8,
        Label: "Alt",
        name: "alt",
        id: "alt",
        type: "text",
        placeholder: "Enter Alt",
      },
      // {
      //   no: 9,
      //   Label: "Schedule Release",
      //   name: "release",
      //   id: "release",
      //   type: "date",
      //   placeholder: "Select Date",
      //   showTime:'HH:mm:ss',
      //   format:"YYYY-MM-DD HH:mm:ss",
      //   required: true,
      //   disabled:true,
      // },
    ],
    LISTICLES_TOOL: [
      {
        no: 1,
        Label: "Index",
        name: "index",
        id: "index",
        type: "text",
        placeholder: "index",
        required: true,
        // disabled:true,
        val: "",
        col: 2,
        // boxMargin:'12px'
      },
      {
        no: 2,
        Label: "Tool",
        name: "toolId",
        id: "toolId",
        type: "select",
        placeholder: "Select Tool",
        required: true,
        option: [],
        col: 4,
        // boxMargin:'12px'
      },
      {
        no: 3,
        Label: "Description",
        name: "description",
        id: "description",
        type: "textarea",
        placeholder: "Enter Description",
        required: true,
        col: 16,
      },
    ],
    EDIT_HOME_TOOL: [
      {
        no: 1,
        Label: "Select Tool",
        name: "toolId",
        id: "toolId",
        type: "select",
        placeholder: "Select Tool",
        // required: true,
        option: [],
        // mode: "multiple",
      },
    ],
    EDIT_HOME_BLOG: [
      {
        no: 1,
        Label: "Select Blog",
        name: "blogId",
        id: "blogId",
        type: "select",
        placeholder: "Select Blog",
        // required: true,
        option: [],
        // mode: "multiple",
      },
    ],
    EDIT_HOME_LISTICLE: [
      {
        no: 1,
        Label: "Select Listicle",
        name: "listingId",
        id: "listingId",
        type: "select",
        placeholder: "Select Listicle",
        // required: true,
        option: [],
        // mode: "multiple",
      },
    ],
    PITCH_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "company",
        id: "company",
        type: "text",
        placeholder: "Name",
        required: true,
        autoFocus: true,
      },
      {
        no: 2,
        Label: "Is Company ?",
        name: "isCompany",
        id: "isCompany",
        type: "radio",
        placeholder: "",
        required: true,
        // defaultValue:true,
        option: [
          {
            id: 1,
            Label: "Yes",
            value: true,
          },
          {
            id: 2,
            Label: "No",
            value: false,
          },
        ],
      },
      {
        no: 3,
        Label: "Tool Name",
        name: "toolName",
        id: "toolName",
        type: "text",
        placeholder: "Tool Name",
        required: true,
      },
      {
        no: 4,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Email",
        required: true,
      },
    ],
    // EDIT_PITCH_MODAL:[
    //   {
    //     no: 1,
    //     Label: "Company Name",
    //     name: "company",
    //     id: "company",
    //     type: "text",
    //     placeholder: "Company Name",
    //     required: true,
    //     autoFocus: true,
    //   },
    //   {
    //     no: 2,
    //     Label: "Tool Name",
    //     name: "toolname",
    //     id: "toolname",
    //     type: "text",
    //     placeholder: "Tool Name",
    //     required: true,
    //     autoFocus: true,
    //   },
    //   {
    //     no: 3,
    //     Label: "Email",
    //     name: "email",
    //     id: "email",
    //     type: "text",
    //     placeholder: "Email",
    //     required: true,
    //     autoFocus: true,
    //   },
    //   {
    //     no: 4,
    //     Label: "Status",
    //     name: "status",
    //     id: "status",
    //     type: "select",
    //     placeholder: "Select Status",
    //     required: true,
    //     // mode: "multiple",
    //     option: pitchStatusList,
    //   },
    // ]
  },
  API: {
    login: {
      type: "POST",
      endpoint: "/admin/login",
    },
    signUp: { type: "POST", endpoint: "/user/signup" },
    getMe: {
      type: "GET",
      endpoint: "/admin/profile",
    },
    getAdmin: {
      type: "GET",
      endpoint: "/admin",
    },
    getSubmitTool: {
      type: "GET",
      endpoint: "/admin/submit-tool",
    },
    getCategory: {
      type: "GET",
      endpoint: "/admin/category",
    },
    getBlogCategory: {
      type: "GET",
      endpoint: "/admin/category-blog",
    },
    getListiclesCategory: {
      type: "GET",
      endpoint: "/admin/category-listing",
    },
    getMainCategory: {
      type: "GET",
      endpoint: "/admin/main-category",
    },
    getRedirectUrl: {
      type: "GET",
      endpoint: "/admin/redirects",
    },
    getMainSingleCategory: {
      type: "GET",
      endpoint: "/admin/main-category/sub/:dataId",
    },
    getNewsCategory: {
      type: "GET",
      endpoint: "/admin/newsCategory",
    },
    getToolNews: {
      type: "GET",
      endpoint: "/admin/tool/news",
    },
    getToolAlt: {
      type: "GET",
      endpoint: "/admin/toolAlt/:dataId",
    },
    getDashboardCount: {
      type: "GET",
      endpoint: "/admin/dashboard",
    },
    addToolAlt: {
      type: "POST",
      endpoint: "/admin/toolAlt/:dataId",
    },
    addCategory: {
      type: "POST",
      endpoint: "/admin/category",
    },
    addBlogCategory: {
      type: "POST",
      endpoint: "/admin/category-blog",
    },
    addListiclesCategory: {
      type: "POST",
      endpoint: "/admin/category-listing",
    },
    addMainCategory: {
      type: "POST",
      endpoint: "/admin/main-category",
    },
    addRedirectUrl: {
      type: "POST",
      endpoint: "/admin/redirects",
    },
    addNewsCategory: {
      type: "POST",
      endpoint: "/admin/newsCategory",
    },
    editSubmitTool: {
      type: "PATCH",
      endpoint: "/admin/submit-tool/:dataId",
    },
    editCategory: {
      type: "PATCH",
      endpoint: "/admin/category/:dataId",
    },
    editRedirectUrl: {
      type: "PATCH",
      endpoint: "/admin/redirects/:dataId",
    },
    editBlogCategory: {
      type: "PATCH",
      endpoint: "/admin/category-blog/:dataId",
    },
    editListiclesCategory: {
      type: "PATCH",
      endpoint: "/admin/category-listing/:dataId",
    },
    editMainCategory: {
      type: "PATCH",
      endpoint: "/admin/main-category/:dataId",
    },
    editNewsCategory: {
      type: "PATCH",
      endpoint: "/admin/newsCategory/:dataId",
    },
    deleteSubmitTool: {
      type: "DELETE",
      endpoint: "/admin/submit-tool/:dataId",
    },
    deleteCategory: {
      type: "DELETE",
      endpoint: "/admin/category/:dataId",
    },
    deleteRedirectUrl: {
      type: "DELETE",
      endpoint: "/admin/redirects/:dataId",
    },
    deleteBlogCategory: {
      type: "DELETE",
      endpoint: "/admin/category-blog/:dataId",
    },
    deleteListiclesCategory: {
      type: "DELETE",
      endpoint: "/admin/category-listing/:dataId",
    },
    deleteMainCategory: {
      type: "DELETE",
      endpoint: "/admin/main-category/:dataId",
    },
    deleteNewsCategory: {
      type: "DELETE",
      endpoint: "/admin/newsCategory/:dataId",
    },
    getTag: {
      type: "GET",
      endpoint: "/admin/tag",
    },
    addTag: {
      type: "POST",
      endpoint: "/admin/tag",
    },
    editTag: {
      type: "PATCH",
      endpoint: "/admin/tag/:dataId",
    },
    deleteTag: {
      type: "DELETE",
      endpoint: "/admin/tag/:dataId",
    },
    getBlog: {
      type: "GET",
      endpoint: "/admin/blog",
    },
    getOneBlog: {
      type: "GET",
      endpoint: "/admin/blog/:id",
    },
    addBlog: {
      type: "POST",
      endpoint: "/admin/blog",
    },
    editBlog: {
      type: "PATCH",
      endpoint: "/admin/blog/:dataId",
    },
    deleteBlog: {
      type: "DELETE",
      endpoint: "/admin/blog/:dataId",
    },
    getListicles: {
      type: "GET",
      endpoint: "/admin/listing",
    },
    getOneListicles: {
      type: "GET",
      endpoint: "/admin/listing/:id",
    },
    addListicles: {
      type: "POST",
      endpoint: "/admin/listing",
    },
    editListicles: {
      type: "PATCH",
      endpoint: "/admin/listing/:dataId",
    },
    deleteListicles: {
      type: "DELETE",
      endpoint: "/admin/listing/:dataId",
    },
    getUsers: {
      type: "GET",
      endpoint: "/admin/users/",
    },
    getOneUser: {
      type: "GET",
      endpoint: "/admin/users/:id",
    },
    editUser: {
      type: "PATCH",
      endpoint: "/admin/users/:dataId",
    },
    deleteUsers: {
      type: "DELETE",
      endpoint: "/admin/users/:dataId",
    },
    getTool: {
      type: "GET",
      endpoint: "/admin/tool",
    },
    getOneTool: {
      type: "GET",
      endpoint: "/admin/tool/:id",
    },
    getOneRedirectUrl: {
      type: "GET",
      endpoint: "/admin/redirects/:id",
    },
    getToolImages: {
      type: "GET",
      endpoint: "/admin/tools/preview?toolId=:dataId",
    },
    getBlogViewsList: {
      type: "GET",
      endpoint: "/admin/blogs/view?blogId=:dataId",
    },
    getBlogLikesList: {
      type: "GET",
      endpoint: "/admin/blogLike?blogId=:dataId",
    },
    getBlogWishlistList: {
      type: "GET",
      endpoint: "/admin/blogWishlist?blogId=:dataId",
    },
    getBlogCommentList: {
      type: "GET",
      endpoint: "/admin/comment?blogId=:dataId",
    },
    getListicleCommentList: {
      type: "GET",
      endpoint: "/listing-comment?listingId=:dataId",
    },
    getToolViewsList: {
      type: "GET",
      endpoint: "/admin/tools/view?toolId=:dataId",
    },
    getToolLikesList: {
      type: "GET",
      endpoint: "/admin/toolLike?toolId=:dataId",
    },
    getToolWishlistList: {
      type: "GET",
      endpoint: "/admin/toolWishlist?toolId=:dataId",
    },
    addTool: {
      type: "POST",
      endpoint: "/admin/tool",
    },
    addToolImages: {
      type: "POST",
      endpoint: "/admin/tools/preview",
    },
    editTool: {
      type: "PATCH",
      endpoint: "/admin/tool/:dataId",
    },
    editToolImages: {
      type: "PATCH",
      endpoint: "/admin/tools/preview/:dataId",
    },
    deleteTool: {
      type: "DELETE",
      endpoint: "/admin/tool/:dataId",
    },
    deleteComment: {
      type: "DELETE",
      endpoint: "/admin/comment/:dataId",
    },
    deleteReplyComment: {
      type: "DELETE",
      endpoint: "/admin/comments/reply/:dataId",
    },
    deleteListicleComment: {
      type: "DELETE",
      endpoint: "/admin/listing-comment/:dataId",
    },
    deleteReplyListicleComment: {
      type: "DELETE",
      endpoint: "/admin/listing-comments/reply/:dataId",
    },
    deleteToolImages: {
      type: "DELETE",
      endpoint: "/admin/tools/preview/:dataId",
    },
    getOneRating: {
      type: "GET",
      endpoint: "/admin/tools/rating/?toolId=",
    },
    deleteRateing: {
      type: "DELETE",
      endpoint: "/admin/tools/rating/:dataId",
    },
    getUserToolWishlist: {
      type: "GET",
      endpoint: "/admin/toolWishlist?userId=:dataId",
    },
    getUserBlogWishlist: {
      type: "GET",
      endpoint: "/admin/blogWishlist?userId=:dataId",
    },
    getUserToolLike: {
      type: "GET",
      endpoint: "/admin/toolLike?userId=:dataId",
    },
    getUserBlogLike: {
      type: "GET",
      endpoint: "/admin/blogLike?userId=:dataId",
    },
    getUserListiclesLike: {
      type: "GET",
      endpoint: "/admin/listingLike?userId=:dataId",
    },
    getUserToolView: {
      type: "GET",
      endpoint: "/admin/tools/view?userId=:dataId",
    },
    getUserBlogView: {
      type: "GET",
      endpoint: "/admin/blogs/view?userId=:dataId",
    },
    getUserListiclesView: {
      type: "GET",
      endpoint: "/admin/listings/view?userId=:dataId",
    },
    getUserToolRating: {
      type: "GET",
      endpoint: "/admin/tools/rating?userId=:dataId",
    },
    getUserBlogComment: {
      type: "GET",
      endpoint: "/admin/comment?userId=:dataId",
    },
    getUserListiclesComment: {
      type: "GET",
      endpoint: "/admin/listing-comments?userId=:dataId",
    },
    adminFlush: {
      type: "POST",
      endpoint: "/admin/flush",
    },
    adminRefillSearch: {
      type: "POST",
      endpoint: "/admin/elasticsearch/refill",
    },
    editNotifiactions: {
      type: "PATCH",
      endpoint: "/admin/notification/:dataId",
    },
    addNotifiactions: {
      type: "POST",
      endpoint: "/admin/notification",
    },
    getNotifictions: {
      type: "GET",
      endpoint: "/admin/notification",
    },
    deleteNotifiactions: {
      type: "DELETE",
      endpoint: "/admin/notification/:dataId",
    },

    addToolFAQ: {
      type: "POST",
      endpoint: "/admin/toolSeo/:dataId",
    },
    getOneToolFAQ: {
      type: "GET",
      endpoint: "/admin/toolSeo/:dataId",
    },
    addBlogFAQ: {
      type: "POST",
      endpoint: "/admin/blogSeo/:dataId",
    },
    addListMeta: {
      type: "PATCH",
      endpoint: "/admin/listing/meta/:dataId",
    },
    getOneBlogFAQ: {
      type: "GET",
      endpoint: "/admin/blogSeo/:dataId",
    },
    addMainCategoryFAQ: {
      type: "POST",
      endpoint: "/admin/mainCategorySeo/:dataId",
    },
    getOneMainCategoryFAQ: {
      type: "GET",
      endpoint: "/admin/mainCategorySeo/:dataId",
    },
    getOneListMeta: {
      type: "GET",
      endpoint: "/admin/listing/meta/:dataId",
    },
    getScheduledTool: {
      type: "GET",
      endpoint: "/admin/tool/scheduled",
    },
    getScheduledBlog: {
      type: "GET",
      endpoint: "/admin/blog/scheduled",
    },
    getScheduledNotification: {
      type: "GET",
      endpoint: "/admin/notification/scheduled",
    },
    getAllDashbordListByUser: {
      type: "GET",
      endpoint: "/admin/dashboard/user/:dataId",
    },
    getJoinUsersAnalaytics: {
      type: "GET",
      endpoint: "/admin/dashboard/users",
    },
    addNews: {
      type: "POST",
      endpoint: "/admin/news",
    },
    editNews: {
      type: "PATCH",
      endpoint: "/admin/news/:dataId",
    },
    deleteNews: {
      type: "DELETE",
      endpoint: "/admin/news/:dataId",
    },
    getNews: {
      type: "GET",
      endpoint: "/admin/news",
    },
    getScheduledCategoryNews: {
      type: "GET",
      endpoint: "/admin/newsCategory/scheduled/:dataId",
    },
    getSingleCategoryNews: {
      type: "GET",
      endpoint: "/admin/newsCategory/news/:dataId",
    },
    getOneMainCategory: {
      type: "GET",
      endpoint: "/admin/main-category/:id",
    },
    getOneCategory: { type: "GET", endpoint: "/admin/category/:id" },
    getNewsWishlistList: {
      type: "GET",
      endpoint: "/admin/newsWishlist?newsId=:dataId",
    },
    getNewsViewList: {
      type: "GET",
      endpoint: "/admin/newsViews?newsId=:dataId",
    },
    getUserNewsWishlistList: {
      type: "GET",
      endpoint: "/admin/newsWishlist?userId=:dataId",
    },
    getUserNewsViewList: {
      type: "GET",
      endpoint: "/admin/newsViews?userId=:dataId",
    },
    getToolNewsData: {
      type: "GET",
      endpoint: "/toolNews?newsId=:dataId",
    },
    getNewsSchedualData: {
      type: "GET",
      endpoint: "/admin/news/scheduled",
    },
    getOneSubmitTool: {
      type: "GET",
      endpoint: "/admin/submit-tool/:dataId",
    },
    getListiclesViewsList: {
      type: "GET",
      endpoint: "/admin/listings/view?listingId=:dataId",
    },
    getListiclesLikesList: {
      type: "GET",
      endpoint: "/admin/listingLike?listingId=:dataId",
    },
    getHomeTool: {
      type: "GET",
      endpoint: "/admin/home/tool",
    },
    editHomeTool: {
      type: "PATCH",
      endpoint: "/admin/home/tool/:dataId",
    },
    getHomeBlog: {
      type: "GET",
      endpoint: "/admin/home/blog",
    },
    editHomeBlog: {
      type: "PATCH",
      endpoint: "/admin/home/blog/:dataId",
    },
    getHomeListing: {
      type: "GET",
      endpoint: "/admin/home/listing",
    },
    editHomeListing: {
      type: "PATCH",
      endpoint: "/admin/home/listing/:dataId",
    },
    getBlogNameList: {
      type: "GET",
      endpoint: "/admin/blog/all",
    },
    getListicleNameList: {
      type: "GET",
      endpoint: "/admin/listing/all",
    },
    getPitch: {
      type: "GET",
      endpoint: "/admin/pitch",
    },
    addPitch: {
      type: "POST",
      endpoint: "/admin/pitch",
    },
    editPitch: {
      type: "PATCH",
      endpoint: "/admin/pitch/:dataId",
    },
  },
  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "Tool Wishlists",
        name: "toolWishlist",
        id: "toolWishlist",
      },
      {
        no: 2,
        Label: "Tool Likes",
        name: "toolLike",
        id: "toolLike",
      },
      {
        no: 3,
        Label: "Tool Ratings",
        name: "toolRating",
        id: "toolRating",
      },
      {
        no: 4,
        Label: "Tool Views",
        name: "toolView",
        id: "toolView",
      },
      {
        no: 5,
        Label: "Blog Wishlists",
        name: "blogWishlist",
        id: "blogWishlist",
      },
      {
        no: 6,
        Label: "Blog Likes",
        name: "blogLike",
        id: "blogLike",
      },
      // {
      //   no: 7,
      //   Label: "Blog Comments",
      //   name: "blogComment",
      //   id: "blogComment",
      // },
      {
        no: 7,
        Label: "Blog Views",
        name: "blogView",
        id: "blogView",
      },
      {
        no: 8,
        Label: "News Wishlist",
        name: "newsWishlist",
        id: "newsWishlist",
      },
      {
        no: 9,
        Label: "News Views",
        name: "newsViews",
        id: "newsViews",
      },
      {
        no: 10,
        Label: "Listicle Likes",
        name: "listicleLikes",
        id: "listicleLikes",
      },
      {
        no: 11,
        Label: "Listicle Views",
        name: "listicleViews",
        id: "listicleViews",
      },
    ],
    BLOG: [
      {
        no: 1,
        Label: "Overview",
        name: "overview",
        id: "overview",
      },
      {
        no: 2,
        Label: "Wishlists",
        name: "wishlist",
        id: "wishlist",
      },
      {
        no: 3,
        Label: "Likes",
        name: "like",
        id: "like",
      },
      {
        no: 4,
        Label: "Comments",
        name: "comment",
        id: "comment",
      },
      {
        no: 5,
        Label: "Views",
        name: "view",
        id: "view",
      },
    ],
    LISTICLES: [
      // {
      //   no: 1,
      //   Label: "Overview",
      //   name: "overview",
      //   id: "overview",
      // },
      {
        no: 2,
        Label: "Likes",
        name: "like",
        id: "like",
      },
      {
        no: 3,
        Label: "Comments",
        name: "comment",
        id: "comment",
      },
      {
        no: 4,
        Label: "Views",
        name: "view",
        id: "view",
      },
    ],
    TOOL: [
      {
        no: 1,
        Label: "Overview",
        name: "overview",
        id: "overview",
      },
      {
        no: 2,
        Label: "Wishlists",
        name: "wishlist",
        id: "wishlist",
      },
      {
        no: 3,
        Label: "Likes",
        name: "like",
        id: "like",
      },
      {
        no: 4,
        Label: "Ratings",
        name: "rating",
        id: "rating",
      },
      {
        no: 5,
        Label: "Views",
        name: "view",
        id: "view",
      },
    ],
    NEWS: [
      {
        no: 1,
        Label: "Wishlists",
        name: "wishlist",
        id: "wishlist",
      },
      {
        no: 2,
        Label: "Views",
        name: "view",
        id: "view",
      },
      {
        no: 3,
        Label: "Tools",
        name: "tool",
        id: "tool",
      },
    ],
  },
  FILTER: {
    TOOL: [
      {
        no: 1,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Filter By Category",
        required: true,
        mode: "multiple",
        option: [],
      },
      {
        no: 2,
        Label: "News Category",
        name: "price",
        id: "price",
        type: "select",
        placeholder: "Filter By Price",
        required: true,
        option: priceList,
      },
      {
        no: 3,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
    LISTICLES: [
      {
        no: 1,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Filter By Category",
        required: true,
        mode: "multiple",
        option: [],
      },
      {
        no: 2,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
    BLOG: [
      {
        no: 1,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Filter By Category",
        required: true,
        mode: "multiple",
        option: [],
      },
      {
        no: 2,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
    SUBCATEGORY: [
      {
        no: 1,
        Label: "Main Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Filter By Main Category",
        required: true,
        // mode: "multiple",
        option: [],
      },
      {
        no: 2,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "name",
      },
    ],
    SUBMIT_TOOL: [
      {
        no: 1,
        Label: "Status",
        name: "status",
        id: "status",
        type: "select",
        placeholder: "Filter By Status",
        required: true,
        // mode: "multiple",
        option: statusList,
      },
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
    PITCH: [
      {
        no: 1,
        Label: "Status",
        name: "status",
        id: "status",
        type: "select",
        placeholder: "Filter By Status",
        required: true,
        // mode: "multiple",
        option: pitchStatusList,
      },
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "toolName",
      },
    ],
    NEWS: [
      {
        no: 1,
        Label: "Category",
        name: "categories",
        id: "categories",
        type: "select",
        placeholder: "Filter By News Category",
        required: true,
        // mode: "multiple",
        option: [],
      },
      {
        no: 2,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
    NEWSCATEGORYVIEW: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
    NEWSCATEGORY: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "name",
      },
    ],
    CATEGORY: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "name",
      },
    ],
    MAINCATEGORYVIEW: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "name",
      },
    ],
    BLOGCATEGORY: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "name",
      },
    ],
    LISTICLESCATEGORY: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "name",
      },
    ],
    USER: [
      {
        no: 1,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "username",
      },
    ],
    NEWSCATEGORYSCHEDUALNEWS: [
      {
        no: 2,
        Label: "Sort",
        name: "sort",
        id: "sort",
        type: "select",
        placeholder: "Filter By Sort",
        required: true,
        option: sortBy,
        sort: "title",
      },
    ],
  },
};
export default CONSTANTS;
