import React from "react";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import PageComponent from "../../../../component/common/Page-Component";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const UserToolRating = ({ dataId }) => {
  let list = { ...CONSTANTS.API.getUserToolRating};
  const apitoolList = apiGenerator(list, { dataId });
  const navigate=useNavigate();
  const handleViewTool=(id)=>{
    navigate(`/app/tool/${id}`)
  }
  return (
    <div>
      <div className="mt-5 mx-3">
        <PageComponent
        trackEventName="Tool Rating"
          tableHeaders="USERTOOLRATING"
          tableTitle="Tool Ratings"
          getAPI={apitoolList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data,
                no: `TR${data?.id.toString().padStart(7, "0")}`, 
                image:data?.tool?.image,
                name:data?.tool?.title,
                title:data?.title ? data?.title : "-",
                review:data?.review ? data?.review : "-",
                view:{
                    id:data?.toolId,
                    onClick:(id)=>{handleViewTool(id)}
                  },
                date:moment(data?.createdAt).format('lll'),              
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/tool?search="
          deleteData
          deleteAPI={CONSTANTS.API.deleteRateing}
        //   viewData
        //   viewFunction={handleViewTool}
        />
      </div>
    </div>
  );
};

export default UserToolRating;
