import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Tag,
} from "antd";

import { pitchStatusList } from "../../util/constant/CONSTANTS";
import CONSTANTS from "../../util/constant/CONSTANTS";
import useHttp from "../../hooks/use-http";
import Label from "../common/Label";
import { apiGenerator } from "../../util/functions";
import { useMixpanel } from "react-mixpanel-browser";
const { Option } = Select;
const PitchEditModal = ({
  open,
  onCancel,
  name = "",
  formData = {},
  setReloading = () => {},
  setOpen = () => {},
}) => {
  console.log(formData, "*-*-formData");
  const [form] = Form.useForm();
  const [toolList, setToolList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedToolOption, setSelectedToolOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const mixpanel = useMixpanel();
  const Admin = JSON.parse(localStorage.getItem("admin"));
  const Token = localStorage.getItem("token");
  const API = useHttp();
  useEffect(() => {
    if (selectedOption === "Featured") {
      const apiTool = { ...CONSTANTS.API.getToolNews };
      API.sendRequest(apiTool, (res) => {
        const tool = res?.data?.map((i) => {
          return {
            ...i,
            Label: i?.title,
            value: i?.id,
            id: i?.id,
          };
        });
        setToolList(tool);
      });
    }
  }, [selectedOption]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    setInputValue(""); // Reset input value when changing options
  };
  const handleSelectChangeTool = (value) => {
    setSelectedToolOption(value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
const handleEditPitch=(values)=>{
  const editPitch = { ...CONSTANTS.API.editPitch };
    console.log(formData?.id);
    const apiEditPitch = apiGenerator(editPitch, {
      dataId: formData?.id,
    });
    let payload={}
    if(values?.status === 'Featured')
    {
      payload = {
        status: values?.status,
        toolId:values?.tool,
      };
    }
    else if(values?.status === 'Rejected')
    {
      payload = {
        status: values?.status,
        reason:values?.reason
      };
    }
    else
    {
      payload = {
        status: values?.status,
      };
    }
    
    API.sendRequest(
      apiEditPitch,
      (res) => {
        setOpen((prv) => !prv);
        setReloading((prv) => !prv);
        form.resetFields();
        mixpanel.identify(Admin?.email);
        mixpanel.track(`Edit Pitch`, {
          Token: Token,
          Admin: { id: Admin?.id, name: Admin?.name },
          ExtraData: { Id: formData?.id },
        });
      },
      payload,
      "Edit Pitch Successfully!!!"
    );
}
  return (
    <>
      <Modal
        open={open}
        title={<p className="text-2xl mb-10 text-slate-500">{name}</p>}
        okText="Submit"
        width={700}
        // {...footer}
        cancelButtonProps={{ style: { display: "none" } }}
        className="form-modal-title"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              console.log(values, "*-*-values");
              handleEditPitch(values)
              // form.resetFields();
              // onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form}>
          <Row className="items-center">
            <Col xs={6}>
              <Label required={true}>Select Status:</Label>
            </Col>
            <Col xs={18}>
              <Form.Item
                name={"status"}
                id={formData?.id}
                className=""
                initialValue={selectedOption || null}
                hasFeedback
                required={true}
                rules={[
                  {
                    required: true,
                    message: "Please select status",
                  },
                ]}
              >
                <Select
                  defaultValue={null}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  placeholder="Select Status"
                  className="w-full"
                  showSearch
                >
                  {pitchStatusList.map((status) => (
                    <Option
                      key={status.value}
                      value={status.value}
                      disabled={
                        status.value === "Initial" || // Always disable Initial
                        formData?.status === status.value || // Disable if formData.status matches
                        (formData?.status === "FollowUp2" &&
                          (status.value === "FollowUp2" ||
                            status.value === "FollowUp1")) || // Disable FollowUp1 and FollowUp2 if formData.status is FollowUp2
                        ((formData?.status === "FollowUp3" ||
                          formData?.status === "Featured") &&
                          (status.value === "FollowUp3" ||
                            status.value === "FollowUp2" ||
                            status.value === "FollowUp1")) // Disable FollowUp1, FollowUp2, and FollowUp3 if formData.status is FollowUp3
                      }
                    >
                      {status?.Label == "Featured" ? (
                        <Tag color="green" className="font-semibold mb-1">
                          {status?.Label}
                        </Tag>
                      ) : status?.Label == "FollowUp3" ||
                        status?.Label == "FollowUp2" ||
                        status?.Label == "FollowUp1" ? (
                        <Tag color="blue" className="font-semibold mb-1">
                          {status?.Label}
                        </Tag>
                      ) : status?.Label == "Rejected" ? (
                        <Tag color="red" className="font-semibold mb-1">
                          {status?.Label}
                        </Tag>
                      ) : (
                        <Tag color="orange" className="font-semibold mb-1">
                          {status?.Label}
                        </Tag>
                      )}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {selectedOption === "Featured" && (
            <Row className="items-center">
              <Col xs={6}>
                <Label required={true}>Select Featured Tool:</Label>
              </Col>
              <Col xs={18}>
                <Form.Item
                  name={"tool"}
                  id={2}
                  className=""
                  initialValue={selectedToolOption || null}
                  hasFeedback
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: "Please select tool",
                    },
                  ]}
                >
                  <Select
                    defaultValue={null}
                    value={selectedToolOption}
                    onChange={handleSelectChangeTool}
                    placeholder="Select Tool"
                    className="w-full"
                    showSearch
                    options={toolList?.map((item) => ({
                      value: item.value,
                      label: item.Label,
                    }))}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    
                    {/* {toolList.map((tool) => (
                      <Option key={tool.value} value={tool.value}>
                        {tool.Label}
                      </Option>
                    ))} */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {selectedOption === "Rejected" && (
            <Row className="items-center">
              <Col xs={6}>
                <Label required={true}>Reason of rejection:</Label>
              </Col>
              <Col xs={18}>
                <Form.Item
                  name={"reason"}
                  className=""
                  id={3}
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: "Enter Reason of rejection",
                    },
                  ]}
                  initialValue={formData?.reason || ""}
                >
                  <Input.TextArea
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Reason for rejection"
                    className="w-full"
                    initialValue={formData?.reason || ""}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default PitchEditModal;
// PitchEditModal.defaultProps = {
//   open: false,
//   onCreate: () => {},
//   onCancel: () => {},
//   name: "",
//   formData: {},
//   menu: "",
//   edit: false,
//   onEdit: () => {},
//   formFields: [],
// };
