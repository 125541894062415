import React from 'react'
import { useParams } from 'react-router-dom';
import ToolWishlist from './wishlist';
import ToolLike from './like';
import ToolUserView from './view';
import ToolRating from './toolRating';
import { Card, Tabs } from 'antd';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import ToolView from './Overview/toolView';

const ToolDetail = () => {
    const { id } = useParams();
    const menus = [
      { overview: <ToolView dataId={id} /> },
      { wishlist: <ToolWishlist dataId={id} /> },
      { like: <ToolLike dataId={id} /> },
      { view: <ToolUserView dataId={id}/> },
      { rating: <ToolRating dataId={id} /> },]
  return (
    <div>
       <div className="mt-10">
      
        <Card className="mt-10">
          <Tabs
            className="p-0 m-0"
            defaultActiveKey="1"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.TOOL.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: menus.map((data) => {
                  if (Object.keys(data)[0] === ele.id) {
                    return data[ele.id];
                  }
                }),
              };
            })}
          />
        </Card>
      </div>
    </div>
  )
}

export default ToolDetail
