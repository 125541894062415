import { Col, Row, Select } from "antd";
import React, { useState, memo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { createBrowserHistory } from 'history';
const CustomFilterSelect = ({ data, getSelectedValues = () => {} }) => {
  const [category, setCategory] = useState([]);
  const [price, setPrice] = useState("");
  const [sortData,setSortData]=useState({sort:'',sortBy:''})
  const location = useLocation();

  // Access the state data
  const locationCatId = location?.state?.categoryId !== undefined ? [location?.state?.categoryId] : null;
  const loactionCatName = location?.state?.categoryName || "";
  let load = false;
  // const history = createBrowserHistory();

  // useEffect(() => {
  //   // Check if there's state in the location
  //   if (location.state) {
  //     // Do something with the state if needed
  //     // console.log('Received state:', location.state);

  //     // Clear the state by replacing the current URL
  //     history.replace({ ...location, state: undefined });
  //   }
  // }, [location.state, history]);

  // useEffect(() => {
  //   if (!load && locationCatId?.length > 0) {
  //     // console.log("--------")
  //     getSelectedValues({ category: locationCatId, price: "" , sort:'' , sortBy: ''}); 
  //     // console.log("---**-----")
  //   }
  //   return () => {
  //     load = true;
      
  //   };
  // }, [locationCatId?.length > 0]);
  
  const defaultValue = locationCatId?.length > 0
    ? { value: locationCatId, label: loactionCatName }
    : null;
    
  const handleChange = (value, name , sort) => {
    // console.log(`selected ${value}`);

    if (name === "categories") {
      // console.log(value,"-*-");
      if (!Array.isArray(value) && value !== undefined ) {
        // If not an array, convert it to an array
        value = [value];
      }
      setCategory(value);
      getSelectedValues({ category:  value === undefined ? [] : value, price , sort:sortData?.sort , sortBy:sortData?.sortBy});
    } else if (name === "price") {
      setPrice(value === undefined ? "" : value);
      getSelectedValues({ category, price: value === undefined ? "" : value , sort:sortData?.sort , sortBy:sortData?.sortBy});
    }
    else if(name === "sort")
    {
      console.log({sort:sort , sortBy: value === undefined ? "" : value })
      setSortData({sort:sort , sortBy: value === undefined ? "" : value })
      getSelectedValues({ category, price , sort:sort , sortBy: value === undefined ? "" : value });
    }
    else if(name === "status")
    {
      getSelectedValues({ category, price , sort:sort , sortBy: value === undefined ? "" : value,status:value === undefined ? "" :value });
    }
  };

  return (
    <div>
      <Row gutter={10}>
        {data &&
          data?.map((ele, index) => (
            <Col key={index} span={6}>
              <Select
                showSearch
                mode={ele?.mode || ""}
                allowClear
                options={ele?.option?.sort((a, b) => a.Label.localeCompare(b.Label)).map((item) => ({
                  value: item?.value,
                  label: item?.Label,
                }))}
                style={{ width: "100%" }}
                placeholder={ele?.placeholder || "Select"}
                className="mt-5"
                onChange={(val) => {
                  handleChange(val, ele?.name , ele?.sort);
                }}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                defaultValue={!ele?.mode && ele?.name == "categories" ? defaultValue : []}
              ></Select>
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default memo(CustomFilterSelect);
