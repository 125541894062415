import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, notification } from "antd";
import FormFields from "./FormFields";
import useHttp from "../../hooks/use-http";
import { apiGenerator } from "../../util/functions";
import { useLocation } from "react-router-dom";
import { PATHNAME } from "../../util/constant/CONSTANTS";
const ModalMetaData = ({
  open,
  onCreate,
  onCancel,
  name = "",
  formData = {},
  menu,
  disabled = false,
  edit,
  SubmitName = "Submit",
  onEdit = () => {},
  Delete,
  onDelete = () => {},
  formFields = [],
  loading = false,
  getAPI = null,
  dataId = null,
  children,
}) => {
  const api = useHttp();
  const [form] = Form.useForm();
  const [metaData, setMetaData] = useState({});
  const formRef = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    if (open) {
      const oneMeta = { ...getAPI };
      const apiOneMeta = apiGenerator(oneMeta, { dataId });
      api.sendRequest(apiOneMeta, (res) => {
        setMetaData(res?.data);     
         
      });
    }
  }, [open]);
// console.log(metaData)
  useEffect(() => {
    if (pathname == PATHNAME.TOOL || pathname == PATHNAME.TOOLSHEDULE || pathname == PATHNAME.LISTICLES) return;
    if (open) {
      const handleKeyPress = (e) => {
        if (e?.key === "Enter" && !e?.shiftKey) {
          form
            .validateFields()
            .then((values) => {
              onCreate(values, () => {
                form?.resetFields();
              });
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [open]);
  return (
    <Modal
      open={open}
      title={<p className="text-2xl mb-10 text-slate-500">{name}</p>}
      okText="Submit"
      width={1000}
      cancelButtonProps={{ style: { display: "none" } }}
      className="form-modal-title"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // console.log(values, "*-*-*-value ");
              onCreate(values, () => {
                form.resetFields();
              });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <FormFields
        formData={metaData || {}}
        menu={menu}
        formFields={formFields}
        form={form}
        disabled={disabled}
        formRef={formRef}
      />
      {children}
    </Modal>
  );
};

export default ModalMetaData;
ModalMetaData.defaultProps = {
  open: false,
  onCreate: () => {},
  onCancel: () => {},
  name: "",
  formData: {},
  menu: "",
  edit: false,
  onEdit: () => {},
  formFields: [],
};
