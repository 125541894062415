import { Card, Col, DatePicker, Image, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import dayjs from "dayjs";
import Heading from "../../../component/common/Heading";
import {
  Archive,
  BoxAdd,
  Category,
  Educare,
  Lovely,
  Magicpen,
  Notification,
  NotificationStatus,
  Receipt21,
  ReceiptItem,
  ReceiptSearch,
  Star,
  Tag,
  Tag2,
  User,
} from "iconsax-react";
import { FaRegCommentAlt, FaRegComments } from "react-icons/fa";
const Dashboard = () => {
  const api = useHttp();
  const { RangePicker } = DatePicker;
  const [count, setCount] = useState({});
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const dateFilterFunction = (e) => {
    console.log(e);
    // console.log(dayjs(e[0]).format("YYYY-MM-DD"));
    // console.log(dayjs(e[1]).format("YYYY-MM-DD"));
    if (e) {
      setDates({
        startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
        endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
    let datefilter = "";
    if (dates.startDate !== null && dates.endDate !== null) {
      datefilter = `?startDate=${dates.startDate}&endDate=${dates.endDate}`;
    }
  };
  useEffect(() => {
    let datefilter = "";
    if (dates.startDate !== null && dates.endDate !== null) {
      datefilter = `?startDate=${dates.startDate}&endDate=${dates.endDate}`;
    }
    const list = { ...CONSTANTS.API.getDashboardCount };
    list.endpoint = list.endpoint + datefilter;
    api.sendRequest(list, (res) => {
      setCount(res?.data);
    });
  }, [dates]);
  const dashboardMenu = [
    { label: "Users", count: count?.users, icon: <User />, color: "#3B7ECC" },
    {
      label: "Notifications",
      count: count?.notifications,
      icon: <Notification />,
      color: "#066",
    },
    {
      label: "Submitted Tools",
      count: count?.submitTools,
      icon: <BoxAdd size={22} />,
      color: "#EB1D36",
    },
    {
      label: "Main Categories",
      count: count?.mainCategories,
      icon: <Category />,
      color: "#662549",
    },
    {
      label: "Sub Categories",
      count: count?.categories,
      icon: <Category />,
      color: "#96C",
    },
    {
      label: "Blog Categories",
      count: count?.blogCategories,
      icon: <Category />,
      color: "#706233",
    },
    {
      label: "News Category",
      count: count?.newsCategories,
      icon: <Educare />,
      color: "#C2BB00",
    },
    {
      label: "Listicle Categories",
      count: count?.listingCategories,
      icon: <Category />,
      color: "#AE445A",
    },
    { label: "Tools", count: count?.tools, icon: <Magicpen />, color: "#C00" },
    { label: "Blogs", count: count?.blogs, icon: <Receipt21 />, color: "#C0F" },
    {
      label: "News",
      count: count?.news,
      icon: <NotificationStatus />,
      color: "#8802DB",
    },
    {
      label: "Listicle",
      count: count?.listings,
      icon: <ReceiptItem />,
      color: "#219C90",
    },
    {
      label: "Tool Views",
      count: count?.toolViews,
      icon: <ReceiptSearch />,
      color: "#F06",
    },
    {
      label: "Blog Views",
      count: count?.blogViews,
      icon: <ReceiptSearch />,
      color: "#E9B824",
    },
    {
      label: "News Views",
      count: count?.newsViews,
      icon: <ReceiptSearch />,
      color: "#053D38",
    },
    {
      label: "Listicle Views",
      count: count?.listingViews,
      icon: <ReceiptSearch />,
      color: "#00F",
    },
    {
      label: "Tool Likes",
      count: count?.toolLikes,
      icon: <Lovely />,
      color: "#F00",
    },
    {
      label: "Blog Likes",
      count: count?.blogLikes,
      icon: <Lovely />,
      color: "#090",
    },
    {
      label: "Listicle Likes",
      count: count?.listingLikes,
      icon: <Lovely />,
      color: "#D83F31",
    },
    {
      label: "Tool Wishlists",
      count: count?.toolWishlists,
      icon: <Archive />,
      color: "#0C0",
    },
    {
      label: "Blog Wishlists",
      count: count?.blogWishlists,
      icon: <Archive />,
      color: "#90C",
    },
    {
      label: "News Wishlist",
      count: count?.newsWishlists,
      icon: <Archive />,
      color: "#F26800",
    },
    {
      label: "Blog Comments",
      count: count?.blogComments,
      icon: <FaRegCommentAlt className="text-xl" />,
      color: "#F90",
    },
    {
      label: "Blog Comment Replies",
      count: count?.blogCommentReplies,
      icon: <FaRegComments className="text-2xl" />,
      color: "#963",
    },
    
    {
      label: "Listicle Comments",
      count: count?.listingComments,
      icon: <FaRegCommentAlt className="text-xl" />,
      color: "#910A67",
    },
    {
      label: "Listicle Comment Replies",
      count: count?.listingCommentReplies,
      icon: <FaRegComments className="text-2xl" />,
      color: "#365486",
    },
    {
      label: "Tool Rating",
      count: count?.toolRatings,
      icon: <Star />,
      color: "#F60",
    },
   
  
   
    
    
  
    
   
    
   
    
   
   
  

   
   
   
   
    
    
   
    
    
    
  ];
  return (
    <div className="px50 py30">
      <div className="my-5">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card>
              <Heading>Filter</Heading>
              <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                <RangePicker className="w-3/4" onChange={dateFilterFunction} />
              </Col>
            </Card>
          </Col>
        </Row>
      </div>

      <Row gutter={[16, 16]} className="my-4">
        {dashboardMenu &&
          dashboardMenu?.map((i) => {
            return (
              <Col span={8} xs={24} sm={12} md={12} lg={8} xl={6}>
                <Card size="small" className="p-5">
                  <div style={{ color: i?.color }}>
                    <div className="flex gap-3 items-center">
                      {i?.icon}
                      <p className="font-semibold text-base m-0">{i?.label}</p>
                    </div>
                    <p className="text-2xl font-medium mt-5 text-[#434344]">
                      {api?.isLoading ? (
                        <Spin size="small" />
                      ) : i?.count ? (
                        i?.count
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                </Card>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default Dashboard;
