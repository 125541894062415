
import React, { useState } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';
const ReDirectUrl = () => { 
  return (
    <>
       <PageComponent
       trackEventName='Redirect URL'
        tableHeaders="REDIRECTURL"
        tableTitle="Redirect URL List"
        getAPI={CONSTANTS.API.getRedirectUrl}
        getData={(res)=>{
            return res?.map((data,idx)=>{
                return {
                    ...data,
                    no: `RU${data?.id.toString().padStart(7, "0")}`,
                    date:moment(data?.createdAt).format('lll'),        
                }
            })
        }}
        extraResData="rows"
        addData
        modalButton="Add Redirect URL"
        addModalTitle="Add Redirect URL"
        editModalTitle="Edit Redirect URL"
        modalFields="REDIRECTURL_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addRedirectUrl}
        editAPI={CONSTANTS.API.editRedirectUrl}
        editData
        deleteData
        deleteAPI={CONSTANTS.API.deleteRedirectUrl}
        searchfilter
        isSearch={true}
        searchAPI="/admin/redirects?search="
      />
    </>
  )
}

export default ReDirectUrl
