import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { apiGenerator } from "../../../util/functions";
import useHttp from "../../../hooks/use-http";
import PitchEditModal from "../../../component/pitch/PitchEditModal";

const Pitch = () => {
  const navigate = useNavigate();
  const [reLoading, setReloading] = useState(false);
  const [open, setOpen] = useState(false);
  const[editRenderData,setEditRenderData]=useState([]);
  const API = useHttp();
  const handleEdit = (id) => {
    setOpen(true);
  };
  return (
    <>
      <PageComponent
        trackEventName="Pitch Status"
        tableHeaders="PITCH"
        tableTitle="Pitch List"
        getAPI={CONSTANTS.API.getPitch}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `P${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("lll"),
              edit: {
                id: data?.id,
                onClick: (id) => {
                  handleEdit(id);
                  setEditRenderData(data)
                },
                status:data?.status,
              },
            };
          });
        }}
        extraResData="rows"
        reload={reLoading}
        addData
        modalButton="Add New Pitch"
        addModalTitle="Add New Pitch"
        // editModalTitle="Edit Pitch"
        modalFields="PITCH_MODAL"
        // editModalFields="PITCH_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addPitch}
        // editformData
        // editAPI={CONSTANTS.API.editPitch}
        // deleteData
        // deleteAPI={CONSTANTS.API.deleteSubmitTool}
        searchfilter
        isSearch={true}
        searchAPI="/admin/pitch?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.PITCH}
        extraFilterParam="status"
        modalWidth={900}
      />
      {open ? (
        <PitchEditModal
          open={open}
          onCancel={() => {
            setOpen((prev) => !prev);
          }}
          name={"Edit Pitch"}
          formData={{ ...editRenderData }}
          setReloading={setReloading}
          setOpen={setOpen}
        />
      ) : null}
    </>
  );
};

export default Pitch;
