import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { apiGenerator } from '../../../../util/functions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const UserNewsWishlist = ({dataId}) => {
    let list = { ...CONSTANTS.API.getUserNewsWishlistList };
    const apiList = apiGenerator(list, { dataId });
    const navigate=useNavigate();
    const handleViewNews = (id) => {
      navigate(`/app/news/${id}`);
    };
  return (
    <>
      <div className="mt-5 mx-3">
        <PageComponent
          tableHeaders="USERNEWSWISHLIST"
          tableTitle="News Wishlist"
          getAPI={apiList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data.news,
                no: `NW${data?.id.toString().padStart(7, "0")}`, 
                view:{
                    id:data?.newsId,
                    onClick:(id)=>{handleViewNews(id)}
                  },
                date:moment(data?.createdAt).format('lll'),            
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/blog?search="
        //   viewData
        //   viewFunction={handleViewTool}
        />
      </div>
    </>
  )
}

export default UserNewsWishlist
