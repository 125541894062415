import React from 'react'
import PageComponent from '../../../../component/common/Page-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import { apiGenerator } from '../../../../util/functions';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const UserListicleLike = ({dataId}) => {
  let list = { ...CONSTANTS.API.getUserListiclesLike};
  const apiBlogList = apiGenerator(list, { dataId });
  const navigate=useNavigate();
  const handleView = (id) => {
    navigate(`/app/listicles/${id}`);
  };
  return (
    <>
       <div className="mt-5 mx-3">
        <PageComponent
          tableHeaders="USERLISTICLELIKE"
          tableTitle="Listicle Likes"
          getAPI={apiBlogList}
          getData={(res) => {
            return res?.map((data, idx) => {
              return {
                ...data.listing,
                no: `LL${data?.id.toString().padStart(7, "0")}`,  
                view:{
                    id:data?.listingId,
                    onClick:(id)=>{handleView(id)}
                  },
                date:moment(data?.createdAt).format('lll'),            
              };
            });
          }}
          extraResData="rows"
          // datefilter
          // searchfilter
          // isSearch={true}
          // searchAPI="/admin/blog?search="
        //   viewData
        //   viewFunction={handleViewBlog}
        />
      </div>
    </>
  )
}

export default UserListicleLike
