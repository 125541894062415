import React, { useEffect, useState } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import useHttp from '../../../hooks/use-http';
import { useNavigate } from 'react-router-dom';
import ModalUserList from '../../../component/common/ModalUserList';
import { apiGenerator, changePremiumtoPaid } from '../../../util/functions';
import ModalRating from './ModalRating';
import moment from 'moment';

const ScheduleTool = () => {
  const api=useHttp();
  const navigate=useNavigate();
  // const [openWishlist, setOpenWishlist] = useState(false);
  // const [openLikes, setOpenLikes] = useState(false);
  // const [openRatings, setOpenRatings] = useState(false);
  // const [openUserView, setOpenUserView] = useState(false);
  // const [toolId, setToolId] = useState("");
  // const [data, setData] = useState([]);
  useEffect(()=>{
    const apiCategory= {...CONSTANTS.API.getCategory};
    api.sendRequest(apiCategory,(res)=>{
       const category=res?.data?.rows?.map((i)=>{
          return {
           ...i,
           Label:i?.name,
           value:i?.id,
           id:i?.id
          }
       })
       CONSTANTS.FORM_FIELD.TOOL_MODAL.find(
         (el) => el?.id === "categories"
       ).option = category;
       CONSTANTS.FORM_FIELD.EDIT_TOOL_MODAL.find(
         (el) => el?.id === "categories"
       ).option = category;
    })
    // const apiTag = {...CONSTANTS.API.getTag};
    // api.sendRequest(apiTag,(res)=>{
    //    const tag = res?.data?.rows?.map((i)=>{
    //        return{
    //          ...i,
    //          Label:i?.name,
    //          value:i?.id,
    //          id:i?.id
    //        }
    //    })
    //    CONSTANTS.FORM_FIELD.TOOL_MODAL.find(
    //      (el) => el?.id === "tags"
    //    ).option = tag

    //    CONSTANTS.FORM_FIELD.EDIT_TOOL_MODAL.find(
    //      (el) => el?.id === "tags"
    //    ).option = tag

      
    // })
  },[])
  const handleViewTool=(id)=>{
    navigate(`/app/tool/${id}`)
  }
  return (
    <>
      <PageComponent
      trackEventName='Tool'
        tableHeaders="SCHEDULETOOL"
        tableTitle="Scheduled Tool List"
        getAPI={CONSTANTS.API.getScheduledTool}
        getData={(res)=>{
            return res?.map((data,idx)=>{ 
                return {
                    ...data,
                    no: `T${data?.id.toString().padStart(7, "0")}`,
                    categories:data?.toolCategories?.map(ele => ele?.categoryId),
                    // tags:data?.toolTags?.map(ele => ele?.tagId),
                    // price:changePremiumtoPaid(data?.price),
                    date:moment(data?.createdAt).format('lll'),  
                    release:moment(data?.release).format('lll'),            
                }
            })
        }}
        extraResData="rows"
        formData
        modalButton="Add New Tool"
        addModalTitle="Add New Tool"
        editModalTitle="Edit Tool"
        modalFields="TOOL_MODAL"
        editModalFields="EDIT_TOOL_SCHEDULED_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addTool}
        editAPI={CONSTANTS.API.editTool}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteTool}
        searchfilter
        isSearch={true}
        searchAPI="/admin/tool?search="
        viewData
        viewFunction={handleViewTool}
        getImagesAPI={CONSTANTS.API.getToolImages}
        deleteImagesAPI={CONSTANTS.API.deleteToolImages}
        addImagesAPI={CONSTANTS.API.addToolImages}
        editImagesAPI={CONSTANTS.API.editToolImages}
        editImages
        editImagesModalFields="EDIT_TOOL_IMAGES_MODAL"
        editImageModalTitle="Edit Tool Images"
        addFAQ
        addFAQModalTitle="Add Blog FAQ"
        faqModalField='FAQ_MODAL'
        getFAQAPI={CONSTANTS.API.getOneToolFAQ}
        addFAQAPI={CONSTANTS.API.addToolFAQ}
        dateTime
        modalWidth={1100}
      />
      
    </>
  )
}

export default ScheduleTool
