import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Image,
  Popconfirm,
  Row,
  Select,
  Switch,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import CONSTANTS, { ROUTES } from "../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../util/functions";
import { DeleteOutlined } from "@ant-design/icons";
import { useMixpanel } from "react-mixpanel-browser";
const UserProfile = () => {
  const { id } = useParams();
  const [users, setUsers] = useState({});
  const [checked, setChecked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const Admin=JSON.parse(localStorage.getItem('admin'));
  const Token=localStorage.getItem('token');
  const mixpanel = useMixpanel();
  const api = useHttp();
  useEffect(() => {
    const user = { ...CONSTANTS.API.getOneUser };
    const apiUser = apiGenerator(user, { id });
    api.sendRequest(apiUser, (res) => {
      setUsers(res?.data);
      setChecked(res?.data?.isBlocked);
      // mixpanel.track('User Profile View', {
      //   Token: Token,
      //   Admin: {id:Admin?.id,name:Admin?.name},
      //   ExtraData:{User:res?.data?.username, Email:res?.data?.email}
      // });
    });
  }, [refresh]);

  const handleBlockUser = (dataId) => {
    const userBlock = { ...CONSTANTS.API.editUser };
    const apiUserBlock = apiGenerator(userBlock, { dataId });
    const payload = {
      isBlocked: !checked,
    };
    api.sendRequest(
      apiUserBlock,
      (res) => {
        setRefresh((prv) => !prv);
        mixpanel.identify(Admin?.email);
        mixpanel.track('Block-Unblock User', {
          Token: Token,
          Admin: {id:Admin?.id,name:Admin?.name},
          ExtraData:{isBlock:!checked,User:users?.username,Email:users?.email}
        });
      },
      payload
    );
  };
  const handleDeleteUser = (dataId) => {
    const userDelete = { ...CONSTANTS.API.deleteUsers };
    const apiUserDelete = apiGenerator(userDelete, { dataId });
    api.sendRequest(apiUserDelete, (res) => {
      mixpanel.identify(Admin?.email);
      mixpanel.track('Delete User', {
        Token: Token,
        Admin: {id:Admin?.id,name:Admin?.name},
        ExtraData:{User:users?.username,Email:users?.email}
      });
      navigate("/app/users");
    });
  };
  return (
    <>
      <Col span={7} xs={24} sm={24} md={24} lg={10} xl={10} xxl={6}>
        <Card className="h-60">
          <Row>
            <Col span={8} xs={24} sm={7} md={7} lg={7} xl={6} xxl={6}>
              <Image
                width={80}
                height={80}
                className="rounded-full"
                preview={false}
                src={users?.profilePic}
                alt="Images is Not Availabel"
              />
            </Col>
            <Col span={16} xs={24} sm={17} md={17} lg={17} xl={18} xxl={18}>
              <p className="font-medium mt-3 text-2xl mb-0 truncate">
                {users?.username}
              </p>
              <p className="font-medium text-sm text-gray-400 truncate">
                {users?.email}
              </p>
            </Col>
          </Row>

          <div>
            <div className="flex mt-9 mx-2 justify-between items-center">
              <div>
                <span className="text-gray-400 text-sm me-3">Block</span>
                <Switch
                  onChange={() => {
                    handleBlockUser(id);
                  }}
                  checked={checked}
                />
              </div>
              <div>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDeleteUser(id)}
                >
                  <Button type="primary">
                    <p className="">
                      <DeleteOutlined />
                    </p>
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </>
  );
};

export default UserProfile;
