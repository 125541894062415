import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Services from "../../util/API/service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { Underline } from "@ckeditor/ckeditor5-basic-styles";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          const formData = new FormData();
          formData.append("image", file);
          Services.post("/admin/upload", formData)
            // .then((response) => response.json())
            .then((result) => {
              console.log(result);
              resolve({default:result.data?.data});
            })
            .catch((error) => {
              reject("Upload failed");
              console.error("Error:", error);
            });
          // const response = await axios.request({
          //   method: "POST",
          //   url: `${process.env.REACT_APP_BASE_URL}/upload_files`,
          //   data: {
          //     files: file
          //   },
          //   headers: {
          //     "Content-Type": "multipart/form-data"
          //   }
          // });
          // resolve({
          //   default: `${process.env.REACT_APP_BASE_URL}/${response.data.filename}`,
          // });
        } catch (error) {
          reject("Hello");
        }
      });
    },
    abort: () => {},
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

function Editor({ onChange, editorLoaded, name, value }) {
  const editorRef = useRef();
  // const { CKEditor, ClassicEditor } = editorRef.current || {};

  // useEffect(() => {
  //   editorRef.current = {
  //     CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
  //     ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
  //   };
  // }, []);

  return (
    <div>
      {editorLoaded ? (
        <CKEditor
          type=""
          name={name}
          editor={ClassicEditor} // Use ClassicEditor here, not CKEditor
          config={{
        //       plugins: [Underline],
        // toolbar: ['underline'],
            extraPlugins: [uploadPlugin],
            // ckfinder: {
            //   // Upload the images to the server using the CKFinder QuickUpload command
            //   // You have to change this address to your server that has the ckfinder php connector
            //   uploadUrl: "https://dev-backend.toolplate.ai/api/v1/admin/upload" //Enter your upload url
            // }
          }}
          initData={value}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data })
            onChange(data);
          }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default Editor; // Named exports
// import axios from "axios";
// import React, { useEffect, useRef, useState } from "react";
// import Services from "../../util/API/service";


// // import CkEditorCustom from "../CkEditor-custom";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

// import { CKFinderUploadAdapter } from '@ckeditor/ckeditor5-adapter-ckfinder';
// import { Alignment } from '@ckeditor/ckeditor5-alignment';
// import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
// import {
// 	Bold,
// 	Code,
// 	Italic,
// 	Strikethrough,
// 	Subscript,
// 	Superscript,
// 	Underline
// } from '@ckeditor/ckeditor5-basic-styles';
// import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
// import { CloudServices } from '@ckeditor/ckeditor5-cloud-services';
// import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace';
// import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font';
// import { Heading, Title } from '@ckeditor/ckeditor5-heading';
// import { Highlight } from '@ckeditor/ckeditor5-highlight';
// import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
// import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed';
// import {
// 	DataFilter,
// 	DataSchema,
// 	GeneralHtmlSupport,
// 	HtmlComment
// } from '@ckeditor/ckeditor5-html-support';
// import {
// 	AutoImage,
// 	Image,
// 	ImageCaption,
// 	ImageResize,
// 	ImageStyle,
// 	ImageToolbar
// } from '@ckeditor/ckeditor5-image';
// import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
// import { TextPartLanguage } from '@ckeditor/ckeditor5-language';
// import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
// import { List, ListProperties, TodoList } from '@ckeditor/ckeditor5-list';
// import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
// import { MediaEmbed, MediaEmbedToolbar } from '@ckeditor/ckeditor5-media-embed';
// import { Mention } from '@ckeditor/ckeditor5-mention';
// import { PageBreak } from '@ckeditor/ckeditor5-page-break';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
// import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
// import { StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing';
// import { SelectAll } from '@ckeditor/ckeditor5-select-all';
// import { ShowBlocks } from '@ckeditor/ckeditor5-show-blocks';
// import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
// import {
// 	SpecialCharacters,
// 	SpecialCharactersArrows,
// 	SpecialCharactersCurrency,
// 	SpecialCharactersEssentials,
// 	SpecialCharactersLatin,
// 	SpecialCharactersMathematical,
// 	SpecialCharactersText
// } from '@ckeditor/ckeditor5-special-characters';
// import { Style } from '@ckeditor/ckeditor5-style';
// import {
// 	Table,
// 	TableCaption,
// 	TableCellProperties,
// 	TableColumnResize,
// 	TableProperties,
// 	TableToolbar
// } from '@ckeditor/ckeditor5-table';
// import { TextTransformation } from '@ckeditor/ckeditor5-typing';
// import { AccessibilityHelp } from '@ckeditor/ckeditor5-ui';
// import { Undo } from '@ckeditor/ckeditor5-undo';
// import { EditorWatchdog } from '@ckeditor/ckeditor5-watchdog';
// import { WordCount } from '@ckeditor/ckeditor5-word-count';
// function uploadAdapter(loader) {
//   return {
//     upload: () => {
//       return new Promise(async (resolve, reject) => {
//         try {
//           const file = await loader.file;
//           const formData = new FormData();
//           formData.append("image", file);
//           Services.post("/admin/upload", formData)
//             // .then((response) => response.json())
//             .then((result) => {
//               console.log(result);
//               resolve({default:result.data?.data});
//             })
//             .catch((error) => {
//               reject("Upload failed");
//               console.error("Error:", error);
//             });
//           // const response = await axios.request({
//           //   method: "POST",
//           //   url: `${process.env.REACT_APP_BASE_URL}/upload_files`,
//           //   data: {
//           //     files: file
//           //   },
//           //   headers: {
//           //     "Content-Type": "multipart/form-data"
//           //   }
//           // });
//           // resolve({
//           //   default: `${process.env.REACT_APP_BASE_URL}/${response.data.filename}`,
//           // });
//         } catch (error) {
//           reject("Hello");
//         }
//       });
//     },
//     abort: () => {},
//   };
// }
// function uploadPlugin(editor) {
//   editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
//     return uploadAdapter(loader);
//   };
// }

// function Editor1({ onChange, editorLoaded, name, value }) {
//   // const editorRef = useRef();
//   // const { CKEditor, ClassicEditor } = editorRef.current || {};

//   // useEffect(() => {
//   //   editorRef.current = {
//   //     CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
//   //     ClassicEditor: InitialClassicEditor,
//   //   };
//   // }, []);
//   const builtinPlugins = [
// 		AccessibilityHelp,
// 		Alignment,
// 		AutoImage,
// 		AutoLink,
// 		Autoformat,
// 		BlockQuote,
// 		Bold,
// 		CKFinderUploadAdapter,
// 		CloudServices,
// 		Code,
// 		CodeBlock,
// 		DataFilter,
// 		DataSchema,
// 		Essentials,
// 		FindAndReplace,
// 		FontBackgroundColor,
// 		FontColor,
// 		FontFamily,
// 		FontSize,
// 		GeneralHtmlSupport,
// 		Heading,
// 		Highlight,
// 		HorizontalLine,
// 		HtmlComment,
// 		HtmlEmbed,
// 		Image,
// 		ImageCaption,
// 		ImageResize,
// 		ImageStyle,
// 		ImageToolbar,
// 		Indent,
// 		IndentBlock,
// 		Italic,
// 		Link,
// 		LinkImage,
// 		List,
// 		ListProperties,
// 		Markdown,
// 		MediaEmbed,
// 		MediaEmbedToolbar,
// 		Mention,
// 		PageBreak,
// 		Paragraph,
// 		PasteFromOffice,
// 		SelectAll,
// 		ShowBlocks,
// 		SourceEditing,
// 		SpecialCharacters,
// 		SpecialCharactersArrows,
// 		SpecialCharactersCurrency,
// 		SpecialCharactersEssentials,
// 		SpecialCharactersLatin,
// 		SpecialCharactersMathematical,
// 		SpecialCharactersText,
// 		StandardEditingMode,
// 		Strikethrough,
// 		Style,
// 		Subscript,
// 		Superscript,
// 		Table,
// 		TableCaption,
// 		TableCellProperties,
// 		TableColumnResize,
// 		TableProperties,
// 		TableToolbar,
// 		TextPartLanguage,
// 		TextTransformation,
// 		Title,
// 		TodoList,
// 		Underline,
// 		Undo,
// 		WordCount
// 	];
// const defaultConfig = {
//   toolbar: {
//     items: [
//       'heading',
//       '|',
//       'bold',
//       'italic',
//       'link',
//       'bulletedList',
//       'numberedList',
//       '|',
//       'outdent',
//       'indent',
//       '|',
//       'blockQuote',
//       'insertTable',
//       'mediaEmbed',
//       'undo',
//       'redo',
//       'style',
//       'textPartLanguage',
//       'superscript',
//       'subscript',
//       '-',
//       'underline',
//       'todoList',
//       'accessibilityHelp',
//       'alignment',
//       'code',
//       'codeBlock',
//       'findAndReplace',
//       'fontBackgroundColor',
//       'fontColor',
//       'fontFamily',
//       'fontSize',
//       'horizontalLine',
//       'highlight',
//       'htmlEmbed',
//       'pageBreak',
//       'showBlocks',
//       'selectAll',
//       'sourceEditing',
//       'specialCharacters',
//       'restrictedEditingException',
//       'strikethrough'
//     ],
//     shouldNotGroupWhenFull: true
//   },
//   language: 'en',
//   image: {
//     toolbar: [
//       'imageTextAlternative',
//       'toggleImageCaption',
//       'imageStyle:inline',
//       'imageStyle:block',
//       'imageStyle:side',
//       'linkImage'
//     ]
//   },
//   table: {
//     contentToolbar: [
//       'tableColumn',
//       'tableRow',
//       'mergeTableCells',
//       'tableCellProperties',
//       'tableProperties'
//     ]
//   }
// };
//   return (
//     <div>
//       {editorLoaded ? (
//         <CKEditor
//           type=""
//           name={name}
//           editor={ClassicEditor} // Use ClassicEditor here, not CKEditor
//           config={{
            
//             plugins:builtinPlugins,
//             ...defaultConfig
//           }}
//           // config={{
//           //   // plugins: [Bold,Underline],
//           //   // toolbar: ['underline'],
//           //   extraPlugins: [uploadPlugin],
//           //   // ckfinder: {
//           //   //   // Upload the images to the server using the CKFinder QuickUpload command
//           //   //   // You have to change this address to your server that has the ckfinder php connector
//           //   //   uploadUrl: "https://dev-backend.toolplate.ai/api/v1/admin/upload" //Enter your upload url
//           //   // }
//           // }}
//           initData={value}
//           data={value}
//           onChange={(event, editor) => {
//             const data = editor.getData();
//             // console.log({ event, editor, data })
//             onChange(data);
//           }}
//         />
//       ) : (
//         <div>Editor loading</div>
//       )}
//     </div>
//   );
// }

// export default Editor1; // Named exports
// import axios from "axios";
// import React, { useEffect, useRef, useState } from "react";
// import Services from "../../util/API/service";


// // import CkEditorCustom from "../CkEditor-custom";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';

// import { CKFinderUploadAdapter } from '@ckeditor/ckeditor5-adapter-ckfinder';
// import { Alignment } from '@ckeditor/ckeditor5-alignment';
// import { Autoformat } from '@ckeditor/ckeditor5-autoformat';
// import {
// 	Bold,
// 	Code,
// 	Italic,
// 	Strikethrough,
// 	Subscript,
// 	Superscript,
// 	Underline
// } from '@ckeditor/ckeditor5-basic-styles';
// import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
// import { CloudServices } from '@ckeditor/ckeditor5-cloud-services';
// import { CodeBlock } from '@ckeditor/ckeditor5-code-block';
// import { Essentials } from '@ckeditor/ckeditor5-essentials';
// import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace';
// import { FontBackgroundColor, FontColor, FontFamily, FontSize } from '@ckeditor/ckeditor5-font';
// import { Heading, Title } from '@ckeditor/ckeditor5-heading';
// import { Highlight } from '@ckeditor/ckeditor5-highlight';
// import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
// import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed';
// import {
// 	DataFilter,
// 	DataSchema,
// 	GeneralHtmlSupport,
// 	HtmlComment
// } from '@ckeditor/ckeditor5-html-support';
// import {
// 	AutoImage,
// 	Image,
// 	ImageCaption,
// 	ImageResize,
// 	ImageStyle,
// 	ImageToolbar
// } from '@ckeditor/ckeditor5-image';
// import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
// import { TextPartLanguage } from '@ckeditor/ckeditor5-language';
// import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
// import { List, ListProperties, TodoList } from '@ckeditor/ckeditor5-list';
// import { Markdown } from '@ckeditor/ckeditor5-markdown-gfm';
// import { MediaEmbed, MediaEmbedToolbar } from '@ckeditor/ckeditor5-media-embed';
// import { Mention } from '@ckeditor/ckeditor5-mention';
// import { PageBreak } from '@ckeditor/ckeditor5-page-break';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
// import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
// import { StandardEditingMode } from '@ckeditor/ckeditor5-restricted-editing';
// import { SelectAll } from '@ckeditor/ckeditor5-select-all';
// import { ShowBlocks } from '@ckeditor/ckeditor5-show-blocks';
// import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
// import {
// 	SpecialCharacters,
// 	SpecialCharactersArrows,
// 	SpecialCharactersCurrency,
// 	SpecialCharactersEssentials,
// 	SpecialCharactersLatin,
// 	SpecialCharactersMathematical,
// 	SpecialCharactersText
// } from '@ckeditor/ckeditor5-special-characters';
// import { Style } from '@ckeditor/ckeditor5-style';
// import {
// 	Table,
// 	TableCaption,
// 	TableCellProperties,
// 	TableColumnResize,
// 	TableProperties,
// 	TableToolbar
// } from '@ckeditor/ckeditor5-table';
// import { TextTransformation } from '@ckeditor/ckeditor5-typing';
// import { AccessibilityHelp } from '@ckeditor/ckeditor5-ui';
// import { Undo } from '@ckeditor/ckeditor5-undo';
// import { EditorWatchdog } from '@ckeditor/ckeditor5-watchdog';
// import { WordCount } from '@ckeditor/ckeditor5-word-count';
// function uploadAdapter(loader) {
//   return {
//     upload: () => {
//       return new Promise(async (resolve, reject) => {
//         try {
//           const file = await loader.file;
//           const formData = new FormData();
//           formData.append("image", file);
//           Services.post("/admin/upload", formData)
//             // .then((response) => response.json())
//             .then((result) => {
//               console.log(result);
//               resolve({default:result.data?.data});
//             })
//             .catch((error) => {
//               reject("Upload failed");
//               console.error("Error:", error);
//             });
//           // const response = await axios.request({
//           //   method: "POST",
//           //   url: `${process.env.REACT_APP_BASE_URL}/upload_files`,
//           //   data: {
//           //     files: file
//           //   },
//           //   headers: {
//           //     "Content-Type": "multipart/form-data"
//           //   }
//           // });
//           // resolve({
//           //   default: `${process.env.REACT_APP_BASE_URL}/${response.data.filename}`,
//           // });
//         } catch (error) {
//           reject("Hello");
//         }
//       });
//     },
//     abort: () => {},
//   };
// }
// function uploadPlugin(editor) {
//   editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
//     return uploadAdapter(loader);
//   };
// }

// function Editor1({ onChange, editorLoaded, name, value }) {
//   // const editorRef = useRef();
//   // const { CKEditor, ClassicEditor } = editorRef.current || {};

//   // useEffect(() => {
//   //   editorRef.current = {
//   //     CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
//   //     ClassicEditor: InitialClassicEditor,
//   //   };
//   // }, []);
//   const builtinPlugins = [
// 		AccessibilityHelp,
// 		Alignment,
// 		AutoImage,
// 		AutoLink,
// 		Autoformat,
// 		BlockQuote,
// 		Bold,
// 		CKFinderUploadAdapter,
// 		CloudServices,
// 		Code,
// 		CodeBlock,
// 		DataFilter,
// 		DataSchema,
// 		Essentials,
// 		FindAndReplace,
// 		FontBackgroundColor,
// 		FontColor,
// 		FontFamily,
// 		FontSize,
// 		GeneralHtmlSupport,
// 		Heading,
// 		Highlight,
// 		HorizontalLine,
// 		HtmlComment,
// 		HtmlEmbed,
// 		Image,
// 		ImageCaption,
// 		ImageResize,
// 		ImageStyle,
// 		ImageToolbar,
// 		Indent,
// 		IndentBlock,
// 		Italic,
// 		Link,
// 		LinkImage,
// 		List,
// 		ListProperties,
// 		Markdown,
// 		MediaEmbed,
// 		MediaEmbedToolbar,
// 		Mention,
// 		PageBreak,
// 		Paragraph,
// 		PasteFromOffice,
// 		SelectAll,
// 		ShowBlocks,
// 		SourceEditing,
// 		SpecialCharacters,
// 		SpecialCharactersArrows,
// 		SpecialCharactersCurrency,
// 		SpecialCharactersEssentials,
// 		SpecialCharactersLatin,
// 		SpecialCharactersMathematical,
// 		SpecialCharactersText,
// 		StandardEditingMode,
// 		Strikethrough,
// 		Style,
// 		Subscript,
// 		Superscript,
// 		Table,
// 		TableCaption,
// 		TableCellProperties,
// 		TableColumnResize,
// 		TableProperties,
// 		TableToolbar,
// 		TextPartLanguage,
// 		TextTransformation,
// 		Title,
// 		TodoList,
// 		Underline,
// 		Undo,
// 		WordCount
// 	];
// const defaultConfig = {
//   toolbar: {
//     items: [
//       'heading',
//       '|',
//       'bold',
//       'italic',
//       'link',
//       'bulletedList',
//       'numberedList',
//       '|',
//       'outdent',
//       'indent',
//       '|',
//       'blockQuote',
//       'insertTable',
//       'mediaEmbed',
//       'undo',
//       'redo',
//       'style',
//       'textPartLanguage',
//       'superscript',
//       'subscript',
//       '-',
//       'underline',
//       'todoList',
//       'accessibilityHelp',
//       'alignment',
//       'code',
//       'codeBlock',
//       'findAndReplace',
//       'fontBackgroundColor',
//       'fontColor',
//       'fontFamily',
//       'fontSize',
//       'horizontalLine',
//       'highlight',
//       'htmlEmbed',
//       'pageBreak',
//       'showBlocks',
//       'selectAll',
//       'sourceEditing',
//       'specialCharacters',
//       'restrictedEditingException',
//       'strikethrough'
//     ],
//     shouldNotGroupWhenFull: true
//   },
//   language: 'en',
//   image: {
//     toolbar: [
//       'imageTextAlternative',
//       'toggleImageCaption',
//       'imageStyle:inline',
//       'imageStyle:block',
//       'imageStyle:side',
//       'linkImage'
//     ]
//   },
//   table: {
//     contentToolbar: [
//       'tableColumn',
//       'tableRow',
//       'mergeTableCells',
//       'tableCellProperties',
//       'tableProperties'
//     ]
//   }
// };
//   return (
//     <div>
//       {editorLoaded ? (
//         <CKEditor
//           type=""
//           name={name}
//           editor={ClassicEditor} // Use ClassicEditor here, not CKEditor
//           config={{
            
//             plugins:builtinPlugins,
//             ...defaultConfig
//           }}
//           // config={{
//           //   // plugins: [Bold,Underline],
//           //   // toolbar: ['underline'],
//           //   extraPlugins: [uploadPlugin],
//           //   // ckfinder: {
//           //   //   // Upload the images to the server using the CKFinder QuickUpload command
//           //   //   // You have to change this address to your server that has the ckfinder php connector
//           //   //   uploadUrl: "https://dev-backend.toolplate.ai/api/v1/admin/upload" //Enter your upload url
//           //   // }
//           // }}
//           initData={value}
//           data={value}
//           onChange={(event, editor) => {
//             const data = editor.getData();
//             // console.log({ event, editor, data })
//             onChange(data);
//           }}
//         />
//       ) : (
//         <div>Editor loading</div>
//       )}
//     </div>
//   );
// }

// export default Editor1; // Named exports
