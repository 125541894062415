import React from "react";
import { Card, Col, Row, Tabs } from "antd";
import UserProfile from "./user-profile";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useParams } from "react-router-dom";
import UserToolLike from "./tabs/tool-like";
import UserBlogLike from "./tabs/blog-like";
import UserToolRating from "./tabs/tool-rating";
import UserToolView from "./tabs/tool-view";
import UserToolWishlist from "./tabs/tool-wishlist";
import UserBlogWishlist from "./tabs/blog-wishlist";
import UserBlogComment from "./tabs/blog-comment";
import UserBlogView from "./tabs/blog-view";
import UserListChart from "./user-list-chart";
import UserNewsWishlist from "./tabs/news-wishlist";
import UserNewsViews from "./tabs/news-views";
import UserListicleLike from "./tabs/listicles-like";
import UserListicleView from "./tabs/listicles-view";
const UserDetail = () => {
  const { id } = useParams();
  const menus = [
    { toolWishlist: <UserToolWishlist dataId={id} /> },
    { toolLike: <UserToolLike dataId={id} /> },
    { toolRating: <UserToolRating dataId={id} /> },
    { toolView: <UserToolView dataId={id}/> },
    { blogWishlist: <UserBlogWishlist dataId={id} /> },
    { blogLike: <UserBlogLike dataId={id} /> },
    // { blogComment: <UserBlogComment dataId={id} /> },
    { blogView: <UserBlogView dataId={id} /> },
    { newsWishlist: <UserNewsWishlist dataId={id} /> },
    { newsViews: <UserNewsViews dataId={id} /> },
    { listicleLikes: <UserListicleLike dataId={id} /> },
    { listicleViews: <UserListicleView dataId={id} /> },
  ];

  return (
    <>
      <div className="mt-10">
        <Row gutter={[16, 16]}>         
            <UserProfile />   
            <UserListChart/>     
        </Row>
        <Card className="mt-10">
          <Tabs
            className="p-0 m-0"
            defaultActiveKey="1"
            size="middle"
            type="card"
            items={CONSTANTS.TAB_MENU.USER.map((ele) => {
              return {
                label: `${ele.Label}`,
                key: `${ele.id}`,
                children: menus.map((data) => {
                  if (Object.keys(data)[0] === ele.id) {
                    return data[ele.id];
                  }
                }),
              };
            })}
          />
        </Card>
      </div>
    </>
  );
};

export default UserDetail;
