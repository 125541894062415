import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import moment from "moment";
import useHttp from "../../../../hooks/use-http";
import { Col, Image, Popconfirm, Row } from "antd";
import { FaHeart, FaRegComment } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useMixpanel } from "react-mixpanel-browser";

const BlogComment = ({ dataId }) => {
  const [hideReply, setHideReply] = useState(false);
  const [onlyHideReply, setOnlyHideReply] = useState("");
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const api = useHttp();
  const Admin=JSON.parse(localStorage.getItem('admin'));
  const Token=localStorage.getItem('token');
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (dataId) {
      fetchComment();
    }
  }, [dataId]);

  const fetchComment = () => {
    const list = { ...CONSTANTS.API.getBlogCommentList };
    const apiList = apiGenerator(list, { dataId });
    api.sendRequest(apiList, (res) => {
      setData(res?.data?.rows);
      setCount(res?.data?.count);
      // console.log(res);
    });
  };
  const replyCommentArrowToggle = (value) => {
    if (value == onlyHideReply) setHideReply((prv) => !prv);
    else setHideReply(true);
    setOnlyHideReply(value);
  };
  const renderReplyComments = (list) => {
    return (
      list &&
      list?.length > 0 &&
      list?.map((i, idx) => (
        <Row key={idx} className="border-s my-2 ps-1">
          <Col xl={2} span={1}>
            <div className="flex justify-center items-center">
              <Image
                width={50}
                height={50}
                className="rounded-full"
                preview={true}
                src={i?.user?.profilePic}
                alt={i?.user?.profilePic}
              />
            </div>
          </Col>
          <Col xl={21} span={22}>
            <p className="font-medium text-lg mb-0 truncate">
              {i?.user?.username}
            </p>
            <p className="font-medium text-sm text-gray-400 ">{i?.reply}</p>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <FaHeart className="text-red-600 text-lg" />
                <span>{i?.likes} Likes</span>
              </div>
              <span className="text-sm">
                {moment(i?.createdAt).format("lll")}
              </span>
            </div>
          </Col>
          <Col span={1}>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteReplyComment(i?.id)}
            >
              <p className="mb-0 text-red-600 text-xl cursor-pointer mt-2">
                <AiFillDelete />
              </p>
            </Popconfirm>
          </Col>
        </Row>
      ))
    );
  };

  const renderComments = () => {
    return (
      data &&
      data?.length > 0 &&
      data?.map((i, idx) => (
        <Row key={idx} className="gap-y-3">
          <Col xl={2} span={1}>
            <div className="flex justify-center items-center">
              <Image
                width={60}
                height={60}
                className="rounded-full"
                preview={true}
                src={i?.user?.profilePic}
                alt={i?.user?.profilePic}
              />
            </div>
          </Col>
          <Col xl={21} span={22}>
            <p className="font-medium text-xl mb-0 truncate ">
              {i?.user?.username}
            </p>
            <p className="font-medium text-base text-gray-600">{i?.comment}</p>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <div className="flex gap-2 items-center">
                  <FaHeart className="text-red-600 text-lg" />
                  <span>{i?.likes} Likes</span>
                </div>
                {i?.blogCommentReplies?.length > 0 ? (
                  hideReply && onlyHideReply == i?.id ? (
                    <div
                      className="flex gap-2 items-center cursor-pointer"
                      onClick={() => {
                        replyCommentArrowToggle(i?.id);
                      }}
                    >
                      <FaRegComment className="text-lg" />
                      <span> Hide Replies</span>
                    </div>
                  ) : (
                    <div
                      className="flex gap-2 items-center cursor-pointer"
                      onClick={() => {
                        replyCommentArrowToggle(i?.id);
                      }}
                    >
                      <FaRegComment className="text-lg" />
                      <span>Show Replies</span>
                    </div>
                  )
                ) : null}
              </div>
              <span className="text-sm">
                {moment(i?.createdAt).format("lll")}
              </span>
            </div>
          </Col>
          <Col span={1}>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteComment(i?.id)}
            >
              <p className="mb-0 text-red-600 text-xl cursor-pointer mt-2">
                <AiFillDelete />
              </p>
            </Popconfirm>
          </Col>
          <Col span={2}></Col>
          <Col span={22} className={``}>
            {hideReply &&
              onlyHideReply == i.id &&
              renderReplyComments(i?.blogCommentReplies)}
          </Col>
        </Row>
      ))
    );
  };
  const handleDeleteComment = (dataId) => {
    if (dataId) {
      const Comment = { ...CONSTANTS.API.deleteComment };
      const deleteComment = apiGenerator(Comment, { dataId });
      api.sendRequest(
        deleteComment,
        (res) => {
          fetchComment();
          mixpanel.identify(Admin?.email);
          mixpanel.track('Delete Comment', {
            Token: Token,
            Admin: {id:Admin?.id,name:Admin?.name},
            ExtraData:{Id:dataId}
          });
        },
        {},
        "Delete comment successfull"
      );
    }
  };
  const handleDeleteReplyComment = (dataId) => {
    if (dataId) {
      const Comment = { ...CONSTANTS.API.deleteReplyComment };
      const deleteComment = apiGenerator(Comment, { dataId });
      api.sendRequest(
        deleteComment,
        (res) => {
          fetchComment();
          mixpanel.identify(Admin?.email);
          mixpanel.track('Delete Reply Comment', {
            Token: Token,
            Admin: {id:Admin?.id,name:Admin?.name},
            ExtraData:{Id:dataId}
          });
        },
        {},
        "Delete reply comment successfull"
      );
    }
  };

  return (
    <>
      <div className="flex gap-3 items-center p-3">
        <p className="text-2xl mb-0 font-semibold">Comments</p>
        <div className="px-2 text-white rounded-md font-semibold bg-[#5a4fcf] text-lg flex justify-center items-center">
          {" "}
          {`${count || "0"}`}
        </div>
      </div>

      <div className="mt-5 mx-3"> {renderComments()}</div>    
    </>
  );
};

export default BlogComment;
