import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./tab.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

const OverView = ({ data }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  useEffect(() => {
    setIsVideoLoaded(true);
    return () => {
      setIsVideoLoaded(false);
    };
  }, [isVideoLoaded]);
  return (
    <>
      <Row className="my-5">
        <Col span={24}>
          <div
            dangerouslySetInnerHTML={{ __html: data?.overview }}
            //   className="opacity-75"
          />
        </Col>
      {data?.videos?.length > 0 ? <Col span={24}>
          <div className="overview-vedios my-5">
            <span className="text-xl font-semibold">Videos</span>

            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              className="tw-select-none slider-vedios mt-5"
              id="main"
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              navigation
              pagination
              breakpoints= {{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                 
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
              }}
            >
              {data?.videos && data?.videos.length > 0
                ? data?.videos.map((i, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        {" "}
                        <ReactPlayer
                          url={i}
                          playing={false}
                          controls={true}
                          loop={true}
                          muted={true}
                          playsinline={false}
                          // light={
                          //   <img
                          //     src={data?.image}
                          //     width={420}
                          //     height={240}
                          //     alt="tool"
                          //     className={`w-full h-[220px] rounded-[20px] border-gray-color opacity-90`}
                          //   />
                          // }
                          onPlay={onLoadedData}
                          width={"100%"}
                          height={240}
                        />{" "}
                      </SwiperSlide>
                    );
                  })
                : null}
            </Swiper>
          </div>
        </Col>:null}  
      </Row>
    </>
  );
};

export default OverView;
