import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { useNavigate } from "react-router-dom";
import { genAlertStyle } from "antd/es/alert/style";
import { Col, Image, Modal, Row } from "antd";
import ModalUserList from "../../../component/common/ModalUserList";
import ModalComments from "./ModalComments";
import { apiGenerator } from "../../../util/functions";
import moment from "moment";

const Blog = () => {
  const api = useHttp();
  const navigate = useNavigate();
  useEffect(() => {
    const apiCategory = { ...CONSTANTS.API.getBlogCategory };
    api.sendRequest(apiCategory, (res) => {
      const category = res?.data?.rows?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.BLOG_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FORM_FIELD.EDIT_BLOG_MODAL.find(
        (el) => el?.id === "categories"
      ).option = category;
      CONSTANTS.FILTER.BLOG.find((el) => el?.id === "categories").option =
        category;
    });
    const apiAdmin = { ...CONSTANTS.API.getAdmin };
    api.sendRequest(apiAdmin, (res) => {
      const admin = res?.data?.map((i) => {
        return {
          ...i,
          Label: i?.name,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.BLOG_MODAL.find(
        (el) => el?.id === "AdminId"
      ).option = admin;
      CONSTANTS.FORM_FIELD.EDIT_BLOG_MODAL.find(
        (el) => el?.id === "AdminId"
      ).option = admin;
    });
  }, []);

  const handleViewBlog = (id) => {
    navigate(`/app/blog/${id}`);
  };

  return (
    <>
      <PageComponent
        trackEventName="Blog"
        tableHeaders="BLOG"
        tableTitle="Blog List"
        getAPI={CONSTANTS.API.getBlog}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `B${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("lll"),
              categories: data?.blogCategories?.map(
                (ele) => ele?.categoryOfBlogId
              ),
              admin:data?.Admin?.name ? data?.Admin?.name:'-',
              // tags: data?.blogTags?.map((ele) => ele?.tagId),
              readTime: `${data?.readTime} Minutes`,
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton="Add New Blog"
        addModalTitle="Add New Blog"
        editModalTitle="Edit Blog"
        modalFields="BLOG_MODAL"
        editModalFields="EDIT_BLOG_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addBlog}
        editAPI={CONSTANTS.API.editBlog}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteBlog}
        searchfilter
        isSearch={true}
        searchAPI="/admin/blog?search="
        viewData
        viewFunction={handleViewBlog}
        addFAQ
        addFAQModalTitle="Add Blog FAQ"
        faqModalField="BLOG_FAQ_MODAL"
        getFAQAPI={CONSTANTS.API.getOneBlogFAQ}
        addFAQAPI={CONSTANTS.API.addBlogFAQ}
        scheduleTitle="Scheduled Blog"
        schedulePage="/app/schedule/blog"
        dateTime
        extraFilter
        extraFilterField={CONSTANTS.FILTER.BLOG}
        extraFilterParam="categoryIds"
        modalWidth={1100}
      />
    </>
  );
};

export default Blog;
