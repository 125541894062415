import React, { useEffect } from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import useHttp from '../../../hooks/use-http';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const News = () => {
  const api=useHttp();
  const navigate=useNavigate();
  useEffect(()=>{
    const apiCategory= {...CONSTANTS.API.getNewsCategory};
    api.sendRequest(apiCategory,(res)=>{
       const category=res?.data?.rows?.map((i)=>{
          return {
           ...i,
           Label:i?.name,
           value:i?.id,
           id:i?.id
          }
       })
       CONSTANTS.FORM_FIELD.NEWS_MODAL.find(
         (el) => el?.id === "newsCategoryId"
       ).option = category;
       CONSTANTS.FILTER.NEWS.find(
        (el) => el?.id === "categories"
      ).option = category;
       CONSTANTS.FORM_FIELD.EDIT_NEWS_MODAL.find(
         (el) => el?.id === "newsCategoryId"
       ).option = category;
    })
    const apiTool= {...CONSTANTS.API.getToolNews};
    api.sendRequest(apiTool,(res)=>{
       const tool=res?.data?.map((i)=>{
          return {
           ...i,
           Label:i?.title,
           value:i?.id,
           id:i?.id
          }
       })
       CONSTANTS.FORM_FIELD.NEWS_MODAL.find(
         (el) => el?.id === "tools"
       ).option = tool;
       CONSTANTS.FORM_FIELD.EDIT_NEWS_MODAL.find(
         (el) => el?.id === "tools"
       ).option = tool;
    })
  },[])
  const handleViewNews=(id)=>{
    navigate(`/app/news/${id}`)
  }
  return (
    <>
      <PageComponent
      trackEventName='News'
        tableHeaders="NEWS"
        tableTitle="News List"
        getAPI={CONSTANTS.API.getNews}
        getData={(res)=>{
            return res?.map((data,idx)=>{ 
                return {
                    ...data,
                    no: `N${data?.id.toString().padStart(7, "0")}`,
                    newsCategory:data?.newsCategory?.name ? data?.newsCategory?.name :"-",    
                    tools:data?.toolNews?.map(ele => ele?.toolId),               
                    date:moment(data?.createdAt).format('lll'),      
                    view:{
                      id:data?.id,
                      onClick:(id)=>{handleViewNews(id)}
                    },      
                }
            })
        }}
        extraResData="rows"
        formData
        modalButton="Add New News"
        addModalTitle="Add New News"
        editModalTitle="Edit News"
        modalFields="NEWS_MODAL"
        editModalFields="EDIT_NEWS_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addNews}
        editAPI={CONSTANTS.API.editNews}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteNews}
        searchfilter
        isSearch={true}
        searchAPI="/admin/news?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.NEWS}
        extraFilterParam='newsCategoryId'
        schedulePage="/app/schedule/news"
        scheduleTitle="Scheduled News"
        dateTime
        modalWidth={800}
      />
    </>
  )
}

export default News
