import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { apiGenerator, capitalizeFirstLetter } from "../../../util/functions";

const SingleNewsCat_NewsView = () => {
  const location = useLocation();
  const api = useHttp();
  const navigate = useNavigate();
  useEffect(() => {
    const defaultValue = location.state?.categoryId
      ? {
          value: location.state?.categoryId,
          Label: location.state?.categoryName,
          id: location.state?.categoryId,
        }
      : null;
    CONSTANTS.FORM_FIELD.NEWSCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "newsCategoryId"
    ).option = [defaultValue];

    CONSTANTS.FORM_FIELD.NEWSCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "newsCategoryId"
    ).defaultValue = location.state?.categoryId;
    CONSTANTS.FORM_FIELD.EDIT_NEWSCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "newsCategoryId"
    ).option = [defaultValue];

    CONSTANTS.FORM_FIELD.EDIT_NEWSCATEGORYVIEW_MODAL.find(
      (el) => el?.id === "newsCategoryId"
    ).defaultValue = location.state?.categoryId;
    const apiTool = { ...CONSTANTS.API.getToolNews };
    api.sendRequest(apiTool, (res) => {
      const tool = res?.data?.map((i) => {
        return {
          ...i,
          Label: i?.title,
          value: i?.id,
          id: i?.id,
        };
      });
      CONSTANTS.FORM_FIELD.NEWSCATEGORYVIEW_MODAL.find((el) => el?.id === "tools").option =
        tool;
      CONSTANTS.FORM_FIELD.EDIT_NEWSCATEGORYVIEW_MODAL.find(
        (el) => el?.id === "tools"
      ).option = tool;
    });
  }, []);
  const handleViewNews = (id) => {
    navigate(`/app/news/${id}`);
  };
  console.log(location.state?.categoryId,"*-")
  const API_CALL = apiGenerator(CONSTANTS.API.getSingleCategoryNews, { dataId: location.state?.categoryId });
  return (
    <>
      <PageComponent
      trackEventName="News"
        tableHeaders="NEWSCATEGORYVIEW"
        tableTitle={`News List [${capitalizeFirstLetter(location.state?.categoryName)}]`}
        getAPI={API_CALL}
        getData={(res) => {
          return res?.map((data, idx) => {
            return {
              ...data,
              no: `N${data?.id.toString().padStart(7, "0")}`,            
              tools: data?.toolNews?.map((ele) => ele?.toolId),
              date: moment(data?.createdAt).format("lll"),
              view: {
                id: data?.id,
                onClick: (id) => {
                  handleViewNews(id);
                },
              },
            };
          });
        }}
        extraResData="rows"
        formData
        modalButton={`Add New News`}
        addModalTitle={`Add New News [${capitalizeFirstLetter(location.state?.categoryName)}]`}
        editModalTitle={`Edit News [${capitalizeFirstLetter(location.state?.categoryName)}]`}
        modalFields="NEWSCATEGORYVIEW_MODAL"
        editModalFields="EDIT_NEWSCATEGORYVIEW_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addNews}
        editAPI={CONSTANTS.API.editNews}
        editformData
        deleteData
        deleteAPI={CONSTANTS.API.deleteNews}
        searchfilter
        isSearch={true}
        searchAPI="/admin/news?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.NEWSCATEGORYVIEW}
        extraFilterParam="newsCategoryId"
        schedulePage={`/app/schedule/news-category/news/${location.state?.categoryId}`}
        scheduleTitle="Scheduled News"
        extraScheduleState={{ state: { categoryId: location.state?.categoryId ,categoryName:location.state?.categoryName} }}
        dateTime
        modalWidth={800}
      />
    </>
  );
};

export default SingleNewsCat_NewsView;
