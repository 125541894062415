import React from 'react'
import PageComponent from '../../../component/common/Page-Component'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import moment from 'moment'

const Notification = () => {
  return (
    <>
        <PageComponent
        trackEventName='Notification'
        tableHeaders="NOTIFICATION"
        tableTitle="Notification List"
        getAPI={CONSTANTS.API.getNotifictions}
        getData={(res)=>{
            return res?.map((data,idx)=>{
                return {
                    ...data,
                    no: `N${data?.id.toString().padStart(7, "0")}`,
                    date:moment(data?.createdAt).format('lll'),
                    sender:data?.Admin?.name,
                }
            })
        }}
        extraResData="rows"
        addData
        // formData
        modalButton="Add Notification"
        addModalTitle="Add New Notification"
        // editModalTitle="Edit Blog"
        modalFields="NOTIFICATION_MODAL"
        // editModalFields="EDIT_BLOG_MODAL"
        datefilter
        addAPI={CONSTANTS.API.addNotifiactions}
        // editAPI={CONSTANTS.API.editBlog}
        // editformData
        // deleteData
        // deleteAPI={CONSTANTS.API.deleteBlog}
        searchfilter
        isSearch={true}
        searchAPI="/admin/notification?search="
        scheduleTitle="Scheduled Notification"
        schedulePage="/app/schedule/notification"
        modalWidth={850}
        // viewData
        // viewFunction={handleViewBlog}
      />
    </>
  )
}

export default Notification
