import React, { useEffect, useState } from "react";
import PageComponent from "../../../component/common/Page-Component";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import moment from "moment";
import { Card, Col, DatePicker } from "antd";
import ReactApexChart from "react-apexcharts";
import useHttp from "../../../hooks/use-http";
import { apiGenerator } from "../../../util/functions";
import dayjs from "dayjs";
const Users = () => {
  const navigate = useNavigate();
  const [reloadChart, setReloadChart] = useState(false);
  const userDetail = (id) => {
    navigate(`/app/users/${id}`);
  };
  const { RangePicker } = DatePicker;
  const api = useHttp();
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const options = {
    labels: ["Blocked", "Active", "Joined"],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "11px",
      },
    },
    colors: ["#FF4560", "#F39F5A", "#008FFB"],
  };

  const [chartData, setChartData] = useState([]);
  const dateFilterFunction = (e) => {
    console.log(e);
    // console.log(dayjs(e[0]).format("YYYY-MM-DD"));
    // console.log(dayjs(e[1]).format("YYYY-MM-DD"));
    if (e) {
      setDates({
        startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
        endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
  };
  useEffect(() => {
    let datefilter = "";
    if (dates.startDate !== null && dates.endDate !== null) {
      datefilter = `?startDate=${dates.startDate}&endDate=${dates.endDate}`;
    }

    const list = { ...CONSTANTS.API.getJoinUsersAnalaytics };
    list.endpoint = list.endpoint + datefilter;
    const apiList = apiGenerator(list);
    api.sendRequest(apiList, (res) => {
      const data = res?.data;
      const series = [data?.blocked, data?.active, data?.joined];
      setChartData(series);
    });
  }, [dates, reloadChart]);

  return (
    <>
      <PageComponent
        trackEventName="User"
        tableHeaders="USERS"
        tableTitle="Users List"
        getAPI={CONSTANTS.API.getUsers}
        getData={(res) => {
          return res?.map((data) => {
            return {
              ...data,
              no: `U${data?.id.toString().padStart(7, "0")}`,
              date: moment(data?.createdAt).format("lll"),
            };
          });
        }}
        extraResData="rows"
        viewData
        viewFunction={userDetail}
        blockData
        addAPI={CONSTANTS.API.addNotifiactions}
        editAPI={CONSTANTS.API.editUser}
        deleteData
        deleteAPI={CONSTANTS.API.deleteUsers}
        datefilter
        searchfilter
        isSearch={true}
        searchAPI="/admin/users?search="
        extraFilter
        extraFilterField={CONSTANTS.FILTER.USER}
        extraFilterParam=""
        getNotify
        chartData
        setReloadChart={setReloadChart}
        chartComponent={
          <Col span={6} xs={24} sm={12} md={12} lg={8} xl={6}>
            <Card size="small" className="h-60">
              <RangePicker className="w-full" onChange={dateFilterFunction} />
              <ReactApexChart
                options={options}
                series={chartData}
                type="pie"
                width={320}
                // height={400}
              />
            </Card>
          </Col>
        }
      />
    </>
  );
};

export default Users;
